import CardList from 'components/Tcg/CardList/CardList';
import Title from 'utils/title';

export default function TcgCardsListPage() {
    return (
        <div className='container py-3'>
            <h1><Title title="卡牌图鉴" /></h1>

            <CardList
                onCardClickCallBack={() => {}}
                onCardClickPreviewMode={true}
            />

        </div>
    );
}
