import api, { OnErrorFn, OnResponseFn } from 'api/api';

export interface RegistrationInfoRequest {
    contest_id: string
};



export interface RegistrationInfoResponse {
    name: string
    num_card_decks_per_user: number
    card_decks: string[]
    form: RegistrationForm[]
};

export interface RegistrationForm {
    question: string,
    options: string[],
    type: string,
}

function registration_info(
    request: RegistrationInfoRequest,
    onResponse?: OnResponseFn<RegistrationInfoResponse>,
    onError?: OnErrorFn) {
    api.post('/contest/tcg/registration_info', {
        data: request,
        onResponse: onResponse,
        onError: onError,
    });
}

export interface RegisterForContestV2Request {
    contest_id: string
    card_deck_ids: string[]
};

function register_v2(
    request: RegisterForContestV2Request,
    onResponse?: () => void,
    onError?: OnErrorFn) {
    api.post('/contest/tcg/register_v2', {
        data: request,
        onResponse: onResponse,
        onError: onError,
    });
}

const tcg_api = {
    registration_info, register_v2
};
export default tcg_api;
