import {AxiosError} from "axios";
import {t} from "i18next";

interface ErrorResponse {
    error: string,
    context: string,
}

export const getErrorMessage = (error: AxiosError) => {
    if (error.response !== undefined) {
        const code = (error.response.data! as ErrorResponse).error;
        return getErrorMessageFromCode(code, error);
    } else {
        const code = error.code;
        return getErrorMessageFromCode(code, error);
    }
}

const getErrorMessageFromCode = (
        errorCode: string | undefined, error: AxiosError) => {
    return t([
            `errors.${errorCode}`,
            `发生未知错误，请联系管理员。路径：({{method}}) {{path}}。错误代码：{{code}} 状态码：{{status}}.`], 
        {
            method: error.config?.method,
            path: error.config?.url ?? t('未知路径'),
            code: errorCode,
            status: error.response?.status
        });
}
