import api, { OnErrorFn, OnResponseFn } from 'api/api';

export interface UploadContestAvatar {
    filename: string
};

function upload_contest_avatar(
    request: FormData,
    onResponse?: OnResponseFn<UploadContestAvatar>,
    onError?: OnErrorFn) {
    api.post('/contest/tcg/upload/avatar', {
        data: request,
        onResponse: onResponse,
        onError: onError,
    });
}

const tcg_avatar_api = {
    upload_contest_avatar,
};
export default tcg_avatar_api;
