
import React from 'react';
import { Typography } from '@douyinfe/semi-ui';

import './CardListCardPreviewVertical.css';
import CardCost from '../CardCost/CardCost';


interface Props {
    card: any;
    onCardClick?: (_card: any) => void;
    showDetails?: boolean;
}

const CardListCardPreviewVertical: React.FC<Props> = ({
        card,
        onCardClick,
        showDetails = true}) => {
    const { Title } = Typography;

    return (
        <div
            className='shadow-sm my-3'
            onClick={() => {if (onCardClick) {onCardClick(card);}}}
            style={{
                cursor: "pointer",
                borderRadius: "8%",
                position: "relative"}}
        >
            {/* Card image */}
            <div
                    className='card-img-mask'>
                <img
                    src={card === undefined? "" : card.img}
                    width={'100%'}
                    height={'auto'} />
            </div>

            {showDetails?
            // showDetails == true
            <>
                {/* Card name */}
                <Title
                        heading={6}
                        ellipsis={{ showTooltip: true }}
                        style={{
                            width: `calc(100% - 16px)`,
                            position: "absolute",
                            bottom: "5px",
                            left: "8px" }}>
                    {card === undefined? "(未知)" : card.name}
                </Title>

                {/* Card cost */}
                {card === undefined? <></> :
                (<span style={{position: 'absolute', top: '-15px', left: '-15px'}}>
                    <CardCost
                        card={card}
                        size_px={35}
                        horizental={false}/>
                </span>)}
            </>:
            // showDetails == false
            <></>
            }
        </div>
    );
};

export default CardListCardPreviewVertical;
