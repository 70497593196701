
import { Dispatch, useEffect, useState } from "react";
import {
    Col,
    Row,
    Typography,
} from "@douyinfe/semi-ui";
import axios from "axios";
import { API_BASE_URL } from "config";
import AbyssCharacter from "./AbyssCharacter";
import './AbyssCharacterFilter.css'
import { COLOR_ELEMENT_FIRE, COLOR_ELEMENT_GRASS, COLOR_ELEMENT_ICE, COLOR_ELEMENT_ROCK, COLOR_ELEMENT_THUNDER, COLOR_ELEMENT_WATER, COLOR_ELEMENT_WIND } from "projectConstants";
import Loading from "components/Loading/Loading";


// interface Filter {
//     rarity: {
//         0: boolean,
//         4: boolean,
//         5: boolean,
//     },
//     element: {
//         "全部": boolean,
//         "岩": boolean,
//         "水": boolean,
//         "冰": boolean,
//         "雷": boolean,
//         "风": boolean,
//         "火": boolean,
//         "草": boolean,
//     },
// }

interface Filter {
    rarity: Record<string, boolean>;
    element: Record<string, boolean>;
}

interface Props {
    allCharacters: any,
    setFilteredCharacters: Dispatch<any>,
}

const AbyssCharacterFilter: React.FC<Props> = ({ allCharacters, setFilteredCharacters }) => {
    const { Text, Paragraph, Title } = Typography;
    // const [allCharacters, setAllCharacters] = useState<any>(null);
    // const [filteredCharacters, setFilteredCharacters] = useState<any>(null);
    const defaultFilter = {
        rarity: {
            '0': true,
            '4': false,
            '5': false,
        },
        element: {
            "全部": true,
            "岩": false,
            "水": false,
            "冰": false,
            "雷": false,
            "风": false,
            "火": false,
            "草": false,
        },
    } as Filter
    const [filter, setFilter] = useState<Filter>(JSON.parse(JSON.stringify(defaultFilter)));


    const ele_color_arr = [
        { text: "全部", color: '#C58042' },
        { text: "岩", color: COLOR_ELEMENT_ROCK },
        { text: "水", color: COLOR_ELEMENT_WATER },
        { text: "冰", color: COLOR_ELEMENT_ICE },
        { text: "雷", color: COLOR_ELEMENT_THUNDER },
        { text: "风", color: COLOR_ELEMENT_WIND },
        { text: "火", color: COLOR_ELEMENT_FIRE },
        { text: "草", color: COLOR_ELEMENT_GRASS },
    ]

    // Handle OnClicks
    const handleSelectedBtn = (type: any, value: string | number) => {
        //console.log(type + ' ' + value)
        if (type === 'rarity') {
            // console.log(value + typeof value)
            if (value !== '0') {
                const newState = Object.assign({}, filter);
                newState.rarity[0] = false;
                newState.rarity[value as number] = !filter.rarity[value as number];
                setFilter(newState);
            } else {
                const newState = Object.assign({}, filter);
                Object.keys(newState.rarity).forEach((key) => newState.rarity[key] = defaultFilter.rarity[key]);
                // newState.rarity = defaultFilter.rarity;
                setFilter(newState);
            }
        } else {
            if (value !== "全部") {
                const newState = Object.assign({}, filter);
                newState.element["全部"] = false;
                newState.element[value as string] = !filter.element[value as string];
                setFilter(newState);
            } else {
                const newState = Object.assign({}, filter);
                Object.keys(newState.element).forEach((key) => newState.element[key] = defaultFilter.element[key]);
                setFilter(newState);
            }
        }
        const collected = collectFilter();
        setFilteredCharacters(multiPropsFilter(collected));
    }

    const collectFilter = () => {
        console.log(filter)
        const collectedTrueKeys = {
            rarity: [],
            element: [],
        };
        for (let rarityKey in filter.rarity) {
            if (filter.rarity[rarityKey]) {
                // console.log(rarityKey)
                // if (rarityKey !== '0') 
                collectedTrueKeys.rarity.push(rarityKey as never);
            }
        }
        for (let elementKey in filter.element) {
            // console.log(elementKey)
            if (filter.element[elementKey]) {
                // if (elementKey !== '全部') 
                collectedTrueKeys.element.push(elementKey as never)
            };
        }
        // console.log(collectedTrueKeys)
        return collectedTrueKeys;
    };

    const multiPropsFilter = (
        filters: { [x: string]: string | any[] }
    ) => {
        const filterKeys = Object.keys(filters);
        return allCharacters.filter((character: { [x: string]: string }) => {
            return filterKeys.every((key) => {
                // console.log(filters[key])
                if (!filters[key].length || (filters[key].includes('全部') || filters[key].includes('0'))) {
                    return true;
                } else {
                    return filters[key].includes(character[key].toString());
                }
            });
        });
    };


    // Handle API Calls
    // const getAllCharactersApiCall = () => {
    //     axios.get(`${API_BASE_URL}/list/characters`, {}).then(
    //         (response) => {
    //             console.log(response.data.data)
    //             setAllCharacters(response.data.data);
    //             setFilteredCharacters(response.data.data);
    //         },
    //         (error) => {
    //             console.log("[DEBUG] TODO", error);
    //         }
    //     );
    // };

    // useEffect(() => {
    //     getAllCharactersApiCall();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
        <div>
            <div>
                <Row type="flex" justify="start" align="middle" style={{ padding: '4px' }}>
                    <Col span={3}>
                        <Title heading={5} style={{ color: '#C58042' }}>稀有度</Title>
                    </Col>
                    {[{ text: "全部", value: 0 }, { text: "五星", value: 5 }, { text: "四星", value: 4 }].map((rarity: any, i: number) => {
                        return (
                            <Col span={2}>
                                <button
                                    key={rarity.value}
                                    className={`my_btn ${filter.rarity[rarity.value] ? 'selected' : ''}`} type="button" value={rarity.value} onClick={e => handleSelectedBtn('rarity', e.currentTarget.value)}>
                                    {rarity.text}
                                </button>
                            </Col>
                        )
                    })}
                </Row>
                <Row type="flex" justify="start" align="middle" style={{ padding: '4px' }}>
                    <Col span={3}>
                        <Title heading={5} style={{ color: '#C58042' }}>元素</Title>
                    </Col>
                    {ele_color_arr.map((ele: any, i: number) => {
                        return (
                            <Col span={2}>
                                <button
                                    key={ele.text}
                                    className={`my_btn ${filter.element[ele.text] ? 'selected' : ''}`}
                                    type="button"
                                    value={ele.text}
                                    // style={{ backgroundColor: ele.color, }}
                                    onClick={e => handleSelectedBtn('element', e.currentTarget.value)}>
                                    {ele.text}
                                </button>
                            </Col>
                        )
                    })}
                </Row>
            </div>
            <br />
            {/* <Row gutter={{ xs: 8, sm: 10, md: 10, lg: 12, xl: 14, xxl: 16 }}>
                {filteredCharacters ?
                    filteredCharacters.map((character: any, i: number) => {
                        return (
                            <Col xs={6} sm={6} md={3} lg={3} xl={2}>
                                <AbyssCharacter character={character} key={character.name + '_' + i} />
                            </Col>
                        );
                    })
                    : <Loading prompt={"角色数据加载中"} />}
            </Row> */}
        </div>
    )
};

export default AbyssCharacterFilter;