import { useNavigate, useLocation, useParams } from "react-router-dom";

import { Button, Card, Col, Form, Row, Select, SideSheet, TabPane, Tabs, Tag, Toast, Typography, useFormState } from '@douyinfe/semi-ui';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { API_BASE_URL } from "config";
import { useEffect, useRef, useState } from "react";
import { TQLImage } from "projectConstants";
import AbyssCharacterFilter from "components/Abyss/AbyssCharacterFilter";
import AbyssCharacter from "components/Abyss/AbyssCharacter";
import Loading from "components/Loading/Loading";
import { Build, TeamBuild } from "api/contest/abyss_api";
import { VideoInfo } from "api/bilibili_api";
import SelectBilibiliVideo from "components/SelectBilibiliVideo/SelectBilibiliVideo";


interface Props {
    isMobile: boolean;
}

interface Filter {
    [key: string]: boolean;
}

const AbyssRecordSubmitPage: React.FC<Props> = ({ isMobile }) => {

    const { t } = useTranslation();

    let navigate = useNavigate();
    const location = useLocation();
    const formRef = useRef<Form<any>>(null);
    const language = "zh-CN";
    const contest_id = useParams()['contest_id'];
    const pathSpilited = location.pathname.split("/");

    const formState = useFormState();
    const [isSidesheetVisible, setSidesheetVisble] = useState<boolean>(false);
    const [allCharacters, setAllCharacters] = useState<any>(null);
    const [filteredCharacters, setFilteredCharacters] = useState<any>(null);
    const [tabActiveKey, setTabActiveKey] = useState<string>("1");

    const [selectedAllCharacter, setSelectedAllCharacter] = useState<any>(null);

    const [selectedCharacter, setSelectedCharacter] = useState<any>(null);
    const [selectedWeapon, setSelectedWeapon] = useState<any>(null);
    const [teamBuild, setTeambuild] = useState<TeamBuild[]>([]);
    const [teamBuildIndex, setTeambuildIndex] = useState<number>(-1);
    const [selectedVideo, setSelectedVideo] = useState<VideoInfo | undefined>();

    // const [timecost1, setTimeCost1] = useState<number>(0);
    // const [timecost2, setTimeCost2] = useState<number>(0);
    // const [timecost3, setTimeCost3] = useState<number>(0);

    // const [totalTime, setTotalTime] = useState<number>(0);
    const [formValues, setFormValues] = useState<any>({});

    const { Text, Paragraph, Title } = Typography;

    const defaultParentFilter = {
        "全部": true,
        "长柄武器": false,
        "单手剑": false,
        "双手剑": false,
        "法器": false,
        "弓": false,
    } as Filter
    const [parentFilter, setParentFilter] = useState<Filter>(JSON.parse(JSON.stringify(defaultParentFilter)));


    // Handle OnClicks
    const handleSelectedParentFilterBtn = (value: string) => {
        const newState = Object.assign({}, parentFilter);
        if (value !== "全部") {
            newState["全部"] = false;
            newState[value as string] = true;
            setParentFilter(newState);
            console.log(parentFilter)
        } else {
            Object.keys(newState).forEach((key) => newState[key] = defaultParentFilter[key]);
            setParentFilter(newState);
        }
        const collected = collectWeaponFilter(newState);
        setSelectedAllCharacter(multiPropsFilter(collected))
        setFilteredCharacters(multiPropsFilter(collected));
    }

    const collectWeaponFilter = (filter: Filter) => {
        const collectedTrueKeys = { weapon_type: [] };
        for (let key in filter) {
            if (filter[key]) {
                // console.log(rarityKey)
                // if (rarityKey !== '0') 
                collectedTrueKeys.weapon_type.push(key as never);
            }
        }
        return collectedTrueKeys;
    };

    const multiPropsFilter = (
        filters: { [x: string]: string | any[] }
    ) => {
        const filterKeys = Object.keys(filters);
        return allCharacters.filter((character: { [x: string]: string }) => {
            return filterKeys.every((key) => {
                // console.log(filters[key])
                if (!filters[key].length || (filters[key].includes('全部'))) {
                    return true;
                } else {
                    return filters[key].includes(character[key].toString());
                }
            });
        });
    };

    const resetParentFilter = () => {
        setParentFilter(defaultParentFilter);
        setSelectedAllCharacter(allCharacters);
        setFilteredCharacters(allCharacters);
    }

    const list = [
        { value: 0, label: '0命' },
        { value: 1, label: '1命' },
        { value: 2, label: '2命' },
        { value: 3, label: '3命' },
        { value: 4, label: '4命' },
        { value: 5, label: '5命' },
        { value: 6, label: '6命' },
    ];

    const weapon_option_list = [
        { value: 1, label: '精炼1' },
        { value: 2, label: '精炼2' },
        { value: 3, label: '精炼3' },
        { value: 4, label: '精炼4' },
        { value: 5, label: '精炼5' },
    ]

    const weaponsType = [
        { value: '全部', label: '全部' },
        { value: '长柄武器', label: '长柄武器' },
        { value: '单手剑', label: '单手剑' },
        { value: '双手剑', label: '双手剑' },
        { value: '法器', label: '法器' },
        { value: '弓', label: '弓' },
    ]


    const { Meta } = Card;

    const toStep2 = (values: any) => {
        console.log(values)
        setFormValues(values);
        setTabActiveKey("2");
        // if (validateBasicFormFields(values) === null) {
        //     setFormValues(values);
        //     setTabActiveKey("2");
        // }
    };

    const getAllCharactersApiCall = () => {
        axios.get(`${API_BASE_URL}/list/characters`, {}).then(
            (response) => {
                console.log(response.data.data)
                setAllCharacters(response.data.data);
                setSelectedAllCharacter(response.data.data);
                setFilteredCharacters(response.data.data);
            },
            (error) => {
                console.log("[DEBUG] TODO", error);
            }
        );
    };

    const handleAddTeamBuild = () => {
        if (teamBuildIndex === -1) {
            if (teamBuild?.length !== 0) {
                setTeambuild([...teamBuild, ...[{
                    character_build: selectedCharacter.character_info as Build,
                    weapon_build: selectedWeapon.weapon_info as Build
                }]])
            } else {
                setTeambuild([{
                    character_build: selectedCharacter.character_info as Build,
                    weapon_build: selectedWeapon.weapon_info as Build as Build
                }])
            };
        } else {
            var tempTeamBuild = teamBuild
            tempTeamBuild[teamBuildIndex] = {
                character_build: selectedCharacter.character_info as Build,
                weapon_build: selectedWeapon.weapon_info as Build as Build as Build
            }
            setTeambuild(tempTeamBuild)
            setTeambuildIndex(-1)
        }
        setSelectedCharacter(null);
        setSelectedWeapon(null);
        resetParentFilter();
    }

    useEffect(() => {
        getAllCharactersApiCall();
        resetParentFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let onChangeVideoSelection = (video: VideoInfo | undefined) => {
        if (video) {
            formRef.current?.formApi.setValue("bvid", video.resource_id);
            setSelectedVideo(video);
        } else {
            formRef.current?.formApi.setValue("bvid", "");
            setSelectedVideo(undefined);
        }
    };


    return (
        <div className="abyss_background" style={{ backgroundImage: `linear-gradient(to left, transparent, white), url(${TQLImage.abyss.cover})` }} >
            <div
                style={{ height: '60px' }}
            // style={{
            //     position: 'relative',
            //     paddingTop: '30px',
            //     justifyContent: 'center',
            //     alignContent: 'center',
            //     //height: height,
            //     width: "100%",
            //     height: '100%',
            //     display: 'inline-block',
            //     padding: 20,
            //     backgroundColor: 'var(--semi-color-fill-0)'
            // }}
            />

            {/* <AbyssCharacterFilter allCharacters={allCharacters} setFilteredCharacters={setFilteredCharacters} />
            <Row gutter={{ xs: 8, sm: 10, md: 10, lg: 12, xl: 14, xxl: 16 }}>
                {filteredCharacters ?
                    filteredCharacters.map((character: any, i: number) => {
                        return (
                            <Col xs={6} sm={6} md={3} lg={3} xl={2}>
                                <AbyssCharacter character={character} key={character.name + '_' + i} />
                            </Col>
                        );
                    })
                    : <Loading prompt={"角色数据加载中"} />}
                </Row> */}

            <div
                // className="container py-3"
                style={{
                    position: 'relative',
                    paddingTop: '30px',
                    // justifyContent: 'center',
                    // alignContent: 'left',
                    //height: height,
                    width: "100%",
                    // height: '100%',
                    display: 'inline-block',
                    padding: 20
                }}>
                <Card
                    style={{ maxWidth: 600, float: "left", top: "50%" }}
                    bordered={false}
                    headerLine={false}
                    shadows="always"
                    // cover={
                    //     <img style={{ height: "150px", objectFit: "cover", objectPosition: "center center" }} src={TQLImage.abyss.cover} />
                    // }
                    bodyStyle={{ margin: '2px' }}

                >
                    <Title heading={2} style={{ color: '#C58042' }}>提交成绩</Title>


                    <Form
                        ref={formRef}
                        labelPosition="left"
                        labelAlign="left"
                        labelWidth={100}
                        // validateFields={validateBasicFormFields}
                        onSubmit={values => Toast.info({ content: JSON.stringify(values) })}
                    >

                        <Tabs
                            type="line"
                            activeKey={tabActiveKey} onTabClick={value => setTabActiveKey(value)}
                        >
                            <TabPane tab="基础信息" itemKey="1">


                                {/* <Form.Input
                                    style={{ width: 200 }}
                                    field='player_name'
                                    label={{
                                        text: '选手名称',
                                        // extra: <Tooltip content='不可重名，请用版本区分'><IconHelpCircle style={{ color: 'var(--semi-color-text-2)' }} /></Tooltip>
                                    }}
                                    placeholder='选手名称'
                                /> */}
                                <Form.InputNumber
                                    hideButtons
                                    field="uid"
                                    label='游戏uid'
                                />
                                <Form.Input
                                    style={{ width: 200 }}
                                    field='bvid'
                                    label={{
                                        text: '投稿bv号',
                                        // extra: <Tooltip content='不可重名，请用版本区分'><IconHelpCircle style={{ color: 'var(--semi-color-text-2)' }} /></Tooltip>
                                    }}
                                    disabled={true}
                                // placeholder='赛道信息'
                                />
                                <Form.Slot>
                                    <SelectBilibiliVideo
                                        selectedVideo={selectedVideo}
                                        onChangeSelection={onChangeVideoSelection} />
                                </Form.Slot>
                                <Form.Select field="track" label='赛道' style={{ width: 200 }}>
                                    <Form.Select.Option value="1">一金</Form.Select.Option>
                                    <Form.Select.Option value="2">二金</Form.Select.Option>
                                    <Form.Select.Option value="free">自由</Form.Select.Option>
                                </Form.Select>
                                <Form.InputNumber
                                    hideButtons
                                    field="cost"
                                    label='成本'
                                    precision={1}
                                />

                                <Form.Slot label={{ text: '阵容' }}>
                                    <Button theme='solid' type='primary' style={{ marginRight: 8, paddingTop: 12, paddingBottom: 12 }} block onClick={() => {
                                        setSidesheetVisble(isSidesheetVisible => !isSidesheetVisible);
                                        resetParentFilter();
                                    }}>
                                        编辑阵容
                                    </Button>
                                </Form.Slot>

                                {teamBuild && <div style={{ display: 'flex' }}>
                                    {teamBuild.map((build: any, index: any) => {
                                        return <div style={{ paddingRight: '2px', paddingLeft: '2px', alignItems: 'center' }} onClick={() => {
                                            console.log(build);
                                            setSidesheetVisble(true);
                                            setTeambuildIndex(index);
                                            setSelectedCharacter(
                                                {
                                                    name: build.character_build.name,
                                                    character_info: build.character_build
                                                });
                                            setSelectedWeapon({
                                                name: build.weapon_build.name,
                                                weapon_info: build.weapon_build
                                            });
                                        }}>
                                            <AbyssCharacter character={build.character_build} showBadge={true} />
                                            <AbyssCharacter character={build.weapon_build} showBadge={true} />
                                        </div>
                                    })}
                                </div>}

                                <div style={{ width: "100%", textAlign: 'right' }}>
                                    <Button
                                        theme="solid"
                                        onClick={toStep2}
                                    // style={{ textAlign: 'center' }}
                                    >
                                        下一步
                                    </Button>
                                </div>

                                {/* </Form> */}

                            </TabPane>

                            <TabPane tab="间均信息" itemKey="2">

                                <Form.InputGroup label={{ text: '第一间详情' }} labelPosition='top'>
                                    <Form.Select style={{ width: 220 }} field='buff_1' initValue='20%_atk' showClear>
                                        <Form.Select.Option value='20%_atk'>【本间生效】加20%大公鸡</Form.Select.Option>
                                        <Form.Select.Option value='20%_hp'>【本间生效】加20%大生命</Form.Select.Option>
                                        <Form.Select.Option value='20%_def'>【本间生效】加20%大防御</Form.Select.Option>
                                    </Form.Select>
                                    <Form.InputNumber
                                        style={{ width: 100 }}
                                        hideButtons
                                        placeholder='用时（秒）'
                                        field="timecost_1"
                                        initValue={0}
                                        onChange={(value) => {
                                            if (formRef.current) {
                                                // will be type HTMLDivElement NOT HTMLDivElement | null
                                                var totalTime = Number(value)
                                                var timecost_2 = formRef.current.formApi.getValue('timecost_2')
                                                if (timecost_2 === undefined) {
                                                    totalTime += 0
                                                } else {
                                                    totalTime += formRef.current.formApi.getValue('timecost_2')
                                                }
                                                var timecost_3 = formRef.current.formApi.getValue('timecost_3')
                                                if (timecost_3 === undefined) {
                                                    totalTime += 0
                                                } else {
                                                    totalTime += formRef.current.formApi.getValue('timecost_3')
                                                }
                                                formRef.current.formApi.setValue('total_timecost', totalTime);

                                            }
                                        }}
                                    />
                                </Form.InputGroup>

                                <Form.InputGroup label={{ text: '第二间详情' }} labelPosition='top'>
                                    <Form.Select style={{ width: 220 }} field='buff_2' initValue='20%_atk' showClear>
                                        <Form.Select.Option value='20%_atk'>【本间生效】加20%大公鸡</Form.Select.Option>
                                        <Form.Select.Option value='20%_hp'>【本间生效】加20%大生命</Form.Select.Option>
                                        <Form.Select.Option value='20%_def'>【本间生效】加20%大防御</Form.Select.Option>
                                    </Form.Select>
                                    <Form.InputNumber
                                        style={{ width: 100 }}
                                        initValue={0}
                                        hideButtons
                                        placeholder='用时（秒）'
                                        field="timecost_2"
                                        onChange={(value) => {
                                            if (formRef.current) {
                                                // will be type HTMLDivElement NOT HTMLDivElement | null
                                                var totalTime = Number(value)

                                                var timecost_1 = formRef.current.formApi.getValue('timecost_1')
                                                if (timecost_1 === undefined) {
                                                    totalTime += 0
                                                } else {
                                                    totalTime += formRef.current.formApi.getValue('timecost_1')
                                                }
                                                var timecost_3 = formRef.current.formApi.getValue('timecost_3')
                                                if (timecost_3 === undefined) {
                                                    totalTime += 0
                                                } else {
                                                    totalTime += formRef.current.formApi.getValue('timecost_3')
                                                }

                                                formRef.current.formApi.setValue('total_timecost', totalTime);

                                            }
                                        }}
                                    />
                                </Form.InputGroup>

                                <Form.InputGroup label={{ text: '第三间详情' }} labelPosition='top'>
                                    <Form.Select style={{ width: 220 }} field='buff_3' initValue='20%_atk' showClear>
                                        <Form.Select.Option value='20%_atk'>【本间生效】加20%大公鸡</Form.Select.Option>
                                        <Form.Select.Option value='20%_hp'>【本间生效】加20%大生命</Form.Select.Option>
                                        <Form.Select.Option value='20%_def'>【本间生效】加20%大防御</Form.Select.Option>
                                    </Form.Select>
                                    <Form.InputNumber
                                        style={{ width: 100 }}
                                        initValue={0}
                                        hideButtons
                                        placeholder='用时（秒）'
                                        field="timecost_3"
                                        onChange={(value) => {
                                            if (formRef.current) {
                                                // will be type HTMLDivElement NOT HTMLDivElement | null
                                                var totalTime = Number(value)
                                                var timecost_1 = formRef.current.formApi.getValue('timecost_1')
                                                if (timecost_1 === undefined) {
                                                    totalTime += 0
                                                } else {
                                                    totalTime += formRef.current.formApi.getValue('timecost_1')
                                                }
                                                var timecost_2 = formRef.current.formApi.getValue('timecost_2')
                                                if (timecost_2 === undefined) {
                                                    totalTime += 0
                                                } else {
                                                    totalTime += formRef.current.formApi.getValue('timecost_2')
                                                }
                                                formRef.current.formApi.setValue('total_timecost', totalTime);

                                            }
                                        }}
                                    />
                                </Form.InputGroup>


                                <Form.InputNumber
                                    width={100}
                                    hideButtons
                                    initValue={0}
                                    field="total_timecost"
                                    label='总用时（秒）'
                                />

                                <div style={{ marginTop: "20px", width: "100%", textAlign: 'right' }}>
                                    <Button
                                        htmlType='submit'
                                        theme="solid"
                                    // style={{ textAlign: 'center' }}
                                    >
                                        提交
                                    </Button>
                                </div>
                            </TabPane>

                        </Tabs>
                    </Form>

                </Card>

                <SideSheet
                    title=""
                    size='large'
                    visible={isSidesheetVisible}
                    //     footer={(<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    //     <Button style={{ marginRight: 8 }}>重置</Button>
                    //     <Button theme="solid">关闭</Button>
                    // </div>)}
                    onCancel={() => {
                        setSidesheetVisble(false);
                        setSelectedCharacter(null);
                        setSelectedWeapon(null);
                        setTeambuildIndex(-1);
                    }}
                >
                    {selectedCharacter ?
                        <Row>
                            <div>
                                <Col span={3}>
                                    <AbyssCharacter
                                        // onClick={() => setSelectedCharacter(null)} 
                                        character={selectedCharacter.character_info} />
                                </Col>
                                <Col span={4}>
                                    <Text strong>请选择角色命座：</Text>
                                    <br></br>
                                    <Select
                                        // placeholder="请选择角色命座" 
                                        defaultValue={selectedCharacter.character_info?.number ? selectedCharacter.character_info?.number : 0}
                                        style={{ width: 180 }}
                                        optionList={list}
                                        onSelect={(value) => {
                                            var tempSelectedChars = selectedCharacter;
                                            tempSelectedChars.character_info.number = value
                                            setSelectedCharacter(tempSelectedChars)
                                        }}></Select>
                                </Col>
                            </div>
                            <Col span={2}></Col>
                            {selectedWeapon ?
                                <div>
                                    <Col span={3}>
                                        <AbyssCharacter character={selectedWeapon.weapon_info} />
                                    </Col>
                                    <Col span={4}>
                                        {/* 请选择武器精炼： */}
                                        <Text strong>请选择武器精炼：</Text>
                                        <Select
                                            // placeholder="请选择武器精炼" 
                                            optionList={weapon_option_list}
                                            defaultValue={selectedWeapon.weapon_info?.number ? selectedWeapon.weapon_info?.number : 1}
                                            style={{ width: 180 }}
                                            onSelect={(value) => {
                                                var tempSelectedWeap = selectedWeapon;
                                                tempSelectedWeap.weapon_info.number = value
                                                setSelectedWeapon(tempSelectedWeap)
                                            }}></Select>
                                    </Col>
                                </div> : <Col span={5}></Col>}
                            <Col span={2} />
                            {selectedCharacter && selectedWeapon &&
                                <Col span={2}>
                                    <Button theme="solid" onClick={handleAddTeamBuild}>
                                        添加
                                    </Button>
                                </Col>}
                        </Row>
                        : null
                    }
                    <div className="parent_filter" style={{ padding: "5px" }}>
                        <Row type="flex" justify="start" align="middle" style={{ padding: '4px', paddingTop: '15px', paddingBottom: '20px' }}>
                            <Col span={3}>
                                <Title heading={5} style={{ color: '#C58042' }}>武器类型</Title>
                            </Col>
                            {weaponsType.map((type: any, i: number) => {
                                return (
                                    <Col span={3}>
                                        <button
                                            key={type.label}
                                            className={`my_btn ${parentFilter[type.label] ? 'selected' : ''}`}
                                            type="button" value={type.value}
                                            onClick={e => handleSelectedParentFilterBtn(e.currentTarget.value)}
                                        >
                                            {type.label}
                                        </button>
                                    </Col>
                                )
                            })}
                        </Row>
                        <div className="character" style={{ backgroundColor: 'var(--semi-color-fill-0)', padding: "5px", borderRadius: "5px" }}>
                            <AbyssCharacterFilter allCharacters={selectedAllCharacter} setFilteredCharacters={setFilteredCharacters} />
                            <Row gutter={{ xs: 8, sm: 10, md: 10, lg: 12, xl: 14, xxl: 16 }}>
                                {filteredCharacters ?
                                    filteredCharacters.map((character: any, i: number) => {
                                        return (
                                            <Col span={3}>
                                                <AbyssCharacter
                                                    onClick={() => {
                                                        var tempCharacter = character;
                                                        tempCharacter.number = 0;
                                                        selectedCharacter && selectedCharacter.name === character.name ?
                                                            setSelectedCharacter(null) :
                                                            setSelectedCharacter({
                                                                name: character.name,
                                                                character_info: tempCharacter
                                                            })
                                                    }
                                                    }
                                                    character={character}
                                                    key={character.name + '_' + i} />
                                            </Col>
                                        );
                                    })
                                    : <Loading prompt={"角色数据加载中"} />}
                            </Row>
                        </div>
                        <br></br>
                        <div className="weapon" style={{
                            backgroundColor: 'var(--semi-color-fill-0)',
                            padding: "5px",
                            paddingTop: "10px",
                            borderRadius: "5px"
                        }}>

                            <Row gutter={{ xs: 8, sm: 10, md: 10, lg: 12, xl: 14, xxl: 16 }}>
                                {selectedAllCharacter ?
                                    selectedAllCharacter.map((character: any, i: number) => {
                                        return (
                                            <Col span={3}>
                                                <AbyssCharacter
                                                    onClick={() => {
                                                        var tempCharacter = character;
                                                        tempCharacter.number = 1;
                                                        selectedWeapon && selectedWeapon.name === character.name ?
                                                            setSelectedWeapon(null) :
                                                            setSelectedWeapon({
                                                                name: character.name,
                                                                weapon_info: tempCharacter,
                                                            })
                                                    }
                                                    }
                                                    character={character}
                                                    key={character.name + '_' + i} />
                                            </Col>
                                        );
                                    })
                                    : <Loading prompt={"武器数据加载中"} />}
                            </Row>
                        </div>
                    </div>
                </SideSheet>
            </div>
        </div>

    );
}

export default AbyssRecordSubmitPage;