import React from "react";
import {
    Spin,
} from "@douyinfe/semi-ui";

interface Props {
    prompt: string
    loading?: boolean
}

const Loading: React.FC<Props> = ({ prompt, loading = true }) => {
    if (loading) {
        return (
            <div className="my-3">
                <span className="m-3">
                    <Spin />
                </span>
                {prompt}
            </div>);
    } else {
        return <></>
    }
};

export default Loading;
