import { useLocation } from "react-router-dom";
import axios from 'axios';
import { API_BASE_URL } from 'config';


import { useEffect, useState } from 'react';
import CharacterNav from 'components/WikiPage/CharacterNav/CharacterNav';



export default function WikiPage() {
    const location = useLocation();
    const pathSpilited = location.pathname.split("/");
    const characterName = pathSpilited[pathSpilited.length - 1];
    const [content, setContent] = useState<string>("");

    // TODO
    const language = "zh-CN";



    const getCharacterApiCall = () => {
        axios.get(`${API_BASE_URL}/wiki/character?name=${characterName}&lang=${language}`, {

        }).then(
            response => {
                console.log(response.data.content);
                setContent(response.data.content);
            },
            error => {
                console.log("[DEBUG] TODO", error);
            }
        )
    }

    useEffect(() => {
        getCharacterApiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'start', marginTop: '30px' }}>

                <CharacterNav />


                <div style={{ display: 'flex', flexDirection: 'column', width: '80%', marginTop: '90px', marginLeft: "max(180px, 10%)" }}>
                    {/* <div style={{ display: 'flex' }}>
                        <h1>{characterName}</h1>
                        <div style={{ display: 'flex', marginTop: '30px', marginLeft: 'auto', marginRight: '200px' }}>
                            <Switch defaultChecked size="large" checkedText="长" uncheckedText="短" />
                        </div>
                    </div> */}



                    <div style={{ marginRight: '-100px'}}>
                        <span dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                </div>


            </div>


        </>
    );
}



