import React, { useEffect, useState } from "react";
import { getCookie } from "utils/cookieHelper";


import {
    Typography,
    Col,
    Row,
    Descriptions,
    Space,
} from "@douyinfe/semi-ui";
import {
    Bar,
    BarChart,
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from "recharts";
import axios from "axios";
import { API_BASE_URL } from "config";
import CardPreviewHorizontal from "../CardPreviewHorizontal/CardPreviewHorizontal";
import { chunkArray } from "utils/generalUtils";
import CardDeckCharacterAvatar from "../CardDeckCharacterAvatar/CardDeckCharacterAvatar";
import ActionCardModal from "../CardDetailModals/ActionCardModal/ActionCardModal";

interface Props {
    cardDeckId: any;
}

const CardDeckInfo: React.FC<Props> = ({
    cardDeckId,
}) => {
    const { Text, Title } = Typography;

    const COLORS = ["#6084a6", "#77b4b7", "#bc995c"];

    const [data, setData] = useState<any>(null);
    const [cost_info, setCostInfo] = useState<any>(null);
    const [type_info, setTypeInfo] = useState<any>(null);
    const [actions_detail, setActionDetails] = useState<any[]>();
    const [nonCharacterCard, setNonCharacterCard] = useState<string>("");
    const [actionCardModalVisible, setActionCardModalVisible] =
        useState(false);

    const getCardInfoAPICall = () => {
        axios.get(`${API_BASE_URL}/tcg/card_deck/info?id=${cardDeckId}`, {
            headers: {
                'Authorization': getCookie("token")
            }
        }).then(
            (response) => {
                setData(response.data.data);
                var data = response.data.data.cost_infos;
                setCostInfo(
                    data.sort(
                        (a: { cost: number }, b: { cost: number }) => a.cost - b.cost
                    )
                );
                setTypeInfo(response.data.data.type_infos);
                if (response.data.data.action_details) {
                    var actionChunks = chunkArray(response.data.data.action_details, 10);
                    setActionDetails(actionChunks);
                }
            },
            (error) => {
                console.log("[DEBUG] TODO", error);
            }
        );
    };

    const onCardClick = (card: any) => {
        console.log(card.name)
        setNonCharacterCard(card);
        setActionCardModalVisible(true);
    };

    useEffect(() => {
        getCardInfoAPICall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
    }: {
        cx: number;
        cy: number;
        midAngle: number;
        innerRadius: number;
        outerRadius: number;
        percent: number;
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor="middle"
                dominantBaseline="central"
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <div>

            <Row style={{ marginBottom: 20 }}>
                <Col span={2}></Col>
                {data?.character_details &&
                    data.character_details.map((card: any) => {
                        return (
                            <Col span={2}>
                                <CardDeckCharacterAvatar card={card} size={75} />
                            </Col>
                        );
                    })
                }
                {/* Placeholder （角色不足时） */}
                {data?.character_details && 3 - data?.character_details.length >= 0 && (
                    [...Array(3 - (data?.character_details)?.length)].map(() => {
                        return (
                            <Col span={2}>
                                <CardDeckCharacterAvatar card={null} size={75} />
                            </Col>
                        );
                    })
                )}

                <Col span={8} offset={1}>
                    <Space vertical align="start">
                        <Title heading={4}>{data?.name}</Title>
                        <Descriptions>
                            <Descriptions.Item itemKey="卡组ID"><Text copyable underline>{"https://tqlib.com/zh-CN/tcg-card-decks/" + data?._id}</Text></Descriptions.Item>
                        </Descriptions>
                    </Space>

                </Col>
                <Col span={6}>
                    <Descriptions>
                        <Descriptions.Item itemKey="合计费用">{data?.total_cost}</Descriptions.Item>
                        <Descriptions.Item itemKey="平均费用">{(Math.round(data?.avg_cost * 100) / 100).toFixed(2)}</Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>

            {actions_detail &&
                actions_detail.map((cards: any) => {
                    return (
                        <Row type="flex" justify="start" align="top" gutter={12} style={{ marginBottom: 12 }}>
                            <Col span={2}></Col>
                            {cards.map((card: any) => {
                                return (
                                    <Col span={2}>
                                        <CardPreviewHorizontal card={card} onCardClick={() => onCardClick(card)} viewCost={true} />
                                    </Col>
                                );
                            })}
                        </Row>
                    );
                })
            }
            {data && type_info && cost_info && (
                <Row style={{ marginBottom: 20 }}>
                    <Col span={2}></Col>
                    <Col span={8}>
                        <Title heading={5}>卡组结构</Title>
                        <ResponsiveContainer width="100%" aspect={1}>
                            <PieChart
                                width={300}
                                height={300}
                                margin={{
                                    top: 10,
                                    right: 20,
                                    left: 20,
                                    bottom: 30,
                                }}
                            >
                                <Pie
                                    data={type_info}
                                    cx={90}
                                    cy={120}
                                    innerRadius={45}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    paddingAngle={2}
                                    dataKey="value"
                                    label={renderCustomizedLabel}
                                    labelLine={false}
                                >
                                    {type_info.map((_entry: any, index: number) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={COLORS[index % COLORS.length]}
                                        />
                                    ))}
                                </Pie>
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                    </Col>
                    <Col span={8}>
                        <Title heading={5}>费用分布</Title>
                        <ResponsiveContainer width="100%" aspect={1}>
                            <BarChart
                                width={300}
                                height={300}
                                data={cost_info}
                                margin={{
                                    top: 30,
                                    right: 30,
                                    left: 5,
                                    bottom: 30,
                                }}
                            >
                                <Bar
                                    name="无色元素"
                                    dataKey="universal"
                                    fill="#575757"
                                    label={{ position: "top" }}
                                />
                                <Bar
                                    name="同色元素"
                                    dataKey="same"
                                    fill="#cfc9bc"
                                    label={{ position: "top" }}
                                />
                                <Legend />
                                <XAxis dataKey="cost" />
                                <YAxis />
                            </BarChart>
                        </ResponsiveContainer>
                    </Col>
                </Row>
            )}

            {actions_detail && (
                <ActionCardModal
                    visible={actionCardModalVisible}
                    card={nonCharacterCard}
                    onCancel={() => setActionCardModalVisible(false)}
                />
            )}


        </div>
    );
};

export default CardDeckInfo;
