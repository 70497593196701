
import React from 'react';

import { Tag, Modal, Typography, Image, Divider } from '@douyinfe/semi-ui';

import { TCG_CARD_PREVIEW_VERTICAL_WIDTH_PX, TCG_CARD_PREVIEW_VERTICAL_HEIGHT_PX } from 'projectConstants';
import CardCost from 'components/Tcg/CardCost/CardCost';
import { TagColor } from '@douyinfe/semi-ui/lib/es/tag/interface';


interface Props {
    card: any;
    visible: boolean;
    onCancel: () => void;
}

const CharacterCardModal: React.FC<Props> = ({ card, visible, onCancel }) => {
    const { Text, Title } = Typography;

    const ele_color_map = new Map<string, TagColor>([
        ["岩", "amber"],
        ["水", "blue"],
        ["雷", "purple"],
        ["风", "cyan"],
        ["火", "red"],
        ["草", "green"],
        ["冰", "light-blue"],
    ]);

    return (
        <Modal
            title={null}
            footer={null}
            visible={visible}
            afterClose={onCancel}
            onCancel={onCancel}
            closeOnEsc={true}
            style={{ width: 'calc(100vw - 60px)', maxWidth: '960px' }}
            size='large'
        >
            {card && <div style={{ padding: "30px", display: "flex", flexDirection: "row" }}>
                {/* 左侧 */}
                <div>
                    <Image
                        width={TCG_CARD_PREVIEW_VERTICAL_WIDTH_PX}
                        height={TCG_CARD_PREVIEW_VERTICAL_HEIGHT_PX}
                        src={card.img}
                        preview={false}
                        style={{ borderRadius: "5px" }}
                    />
                    {/* <div style={{ paddingLeft: "10px", paddingTop: "20px" }}>
                        <Title heading={6} type="secondary" >价格: {card.price}</Title>
                    </div> */}
                </div>
                {/* 右侧 */}
                <div style={{ paddingLeft: "50px", width: "100%", display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Title >{card.name}</Title>
                    </div>
                    <div style={{}}>
                        <Tag size="large" color="violet" >#{card.card_type}</Tag>
                        <Tag size="large" color={ele_color_map.get(card.element)} style={{ marginLeft: "5px" }} >#{card.element}</Tag>
                        <Tag size="large" color="violet" style={{ marginLeft: "5px" }} >#{card.weapon}</Tag>
                    </div>
                    {/* 技能 */}
                    <div>
                        {
                            card.skills.map((item: any) => {
                                return (
                                    <div key={item.title} style={{ paddingTop: "10px", display: "flex", flexDirection: "column" }}>
                                        <Divider margin='10px' />
                                        {/* 标题行：技能标题，类型，费用 */}
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <Title heading={3} type="secondary" >{item.title}</Title>
                                                <Title heading={6} type="quaternary" style={{ paddingLeft: "30px", paddingTop: "3px" }} >{item.type}</Title>
                                            </div>
                                            <CardCost
                                                card={
                                                    {
                                                        cost: {
                                                            specify: item.cost.specify,
                                                            universal: item.cost.universal,
                                                            energy: item.cost.energy
                                                        },
                                                        element: card.element
                                                    }
                                                }
                                                size_px={30}
                                            />
                                        </div>
                                        {/* 技能简介、备注 */}
                                        <div style={{ paddingLeft: "15px", paddingTop: "10px" }}>
                                            <Title heading={6} type="secondary" >{item.effect}</Title>
                                            {item.note ? <Text type="quaternary" >{item.note}</Text> : ""}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>}
        </Modal>
    );
};


export default CharacterCardModal;
