import { useEffect, useState } from 'react';
import UploadAvatarDialog from 'components/userProfile/UploadAvatarDialog';
import { UserInfoState, loadUserInfo } from 'states/user_info';
import { useDispatch, useSelector } from 'react-redux';
import auth_api from 'api/auth_api';
import { Toast } from '@douyinfe/semi-ui';
import { useNavigate } from 'react-router-dom';
import { relPath } from 'utils/navigateHelpers';
import Title from 'utils/title';
import BilibiliAccountInfo from 'components/BilibiliAccountInfo/BilibiliAccountInfo';
import { Button, Card, Descriptions, Space } from '@douyinfe/semi-ui';

export default function UserProfilePage() {
    const [allowResendEmail, setallowResendEmail] = useState<boolean>(true);
    const userInfo: UserInfoState = useSelector(
        (state: any) => state.user_info);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const language = "zh-CN";
    const contest = {
        _id:"uid",
        name: "比赛名",
        start_time:100000000,
        end_time:200000000,
        bannerImg:""
    };
    const { Meta } = Card;
    useEffect(()=> {
        loadUserInfo(dispatch, userInfo);
    }, [dispatch, userInfo]);

    const [uploadAvatarDialogVisible, setUploadAvatarDialogVisible] = useState(false);
    if (userInfo.info == null) {
        return <div className='container'>
            <h1><Title title="个人主页" /></h1>
            <p>无法获取账号信息！请先登录。</p>
        </div>;
    }
    const info = userInfo.info!;

    return <div className='container'>
        <h1><Title title="个人主页" /></h1>
        <div className='row'>
            <div className='col-md-4'>
                <div className='p-3 m-3 rounded-3 border shadow'>
                    <h2>头像</h2>
                    <button className='btn btn-primary'
                            disabled={true}
                            onClick={() => setUploadAvatarDialogVisible(true)}>
                        上传头像
                    </button>

                    <h2>用户名</h2>
                    {info.username}
                    {info.approved? "(已审核)" : "（未审核）"}

                    <h2>密码</h2>
                    <p>
                        <button className='btn btn-primary'
                            disabled={!allowResendEmail}
                            onClick={() =>
                                navigate(relPath('/auth/reset_password'))}
                        >
                            重置密码
                        </button>
                    </p>
                </div>
            </div>
            <div className='col-md-8'>
                <div className='p-3 m-3 rounded-3 border shadow'>

                    <h2>邮箱</h2>
                    <p>
                        {info.email}
                        {info.email_verified? "（已验证）" : "（未验证）"}
                    </p>
                    <p>
                        <button className='btn btn-primary'
                            disabled={!allowResendEmail || info.email_verified}
                            onClick={() => {
                                setallowResendEmail(false);
                                auth_api.sendVerificationEmail(
                                    (_response) => {
                                        Toast.success("已发送验证邮件");
                                    });
                            }}>
                            重发验证邮件
                        </button>
                    </p>

                    <h2>Bilibili账号</h2>
                    <BilibiliAccountInfo />

                    <h2>系统信息</h2>
                    <p>
                        <b>注册时间：</b>
                        {new Date(info.sign_up_timestamp * 1000).toLocaleString()}
                    </p>
                    <p>
                        <b>最近登录：</b>
                        {new Date(info.last_login_timestamp * 1000).toLocaleString()}
                    </p>
                    {info.superuser? <b>超级管理员</b> : null}
                </div>

                <div className='p-3 m-3 rounded-3 border shadow'>
                <h2>我的比赛</h2>
                <Card
                    cover={
                        <img
                            height="100px"
                            width="100%"
                            style={{ objectFit: "cover" }}
                            alt="tcg"
                            src={contest.bannerImg ? contest.bannerImg : "https://i.ytimg.com/vi/HIOExazTZNI/maxresdefault.jpg"}
                        />
                    }
                    footer={
                        <Button block style={{ backgroundColor: "#D57804" }} theme='solid' type='primary' onClick={() => window.open(`/${language}/contest/tcg/${contest._id}`, '_blank')}>查看详情</Button>

                    }
                >
                    <Meta title={contest.name}
                    // avatar={
                    //     <Avatar
                    //         alt='tcg game default'
                    //         size="default"
                    //         src='https://i01piccdn.sogoucdn.com/dcc8453185dcb299'
                    //     />
                    // }
                    />
                    <Space />
                    <Descriptions align="left"> 
                        <Descriptions.Item itemKey="开始时间">{contest.start_time ? new Date(contest.start_time * 1000).toLocaleDateString() : '暂无'}</Descriptions.Item>
                        <Descriptions.Item itemKey="结束时间">{contest.end_time ? new Date(contest.end_time * 1000).toLocaleDateString() : '暂无'}</Descriptions.Item>
                    </Descriptions>
                </Card>
                </div>
            </div>
        </div>

        <UploadAvatarDialog 
            visible={uploadAvatarDialogVisible}
            onUploadAvatarSuccess={() => {}}
            onCancel={() => setUploadAvatarDialogVisible(false)}
        />
    </div>;
}
