import api, {OnErrorFn, OnResponseFn} from 'api/api';

export interface DetectByImageResponse {
    characters: string[],
    actions: string[],
};

function detect_by_image(
        request: FormData,
        onResponse?: OnResponseFn<DetectByImageResponse>,
        onError?: OnErrorFn) {
    api.post('/tcg/card_deck/detect_by_image', {
        data: request,
        onResponse: onResponse,
        onError: onError,
    });
}

const card_deck_api = {
    detect_by_image,
};
export default card_deck_api;
