import api, { OnErrorFn, OnResponseFn } from 'api/api'

export interface RegisterRequest {
    username: string,
    email: string,
    ys_uid: string,
    qq: string,
    contest_code: string,
    custom_questions: CustomQuestions[],
    characters: string[],
    actions: string[],
    avatar: string
};

export interface LoginRegisterRequest {
    username: string,
    contest_code: string,
    custom_questions: CustomQuestions[],
    avatar: string
};

export interface CustomQuestions {
    question: string,
    answer: string,
}

export interface CreateRequest {
    name: string,
    rule: string,
    hosts: string[],
    start_time: number,
    end_time: number,
}

function register(
    request: RegisterRequest,
    onResponse?: OnResponseFn<null>,
    onError?: OnErrorFn) {
    api.post('/contest/tcg/register', {
        data: request,
        onResponse: onResponse,
        onError: onError,
    });
}

function loginRegister(
    request: RegisterRequest,
    onResponse?: OnResponseFn<null>,
    onError?: OnErrorFn) {
    api.post('/contest/tcg/register', {
        data: request,
        onResponse: onResponse,
        onError: onError,
    });
}

function create(
    request: CreateRequest,
    onResponse?: OnResponseFn<null>,
    onError?: OnErrorFn) {
    api.post('/contest/tcg/create', {
        data: request,
        onResponse: onResponse,
        onError: onError,
    });
}

const contest_api = {
    register, loginRegister, create
};
export default contest_api;
