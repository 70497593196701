
import React from 'react';
import { useTranslation } from "react-i18next";
import {  Modal, Typography} from '@douyinfe/semi-ui';


interface Props {
    visible: boolean;
    onClose: () => void;
}

const UserAgreementModal: React.FC<Props> = ({ visible, onClose }) => {
    const { t } = useTranslation();
    const { Text } = Typography;


    return (
        <Modal
            title={"用户协议"}
            size={"large"}
            visible={visible}
            okText={`${t("actions.close")}`}
            onOk={onClose}
            onCancel={onClose}
            hasCancel={false}
            closeOnEsc={true}
            bodyStyle={{ overflow: 'auto', height: 400 }}
        >
            <div style={{ paddingBottom: "30px", paddingLeft: "10px" }}>
                <Text>请用户自觉遵守法律法规，共同维护良好的网络环境。</Text>
                <p style={{ lineHeight: 1.8 }}>
                    在以下情形之下，本站有权封禁、冻结或删除用户：
                </p>
                <ul>
                    <li>
                        <p>用户在任何板块发布不当言论</p>
                    </li>
                    <li>
                        <p>用户名中包含不当言论</p>
                    </li>
                </ul>
                <p>目前用户名为本站用户唯一识别信息，请妥善保管。暂时不支持用户名修改。</p>
                <Text>目前本站暂不支持找回密码，遂如忘记密码可能造成无法找回账号。请妥善保管登录信息，此功能将在后续更新中尽快加入。</Text>

            </div>
        </Modal>
    );
};


export default UserAgreementModal;
