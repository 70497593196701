
import React, { useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { Form, Modal, Button, Typography, Toast, Tooltip } from '@douyinfe/semi-ui';
import { IconHelpCircle } from '@douyinfe/semi-icons';

import { setSignInCookies } from 'utils/cookieHelper';

import { USERNAME_LENGTH_MIN, USERNAME_LENGTH_MAX, PASSWORD_LENGTH_MIN, PASSWORD_LENGTH_MAX } from 'projectConstants';
import UserAgreementModal from '../UserAgreementModal/UserAgreementModal';
import auth_api from 'api/auth_api';
import { relPath } from 'utils/navigateHelpers';
import bilibiliUtils from 'utils/bilibiliUtils';
import { BASE_URL } from 'config';


interface Props {
    visible: boolean;
    onSignInSuccess: () => void;
    onSignUpSuccess: () => void;
    onCancel: () => void;
}

const SignInSignUpDialog: React.FC<Props> = ({ visible, onSignInSuccess, onSignUpSuccess, onCancel }) => {
    const { t } = useTranslation();
    const { Text } = Typography;

    const formRef = useRef<Form<any>>(null);;
    const [loading, setLoading] = useState(false);
    const [signUpMode, setSignUpMode] = useState(false);
    const [userAgreementModalVisible, setUserAgreementModalVisible] = useState(false);

    const onSubmitSignInForm = (values: any) => {
        setLoading(true);
        auth_api.signIn({
                username: values.username,
                password: values.password,
            },
            response => {
                setLoading(false)
                Toast.success({ content: "登录成功", duration: 3 });
                setSignInCookies(response);
                onSignInSuccess();
            },
            (_error)=>{setLoading(false);}
        );
    };

    const onSubmitSignUpForm = (values: any) => {
        delete values["confirm"];
        auth_api.signUp({
                username: values.username,
                password: values.password,
                email: values.email
            },
            response => {
                setSignInCookies(response);
                onSignUpSuccess();
            },
            setLoading);
    };

    const onFieldsChange = (_values: any) => {
        formRef.current?.formApi.validate().catch(() => {});
    }

    const validateSignInFields = (values: any) => {
        const errors: any = {};
        if (!values.username || values.username.length < USERNAME_LENGTH_MIN || values.username.length > USERNAME_LENGTH_MAX) {
            errors.username = t("signin.validations.validationUsernameLength");
        }
        if (!values.password || values.password.length < PASSWORD_LENGTH_MIN || values.password.length > PASSWORD_LENGTH_MAX) {
            errors.password = t("signin.validations.validationPasswordLength");
        }

        return Object.keys(errors).length !== 0 ? errors : null;
    }

    const validateSignUpFields = (values: any) => {
        const errors: any = {};
        if (!values.username || values.username.length < USERNAME_LENGTH_MIN || values.username.length > USERNAME_LENGTH_MAX) {
            errors.username = t("signin.validations.validationUsernameLength");
        }
        if (!values.password || values.password.length < PASSWORD_LENGTH_MIN || values.password.length > PASSWORD_LENGTH_MAX) {
            errors.password = t("signin.validations.validationPasswordLength");
        }
        if (!values.confirm || values.confirm !== values.password) {
            errors.confirm = t("signin.validations.validationPasswordNotSame");
        }
        return Object.keys(errors).length !== 0 ? errors : null;
    }

    return (
        <Modal
            title={signUpMode ? t("signin.signup") : t("signin.signin")}
            visible={visible}
            footer={null}
            afterClose={onCancel}
            onCancel={onCancel}
            closeOnEsc={true}
        >
            <div style={{ paddingBottom: "30px", paddingLeft: "10px" }}>
                {/* Sign In Form */}
                {signUpMode ||
                    <Form
                        layout='vertical'
                        ref={formRef}
                        onValueChange={onFieldsChange}
                        validateFields={validateSignInFields}
                        onSubmit={onSubmitSignInForm}
                    >
                        <Form.Input field='username' style={{ width: 200 }}
                            label={t('signin.username_or_email')} />
                        <Form.Input field='password' style={{ width: 200 }}
                            label={t('signin.password')} mode="password" />
                        <div style={{ marginTop: "30px", width: "100%" }}>
                            <Button
                                htmlType='submit'
                                theme="solid"
                                loading={loading}
                            >
                                {t("signin.signin")}
                            </Button>
                            <BilibiliSignInButton />
                            <a
                                className='mx-3'
                                href='javascript:void(0)'
                                onClick={() => setSignUpMode(true)}
                            >
                                {t("signin.createNewAccount")}
                            </a>
                            <a href={relPath('/auth/reset_password')}>
                                忘记密码
                            </a>
                        </div>
                    </Form>
                }

                {/* Sign Up Form */}
                {signUpMode &&
                    <Form
                        layout='vertical'
                        ref={formRef}
                        onValueChange={onFieldsChange}
                        validateFields={validateSignUpFields}
                        onSubmit={onSubmitSignUpForm}
                    >
                        {({ values }) => (
                            <div>
                                <Form.Input field='username'
                                    style={{ width: 200 }}
                                    label={t('signin.username')} />
                                <Form.Input field='email'
                                    style={{ width: 200 }}
                                    label={t('signin.email')} />
                                <Form.Input field='password'
                                    style={{ width: 200 }}
                                    label={t('signin.password')}
                                    mode="password" />
                                <Form.Input field='confirm'
                                    style={{ width: 200 }}
                                    label={t('signin.confirmPassword')}
                                    mode="password" />

                                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center'}}>
                                    <Form.Checkbox field='agree' noLabel>
                                    </Form.Checkbox>
                                    <div style={{ paddingLeft: '5px', display: 'flex', flexDirection: "row", alignItems: 'center' }}>
                                        {"我已阅读并同意本站"}
                                        <Text
                                            link
                                            onClick={() => setUserAgreementModalVisible(true)}
                                        >
                                            {"用户协议"}
                                        </Text>
                                        &nbsp;
                                        <Tooltip content='注册即表示同意本站用户协议'><IconHelpCircle style={{ color: 'var(--semi-color-text-2)' }} /></Tooltip>
                                    </div>
                                </div>

                                <div style={{ marginTop: "30px", width: "100%" }}>
                                    <Button
                                        htmlType='submit'
                                        theme="solid"
                                        loading={loading}
                                        disabled={!values.agree}
                                    >
                                        {t("signin.signup")}
                                    </Button>
                                    <Text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("signin.alreadyHaveAnAccount")}&nbsp;&nbsp;&nbsp;</Text>
                                    <Text
                                        link
                                        onClick={() => setSignUpMode(false)}
                                    >
                                        {t("signin.signin")}
                                    </Text>
                                </div>
                            </div>
                        )}
                    </Form>
                }

                <UserAgreementModal visible={userAgreementModalVisible} onClose={() => setUserAgreementModalVisible(false)} />
            </div>
        </Modal>
    );
};
export default SignInSignUpDialog;

const BilibiliSignInButton = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const onClick = () => {
        setLoading(true);
        bilibiliUtils.login(
            BASE_URL + 'auth/bilibili_callback/login',
            window.location.href,
            (url: string)=>{window.location.replace(url);});
    }
    return <Button className='btn btn-primary mx-3' onClick={onClick} loading={loading}>
        Bilibili登录
    </Button>;
}
