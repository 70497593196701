import api, { OnErrorFn, OnResponseFn } from 'api/api'

export interface VersionInfo {
    build_time_string: string,
    build_timestamp: number,
    builder: string,
    git_sha: string,
}


function frontendVersion(
    onResponse?: OnResponseFn<VersionInfo>,
    onError?: OnErrorFn) {
    api.get('./version.json', {
        onResponse: onResponse,
        onError: onError,
    });
}

function backendVersion(
    onResponse?: OnResponseFn<VersionInfo>,
    onError?: OnErrorFn) {
    api.get('/backend_version', {
        onResponse: onResponse,
        onError: onError,
    });
}

const system_api = {
    frontendVersion,
    backendVersion,
};
export default system_api;
