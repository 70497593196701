import { useEffect, useMemo, useState } from "react";
import { getCookie } from "utils/cookieHelper";
import { Typography, Button, Table, TabPane, Tabs, Toast, Popconfirm } from "@douyinfe/semi-ui";
import axios from "axios";
import { API_BASE_URL } from "config";
import { useSelector } from "react-redux";
import { UserInfoState } from "states/user_info";


export default function AdminPage() {
    const userInfo: UserInfoState = useSelector(
        (state: any) => state.user_info);

    const [_cards, setCards] = useState<any>(null);
    const [cardDecks, setCardDecks] = useState<any>(null);
    const [users, setUsers] = useState<any>(null);


    const { Text } = Typography;


    const language = "zh-CN";

    const getCardsApiCall = () => {
        axios.get(`${API_BASE_URL}/tcg/card_list?lang=${language}`, {

        }).then(
            response => {
                setCards(response.data.data);
            },
            error => {
                console.log("[DEBUG] TODO", error);
            }
        )
    }

    const getCardDecksApiCall = () => {
        axios.get(`${API_BASE_URL}/tcg/card_deck/get_all`, {
            headers: {
                'Authorization': getCookie("token")
            }
        }).then(
            response => {
                setCardDecks(response.data.data);
            },
            error => {
                console.log("[DEBUG] TODO", error);
            }
        )
    }

    const getAllUsersApiCall = () => {
        axios.get(`${API_BASE_URL}/list/users`, {
            headers: {
                'Authorization': getCookie("token")
            }
        }).then(
            response => {
                setUsers(response.data.data);
            },
            error => {
                console.log("[DEBUG] TODO", error);
            }
        )
    }


    useEffect(() => {
        getCardsApiCall();
        getCardDecksApiCall();
        getAllUsersApiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const onApproveCardDeck = (record: any, approved: boolean) => {
        axios.post(`${API_BASE_URL}/tcg/review/approve`, {
            id: record._id,
            approved: approved
        }, {
            headers: {
                'Authorization': getCookie("token")
            }
        }).then(
            _response => {
                Toast.success(`操作成功！卡组id = ${record._id}`);
                getCardDecksApiCall();
            },
            error => {
                console.log("[DEBUG] Error: ", error);
            }
        )
    }

    const onApproveUser = (record: any, approved: boolean) => {
        axios.post(`${API_BASE_URL}/review/username`, {
            username: record.username,
            approved: approved
        }, {
            headers: {
                'Authorization': getCookie("token")
            }
        }).then(
            _response => {
                Toast.success(`操作成功！用户 = ${record._id}，用户名 = ${record.username} 已通过`);
                getAllUsersApiCall();
            },
            error => {
                console.log("[DEBUG] Error: ", error);
            }
        )
    }

    const user_columns = [
        {
            title: '用户名',
            dataIndex: 'username',
        },
        {
            title: '管理员权限',
            dataIndex: 'superuser',
            render: (text: any) => {
                console.log(text);
                return (
                    <div>
                        <Text type={text ? "success" : "danger"}>{`${text}`}</Text>
                    </div>
                );
            },
        },
        {
            title: '注册时间',
            dataIndex: 'sign_up_timestamp',
            render: (text: any) => {

                var date = text ? new Date(text * 1000) : null;
                return (
                    <div>
                        <Text>{date ? date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() : "无数据"}</Text>
                    </div>
                );
            },
        },
        {
            title: '最后登录',
            dataIndex: 'last_login_timestamp',
            render: (text: any) => {

                var date = text ? new Date(text * 1000) : null;
                return (
                    <div>
                        <Text>{date ? date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() : "无数据"}</Text>
                    </div>
                );
            },
        },
        {
            title: '是否过审',
            dataIndex: 'approved',
            render: (text: any) => {
                // console.log(text);
                return (
                    <div>
                        <Text type={text ? "success" : "danger"}>{`${text}`}</Text>
                    </div>
                );
            },
        },
        {
            title: '操作',
            render: (_text: any, record: any) => {
                return (
                    <>
                        <Button theme='solid' type='primary' onClick={() => onApproveUser(record, true)}>通过</Button>
                        <Button style={{ marginLeft: "20px" }} type='danger' onClick={() => onApproveUser(record, false)}>不通过</Button>
                    </>
                )
            },
        },
    ]

    const columns = [
        {
            title: '卡组名称',
            dataIndex: 'name',
        },
        {
            title: '用户名',
            dataIndex: 'username',

        },
        {
            title: '角色卡',
            dataIndex: 'characters',
            width: 100,
            render: (text: any) => {
                return (
                    <div>
                        {
                            text?.map((charName: any) => <p>{charName}</p>)
                        }
                    </div>
                );
            },
        },
        {
            title: '行动卡',
            dataIndex: 'actions',
            width: 500,
            render: (text: any) => {
                return (
                    <div>
                        {text?.join(" || ")}
                    </div>
                );
            },
        },
        {
            title: '是否过审',
            dataIndex: 'approved',
            render: (text: any) => {
                console.log(text);
                return (
                    <div>
                        <Text type={text ? "success" : "danger"}>{`${text}`}</Text>
                    </div>
                );
            },
        },
        {
            title: '操作',
            render: (_text: any, record: any) => {
                return (
                    <>
                        <Button theme='solid' type='primary' onClick={() => onApproveCardDeck(record, true)}>通过</Button>
                        <Button style={{ marginLeft: "20px" }} type='danger' onClick={() => onApproveCardDeck(record, false)}>不通过</Button>
                    </>
                )
            },
        },
    ];

    const pagination = useMemo(
        () => ({
            pageSize: 20,
        }),
        []
    );

    const rowSelection = {
        getCheckboxProps: (record: any) => ({
            disabled: record.name === '设计文档', // Column configuration not to be checked
            name: record.name,
        }),
        onSelect: (record: any, selected: any) => {
            console.log(`select row: ${selected}`, record);
        },
        onSelectAll: (selected: any, selectedRows: any) => {
            console.log(`select all rows: ${selected}`, selectedRows);
        },
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
    };

    const onRebuildDatabase = () => {
        axios.post(`${API_BASE_URL}/page/rebuild_database`, {
        }, {
            headers: {
                'Authorization': getCookie("token")
            }
        }).then(
            _response => {
                Toast.success(`数据库重建成功`);
                getCardDecksApiCall();
            },
            error => {
                console.log("[DEBUG] Error: ", error);
            }
        )
    }

    return (
        <>
            {userInfo.info?.superuser &&
                <div>
                    <div style={{ marginTop: '80px' }} />
                    <Text>当前您正在使用的用户是: {userInfo.info.username}</Text>

                    <Tabs type="line" >
                        <TabPane tab="卡组" itemKey="1">
                            {cardDecks &&
                                <>
                                    <Table
                                        columns={columns}
                                        dataSource={cardDecks}
                                        rowSelection={rowSelection}
                                        pagination={pagination}
                                    />
                                </>

                            }
                        </TabPane>
                        <TabPane tab="用户" itemKey="2" >
                            {users &&
                                <>
                                    <Table
                                        columns={user_columns}
                                        dataSource={users}
                                        pagination={pagination}
                                    />
                                </>

                            }
                        </TabPane>
                        <TabPane tab="重建数据库" itemKey="3" >
                            <Popconfirm
                                title="确定重建数据库？"
                                content="请确认重建操作不会影响当前环境, "
                                onConfirm={onRebuildDatabase}
                                onCancel={() => { }}
                                okType="danger"
                                okText="确认"
                            >
                                <Button
                                    type="danger"
                                    style={{ marginLeft: "50px", cursor: "pointer" }}
                                >
                                    {"重建数据库"}
                                </Button>
                            </Popconfirm>
                        </TabPane>
                    </Tabs>

                </div>
            }
        </>
    );
}
