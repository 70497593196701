import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getCookie } from "utils/cookieHelper";
import { Button, Table, TabPane, Tabs, Avatar, List, Col, Row, Divider } from "@douyinfe/semi-ui";
import axios from "axios";
import { API_BASE_URL } from "config";
import CardDeckCharacterAvatar from "components/Tcg/CardDeckCharacterAvatar/CardDeckCharacterAvatar";
import Loading from "components/Loading/Loading";

export default function TcgContestAdminPage() {
    const contest_id = useParams()['contest_id'];
    const [cards, setCards] = useState<any>(null);
    // const [isHost, setIsHost] = useState<boolean>(false);
    const [usersData, setUsersData] = useState<any>(null);
    const language = "zh-CN";

    const getAllParticipantsApiCall = () => {
        axios.get(`${API_BASE_URL}/contest/tcg/info/players?id=${contest_id}`, {
            headers: {
                'Authorization': getCookie("token")
            }
        }).then(
            response => {
                setUsersData(response.data.player_profiles || []);
            },
            error => {
                console.log("[DEBUG] TODO", error);
            }
        )
    }

    const getCardsApiCall = () => {
        axios.get(`${API_BASE_URL}/tcg/card_list?lang=${language}`, {

        }).then(
            response => {
                setCards(response.data.data);
            },
            error => {
                console.log("[DEBUG] TODO", error);
            }
        )
    }


    useEffect(() => {
        getCardsApiCall();
        getAllParticipantsApiCall();
        // getContestInfoApiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // const onApproveCardDeck = (record: any, approved: boolean) => {
    //     axios.post(`${API_BASE_URL}/tcg/review/approve`, {
    //         id: record._id,
    //         approved: approved
    //     }, {
    //         headers: {
    //             'Authorization': getCookie("token")
    //         }
    //     }).then(
    //         response => {
    //             Toast.success(`操作成功！卡组id = ${record._id}`);
    //             // getCardDecksApiCall();
    //         },
    //         error => {
    //             console.log("[DEBUG] Error: ", error);
    //         }
    //     )
    // }

    const user_columns = [
        {
            title: '用户',
            dataIndex: 'username',
            width: 300,
            render: (text: any, record: any, index: number) => {
                return (
                    <div>
                        <Avatar
                            alt="avatar"
                            size="small"
                            src={'https://www.tqlib.com/img/avatar/' + record.avatar_url}
                            style={{ margin: 4 }}
                        />{text}
                    </div>
                )
            }
        },
        {
            title: 'QQ号',
            dataIndex: 'qq',
        },
        {
            title: '原神UID',
            dataIndex: 'ys_uid',
        },
    ]

    const expandRowRender = (record: any, index: number | undefined) => {
        if (index !== undefined && usersData[index].card_decks) {
            return <List
                // bordered
                dataSource={usersData[index].card_decks}
                renderItem={item => (
                    <List.Item
                        main={<Row style={{ width: '100%' }}>
                            {
                                item.characters &&
                                item.characters.map((characterCardName: any) => {
                                    const characterCard = cards.find((card: any) => card.name === characterCardName);
                                    return (
                                        <Col span={6}>
                                            <CardDeckCharacterAvatar card={characterCard} size={50} />
                                        </Col>
                                    );
                                })

                            }
                        </Row>}
                        extra={
                            <Button onClick={() => window.open(`/${language}/tcg-card-decks/${item.card_deck_id}`, '_blank')}>查询卡组详情</Button>
                        }
                    />
                )}
            />
        } else {
            return <div style={{ padding: '10px' }}>暂无卡组数据</div>
        }
    };

    const pagination = useMemo(
        () => ({
            pageSize: 20,
        }),
        []
    );

    interface PlayerProfile {
        username: string;
        qq: string;
        ys_uid: string;
        avatar_url: string;
        card_decks: CardDeck[];
    }

    interface CardDeck {
        _id: string;
        username: string;
        name?: string;
        characters?: string[];
        actions?: string[];
        lang: string;
        modification_timestamp: number;
        public: boolean;
        contest_id: string;
    }

    const jsonToCsv = (data: PlayerProfile[]): string => {
        // Flatten the nested data into a tabular format
        const headers = ['选手名', 'qq', '原神uid', '头像', '卡组名', '角色卡', '', '', '行动卡'];
        const rows = data.flatMap((profile) =>
            profile.card_decks ? profile.card_decks.map((deck) => (
                {
                    username: profile.username,
                    qq: profile.qq,
                    ys_uid: profile.ys_uid,
                    avatar_url: 'https://www.tqlib.com/img/avatar/' + profile.avatar_url,
                    deck_name: deck.name ? deck.name : "",
                    characters: deck.characters ? deck.characters.join(',') : "",
                    actions: deck.actions ? deck.actions.join(',') : "",
                })) : [profile].map(() => (
                    {
                        username: profile.username,
                        qq: profile.qq,
                        ys_uid: profile.ys_uid,
                        avatar_url: 'https://www.tqlib.com/img/avatar/' + profile.avatar_url,
                        deck_name: "",
                        characters: "",
                        actions: "",
                    }))
        );

        // Convert the tabular data to a CSV string
        const csvContent = [headers.join(','), ...rows.map((row) => Object.values(row).join(','))].join('\n');

        return csvContent;
    };

    const savePlayerProfiles = () => {
        var data = jsonToCsv(usersData)
        console.log(data)
        var FileSaver = require('file-saver');
        var blob = new Blob([data], { type: "text/plain;charset=utf-8" });
        FileSaver.saveAs(blob, "赛事信息.csv");
    };

    return (
        <>
            {usersData ?
                <div>
                    <div style={{ marginTop: '80px' }} />
                    <Button
                        theme="solid"
                        onClick={savePlayerProfiles}
                    >
                        {"下载赛事信息CSV"}
                    </Button>
                    <Divider margin='20px' />
                    <Tabs type="line" >
                        <TabPane tab="选手信息" itemKey="1">

                            <>
                                <Table
                                    rowKey="username"
                                    columns={user_columns}
                                    dataSource={usersData}
                                    pagination={pagination}
                                    hideExpandedColumn={false}
                                    expandedRowRender={expandRowRender}
                                />
                            </>


                        </TabPane>
                    </Tabs>

                </div> : <Loading prompt={"数据加载中"} />
            }
        </>
    );
}
