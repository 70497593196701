import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import { Space, Button, Row, Switch } from '@douyinfe/semi-ui';
import axios from 'axios';
import { API_BASE_URL } from 'config';
import { getCookie } from 'utils/cookieHelper';
import CardDeckListDetail from 'components/Tcg/CardDeck/CardDeckListDetail';
import { CheckboxEvent } from '@douyinfe/semi-ui/lib/es/checkbox';
import { relPath } from 'utils/navigateHelpers';
import Title from 'utils/title';

interface Props {
    defaultActionCardsVisible?: boolean
    selectedCardDecks?: Set<string>
    setSelectedCardDecks?: (_: Set<string>) => void;
    useModal?: boolean;
    // If set, return to this URL after card deck submission
    returnTo?: string;
}

export default function TcgMyCardDecksPageLi({
    defaultActionCardsVisible = true,
    selectedCardDecks,
    setSelectedCardDecks=()=>{},
    returnTo = undefined,
}: Props) {
    const [cards, setCards] = useState<any>(null);
    const [myCardDecks, setMyCardDecks] = useState<any>(null);
    const token = getCookie("token");
    const [checked, setChecked] = useState(defaultActionCardsVisible);

    const onChange = (checked: boolean | ((_prevState: boolean) => boolean)) => {
        setChecked(checked);
    };

    const language = useParams()["language"];

    const getCardsApiCall = () => {
        axios.get(`${API_BASE_URL}/tcg/card_list?lang=${language}`, {

        }).then(
            response => {
                setCards(response.data.data);
            },
            error => {
                console.log("[DEBUG] TODO", error);
            }
        )
    }


    const getMyCardDecksApiCall = () => {
        axios.get(`${API_BASE_URL}/tcg/card_deck`, {
            headers: {
                'Authorization': token
            }
        }).then(
            response => {
                setMyCardDecks(response.data.data);
            },
            error => {
                console.log("[DEBUG] TODO", error);
            }
        )
    }


    useEffect(() => {
        if (token) {
            getMyCardDecksApiCall();
        }
        getCardsApiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSelect = function (id: any, event: CheckboxEvent) {
        let newSet = new Set(selectedCardDecks);
        if (event.target.checked) {
            newSet.add(id);
        } else {
            newSet.delete(id);
        }
        setSelectedCardDecks(newSet);
    }

    let params = "";
    if (returnTo !== undefined) {
        params = `?return_to=${encodeURIComponent(returnTo)}`;
    }

    return (
        <div>
            <div style={{ marginTop: '80px' }} />



            {token === undefined &&
                <p style={{ marginLeft: '80px', marginTop: '60px' }}>若想访问"我的卡组"页面，请先登录</p>
            }

            {token !== undefined &&
                <div key="editTab">
                    <div>
                        <Row type="flex"
                            justify="space-between">
                                <h1>
                                    <Title title="我的卡组" />
                                    <a href={relPath(`/tcg-card-decks/new/edit${params}`)} style={{padding: '30px'}}>
                                        <Button theme='solid'>新建卡组
                                        </Button>
                                    </a>
                                </h1>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span className='mx-3'>
                                    {checked ? '显示行动卡' : '隐藏行动卡'}
                                </span>
                                <Switch checked={checked} onChange={onChange}></Switch>
                            </div>
                        </Row>

                        <Space />
                        {
                            cards && myCardDecks && myCardDecks.map((cardDeck: any) => {
                                return (
                                    <CardDeckListDetail
                                        key={cardDeck._id}
                                        id={cardDeck._id}
                                        cardDeck={cardDeck}
                                        cards={cards}
                                        createEditMode={false}
                                        actionCardVisible={checked}
                                        showCheckbox={selectedCardDecks !== undefined}
                                        selected={
                                            selectedCardDecks !== undefined
                                            && selectedCardDecks.has(cardDeck._id)}
                                        onSelect={(e) => {onSelect(cardDeck._id, e)}}
                                        returnTo={returnTo}/>
                                )
                            })
                        }
                    </div>
                </div>

            }
        </div>
    );
}
