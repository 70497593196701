import { Toast } from "@douyinfe/semi-ui";
import bilibili_api from "api/auth/bilibili_api";

// Generates a state and call "onLogin" with the final login URL
function login(callbackURL: string, returnURL: string, onLogin: (url: string)=>void) {
    bilibili_api.generateCallbackState(
        {return_url: returnURL},
        (response) => {
            let url = "https://passport.bilibili.com/register/pc_oauth2.html#/?";
            url += "response_type=code&";
            url += "client_id=" + encodeURIComponent(response.client_id) + "&";
            url += "state=" + encodeURIComponent(response.callback_state) + "&";
            url += "return_url=" + encodeURIComponent(callbackURL);
            onLogin(url);
        },
        (_error) => {
            Toast.error("跳转失败，请重试。");
        }
    )
}

const bilibiliUtils = {
    login,
}

export default bilibiliUtils;
