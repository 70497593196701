import api, {BilibiliRequest, BilibiliResponse, OnErrorFn, OnResponseFn} from 'api/api'

export interface GenerateCallbackStateRequest {
    return_url: string,
}

export interface GenerateCallbackStateResponse {
    callback_state: string,
    client_id: string,
}

function generateCallbackState(
    request: GenerateCallbackStateRequest,
    onResponse: OnResponseFn<GenerateCallbackStateResponse>,
    onError: OnErrorFn
) {
    api.post('/auth/bilibili/generate_callback_state',
        {data: request, onResponse: onResponse, onError: onError})
}

export interface DecodeCallbackStateRequest {
    callback_state: string,
}

export interface DecodeCallbackStateResponse {
    return_url: string,
}

function decodeCallbackState(
    request: DecodeCallbackStateRequest,
    onResponse: OnResponseFn<DecodeCallbackStateResponse>,
    onError: OnErrorFn
) {
    api.post('/auth/bilibili/decode_callback_state',
        {data: request, onResponse: onResponse, onError: onError})
}

export interface GenerateCookieStateRequest extends BilibiliRequest {
    bilibili_code: string,
}

function generateCookieState(
    request: GenerateCookieStateRequest,
    onResponse: OnResponseFn<BilibiliResponse>,
    onError: OnErrorFn
) {
    api.postBilibili('/auth/bilibili/generate_cookie_state',
        {data: request, onResponse: onResponse, onError: onError})
}

function LinkAccount(
    onResponse: OnResponseFn<BilibiliResponse>,
    onError: OnErrorFn
) {
    api.postBilibili('/auth/bilibili/link_account',
        {data: {}, onResponse: onResponse, onError: onError})
}

function UnlinkAccount(
    onResponse: OnResponseFn<void>,
    onError: OnErrorFn
) {
    api.post('/auth/bilibili/unlink_account',
        {onResponse: onResponse, onError: onError})
}

const bilibili_api =  {
    generateCallbackState,
    decodeCallbackState,
    generateCookieState,
    LinkAccount,
    UnlinkAccount,
};

export default bilibili_api;
