/**************************************** 主要 ****************************************/
export const DEFAULT_LANDING_PAGE_RROUTE = "/tcg-cards";

export const COLOR_ELEMENT_BACKGROUND_THUNDER = "TODO";



/**************************************** 登录信息validation ****************************************/
export const PASSWORD_LENGTH_MIN = 8;
export const PASSWORD_LENGTH_MAX = 20;
export const USERNAME_LENGTH_MIN = 2;
export const USERNAME_LENGTH_MAX = 20;



/**************************************** TCG ****************************************/
export const TCG_CARD_PREVIEW_VERTICAL_HEIGHT_PX = 206;
export const TCG_CARD_PREVIEW_VERTICAL_WIDTH_PX = 120;

export const TCG_CARD_LISTING_ROW_SIZE = 8;
export const TCG_CARD_DECK_ROW_SIZE = 3;

export const TCG_CARD_DECK_PAGE_SIZE = 30;

/**************************************** Color ****************************************/
// Element
export const COLOR_ELEMENT_ICE = "#99FFFFFF"
export const COLOR_ELEMENT_THUNDER = "#FFACFFFF"
export const COLOR_ELEMENT_WATER = "#80C0FFFF"
export const COLOR_ELEMENT_WIND = "#80FFD7FF"
export const COLOR_ELEMENT_FIRE = "#FF9999FF"
export const COLOR_ELEMENT_ROCK = "#FFE699FF"
export const COLOR_ELEMENT_GRASS = "#7EC236FF"






// 图片URL  /// TODO
export const ICON_IMG_URL_ELEMENT_THUNDER = "https://www.tqlib.com/img/tcg/thunder.png";

export const ICON_IMG_URL_TCG_COIN = "https://www.tqlib.com/img/tcg/coin.png";

/**************************************** ImageAsset ****************************************/
// asset url
const TCG_ASSET_URL = "https://www.tqlib.com/img/tcg/asset/";
const ABYSS_ASSET_URL = "https://www.tqlib.com/img/abyss/asset/"

// 管理asset入口
export const TQLImage = {
    tcg: {
        card_bg: TCG_ASSET_URL + "main-card-bg.png",
        white_hex: TCG_ASSET_URL + "white-hex.png",
        black_hex: TCG_ASSET_URL + "black-hex.png",
        grass_hex: TCG_ASSET_URL + "green-hex.png",
        wind_hex: TCG_ASSET_URL + "cyan-hex.png",
        rock_hex: TCG_ASSET_URL + "yellow-hex.png",
        fire_hex: TCG_ASSET_URL + "red-hex.png",
        water_hex: TCG_ASSET_URL + "blue-hex.png",
        ice_hex: TCG_ASSET_URL + "icy-hex.png",
        thunder_hex: TCG_ASSET_URL + "purple-hex.png",
        energy_bg: TCG_ASSET_URL + "energy-bg.png"
    },
    abyss: {
        cover: ABYSS_ASSET_URL + "abyss_cover.png",
        rarity_4: ABYSS_ASSET_URL + "rarity4_bg.png",
        rarity_5: ABYSS_ASSET_URL + "rarity5_bg.png",
        traveler_f: ABYSS_ASSET_URL + "traveler_f.png"
    }
}
