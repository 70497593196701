import './FilterButton.css'

interface Props {
    key?: string,
    children: any;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    selected?: boolean;
}

const checkMark = (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456725 3.7039C0.0025997 4.80026 -0.11622 6.00665 0.115291 7.17054C0.346802 8.33443 0.918247 9.40352 1.75736 10.2426C2.59648 11.0818 3.66557 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0891 10.3295 10.3201 10.9888 9.33342C11.6481 8.34672 12 7.18669 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0ZM5.14286 8.39571L3 6.25286L3.68143 5.57143L5.14286 7.03286L8.31857 3.85714L9.00257 4.53686L5.14286 8.39571Z" fill="currentColor"/>
    </svg>);

const FilterButton: React.FC<Props> = ({
    key = undefined,
    children = undefined,
    onClick = ()=>{},
    disabled = false,
    selected = false,
}) => {
    return (
        <button
                key={key}
                className={
                    "btn tql-filter-btn m-2"
                    + (selected? " selected" : "")
                    + (disabled? " disabled" : "")}
                disabled={disabled}
                onClick={onClick}>
            {children}
            <span className='tql-checkmark'>
                {checkMark}
            </span>
        </button>);
}

export default FilterButton;