import { useLocation } from "react-router-dom";
import CardDeckInfo from 'components/Tcg/CardDeckInfo/CardDeckInfo';


export default function TcgCardDeckDetailPage() {
    const location = useLocation();
    const pathSpilited = location.pathname.split("/");

    return (
        <div>
            <div style={{ marginTop: '80px' }} />


            <CardDeckInfo
                cardDeckId={pathSpilited.slice(-1)}
            />



            <div style={{ marginTop: '80px' }} />

        </div>
    );
}
