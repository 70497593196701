import React, { useEffect, useState } from "react";
import {
    Modal,
    Button,
    Row,
    Col,
    Space,
} from "@douyinfe/semi-ui";
import axios from "axios";


import { API_BASE_URL } from "config";
import CardDeckCharacterAvatar from "components/Tcg/CardDeckCharacterAvatar/CardDeckCharacterAvatar";
import CardPreviewHorizontal from "components/Tcg/CardPreviewHorizontal/CardPreviewHorizontal";

interface Props {
    visible: boolean;
    loading: boolean;
    characterCardNames: string[];
    actionCardNames: string[];
    isFullScreen: boolean;
    onOK: () => void;
    onCancel: () => void;
}

const TcgCardDeckDetectionConfirmModal: React.FC<Props> = ({ visible, loading, characterCardNames, actionCardNames, isFullScreen, onOK, onCancel }) => {
    const [cards, setCards] = useState<any>(null);

    // TODO
    const language = "zh-CN";

    const getCardsApiCall = () => {
        axios.get(`${API_BASE_URL}/tcg/card_list?lang=${language}`, {}).then(
            (response) => {
                setCards(response.data.data);
            },
            (error) => {
                console.log("[DEBUG] TODO", error);
            }
        );
    };

    useEffect(() => {
        getCardsApiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <Modal
            title={"卡组预览"}
            closable={false}
            visible={visible}
            closeOnEsc={true}
            fullScreen={isFullScreen}
            preventScroll={true}
            size={"large"}
            footer={
                null
            }
        >

            <Row gutter={{ xs: 8, sm: 10, md: 10, lg: 12, xl: 14, xxl: 16 }}>
                {cards && characterCardNames &&
                    characterCardNames.map((name: any) => {
                        const characterCard = cards.find((card: any) => card.name === name);
                        return (
                            <Col xs={6} sm={6} md={3} lg={3} xl={2}>
                                <CardDeckCharacterAvatar card={characterCard} size={60} />
                            </Col>
                        );
                    })
                }
            </Row>
            <Space />
            <Row gutter={{ xs: 8, sm: 10, md: 10, lg: 12, xl: 14, xxl: 16 }}>

                {cards && actionCardNames &&
                    actionCardNames.map((name: any) => {
                        const actionCard = cards.find((card: any) => card.name === name);
                        return (
                            <Col xs={4} sm={3} md={2} lg={2} xl={2}>
                                <CardPreviewHorizontal card={actionCard} onCardClick={() => null} viewCost={false} />
                            </Col>
                        );
                    })
                }

            </Row>
            <Space />
            <Row gutter={{ xs: 8, sm: 10, md: 10, lg: 12, xl: 14, xxl: 16 }} type="flex" justify="end" align="top" style={{ paddingBottom: "20px" }}>
                <Space>
                    <Button theme='light' type='tertiary' onClick={onCancel} disabled={loading}>
                        识别有误
                    </Button>
                    <Button loading={loading} theme="solid" type="primary" onClick={onOK}>
                        确认提交
                    </Button>
                </Space>
            </Row>
        </Modal>
    );
}

export default TcgCardDeckDetectionConfirmModal;
