import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "config";
import CardListCardPreviewVertical from "../CardListCardPreviewVertical/CardListCardPreviewVertical";
import CardListFilterArea from "../CardListFilterArea/CardListFilterArea";
import CharacterCardModal from "../CardDetailModals/CharacterCardModal/CharacterCardModal";
import ActionCardModal from "../CardDetailModals/ActionCardModal/ActionCardModal";
import Loading from "components/Loading/Loading";

interface Props {
    onCardClickCallBack: (_card: any) => void; // 目前只有 创建卡组 页面需要
    onCardClickPreviewMode: boolean; // 目前 创建卡组 页面是 false，卡牌一览页面是 true
}

const CardList: React.FC<Props> = ({
    onCardClickCallBack,
    onCardClickPreviewMode,
}) => {
    const [allCards, setAllCards] = useState<any>(null);
    const [cards, setCards] = useState<any>(null);
    const [characterCard, setCharacterCard] = useState<string>("");
    const [nonCharacterCard, setNonCharacterCard] = useState<string>("");
    const [characterCardModalVisible, setCharacterCardModalVisible] =
        useState(false);
    const [actionCardModalVisible, setActionCardModalVisible] =
        useState(false);

    // TODO
    const language = "zh-CN";

    const getCardsApiCall = () => {
        axios.get(`${API_BASE_URL}/tcg/card_list?lang=${language}`, {}).then(
            (response) => {
                setAllCards(response.data.data);
                setCards(response.data.data);
            },
            (error) => {
                console.log("[DEBUG] TODO", error);
            }
        );
    };

    useEffect(() => {
        getCardsApiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCardClick = (card: any) => {
        onCardClickCallBack(card);
        if (card.card_type === "角色") {
            setCharacterCard(card);
            setCharacterCardModalVisible(true);
        } else {
            setNonCharacterCard(card);
            setActionCardModalVisible(true);
        }
    };

    var content;
    if (cards) {
        content = (
            <div className="row row-cols-3 row-cols-md-5 tql-row-cols-xl-10">
                {
                    cards.map((card: any) => {
                        return (
                            <div
                                    key={card._id}
                                    className='col'>
                                <CardListCardPreviewVertical
                                    card={card}
                                    onCardClick={() => onCardClick(card)}
                                />
                            </div>);
                    })
                }
            </div>);
        
    } else {
        content = <Loading prompt="加载卡牌信息中……" />
    }

    return (
        <div>
            {cards && (
                <CardListFilterArea cards={allCards} setFilteredCards={setCards} />
            )}

            {content}

            {onCardClickPreviewMode && cards && (
                <CharacterCardModal
                    visible={characterCardModalVisible}
                    card={characterCard}
                    onCancel={() => setCharacterCardModalVisible(false)}
                />
            )}
            {onCardClickPreviewMode && cards && (
                <ActionCardModal
                    visible={actionCardModalVisible}
                    card={nonCharacterCard}
                    onCancel={() => setActionCardModalVisible(false)}
                />
            )}
        </div>
    );
};

export default CardList;
