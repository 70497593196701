import { useEffect } from "react"
import Title from "utils/title";

export default function NotFoundPage() {
    useEffect(()=>{
        // Search Engine Optimization: avoid soft 404
        // https://developers.google.com/search/docs/crawling-indexing/javascript/javascript-seo-basics#avoid-soft-404s

        // Add 404 tag
        let original_tag = document.querySelector('meta[name="robots"]');
        if (original_tag != null) {
            original_tag.remove()
        }
        const metaRobots = document.createElement('meta');
        metaRobots.name = 'robots';
        metaRobots.content = 'noindex';
        document.head.appendChild(metaRobots);  
        
        return () => {
            // Restore orignal tag
            metaRobots.remove()
            if (original_tag != null) {
                document.head.appendChild(original_tag);
            }
        }
    }, [])
    return <div className="container" style={{marginTop: 100}}>
        <h1><Title title="Error 404" /></h1>
        <p>前面的页面，以后再来探索吧！</p>
    </div>
}
