
export function logo(size: number) {
    return (
        <svg width={265*size} height={111*size} viewBox="0 0 265 111" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M126.029 37.7086L70.5139 39.1043L69.5286 48.1043L126.029 46.6043C132.362 46.771 142.129 48.9042 130.529 62.1042L108.529 87.1042H107.014C103.014 83.1042 88.5139 75 64.5139 69L62.0137 79C71.0137 81.1667 91.9137 89.9 103.514 99.5C111.68 105.667 132.329 112.604 147.529 110.604C159.862 110.771 186.485 107.304 196.485 88.1042C199.685 88.9043 238.014 98.3333 243.514 101.5C245.347 102.5 249.114 105.2 249.514 108C250.68 104.167 255.314 95.7 264.514 92.5C257.714 92.5 253.514 80 251.014 76C248.214 85.2 241.362 88.1042 239.029 88.1042C239.029 88.1042 237.244 88.0001 235.078 88.0001C217.078 88.8001 204.911 82.6667 201.078 79.5001C218.678 42.3001 220.029 21.1042 218.529 15.1042C218.529 12.7042 212.529 10 209.529 10C201.929 9.6 188.514 8.83333 182.514 10L181.014 16L182.029 19.6042H203.029C202.362 28.4376 200.229 49.3042 195.029 62.1042C193.029 71.7042 191.847 69.1042 190.514 67.6042C180.514 56.0042 178.68 45.1042 179.014 41.1042C175.814 37.1042 170.195 43.271 169.029 45.1043C169.829 51.1043 180.179 69 185.512 78.5C178.712 99.7 157.012 101.167 147.012 99.9999L148.529 67.6042C170.129 66.4043 166.195 76.6042 161.029 81.6042C159.829 82.8042 161.195 84.4376 162.029 85.1042C169.229 81.5042 170.695 74.6042 170.529 71.6042C170.129 62.0042 161.362 53.9376 157.029 51.1042V45.1043C161.429 41.1044 166.862 40.1043 169.029 40.1042C170.629 38.9042 169.695 37.6042 169.029 37.1042C156.629 38.3042 149.529 13.6043 147.529 1.1043C145.529 -0.895697 143.695 0.27097 143.029 1.1043C139.829 24.7043 130.362 34.8753 126.029 37.7086Z" fill="#C58042" />
            <path d="M120.885 86.1006L135.385 75.1006C139.603 71.9006 140.142 76.434 139.885 79.1006L141.385 93.1006C142.585 98.7006 138.551 98.434 136.385 97.6006L120.885 92.1006C115.685 90.9006 118.718 87.6006 120.885 86.1006Z" fill="white" />
            <path d="M60.0093 46.5002H35.5093C32.0093 46.3336 23.0282 46.8045 23.0282 55.6045C22.6992 52.1502 12.6255 40.7171 9.14819 41.4642C9.1028 41.4767 9.05795 41.4887 9.01367 41.5002C9.05733 41.486 9.10218 41.474 9.14819 41.4642C13.0323 40.3938 20.9276 35.8259 22.5094 29.1045C23.676 33.2712 27.9095 38.8002 35.5095 38.0002H60.5137L60.0093 46.5002Z" fill="#C58042" />
            <path d="M49.0161 99.1042C71.0161 93.9043 76.8345 37.8333 78.0011 10H60.5139C60.1139 36 55.3619 57.9376 51.5285 66.1042C14.7285 56.5042 3.01378 74.5 0.0138872 85.5C-0.786113 105.9 33.1828 103.604 49.0161 99.1042Z" fill="#C58042" />
            <path d="M100.028 1H37.5283L36.0137 5.44059L37.5137 10H100.028L101.014 5.44059L100.028 1Z" fill="#C58042" />
            <path d="M22.6201 75.8752C31.312 72.6433 37.6429 74.2294 41.5432 75.8753C46.5578 83.0574 42.811 87.4901 39.8859 89.4353C33.8684 93.7445 26.3816 93.3071 21.3669 92.4093C13.0093 91.332 13.1489 84.4041 15.099 81.7108C18.1077 76.3242 18.8591 77.3715 22.6201 75.8752Z" fill="white" />
        </svg>
    );
}