import React, { useEffect, useState } from "react";
import { getCookie } from "utils/cookieHelper";


import {
    Col,
    Row,
} from "@douyinfe/semi-ui";
import axios from "axios";
import { API_BASE_URL } from "config";


import { useParams } from "react-router-dom";
import CardDeckCharacterAvatar from "components/Tcg/CardDeckCharacterAvatar/CardDeckCharacterAvatar";

interface Props {
    p1Name: string;
    p2Name: string;
    gameInfo: any;
}

const TcgGamePreview: React.FC<Props> = ({
    p1Name,
    p2Name,
    gameInfo,
}) => {
    const language = useParams()["language"];

    const [cards, setCards] = useState<any>(null);

    const [_p1Data, setP1Data] = useState<any>(null);
    const [_p2Data, setP2Data] = useState<any>(null);
    const [p1CharacterCardNames, setP1CharacterCardNames] = useState<any[]>();
    const [p2CharacterCardNames, setP2CharacterCardNames] = useState<any[]>();

    const isP1Win = gameInfo.winner && gameInfo.winner === p1Name



    const getCardsApiCall = () => {
        axios.get(`${API_BASE_URL}/tcg/card_list?lang=${language}`, {

        }).then(
            response => {
                setCards(response.data.data);
            },
            error => {
                console.log("[DEBUG] TODO", error);
            }
        )
    }

    const getGameCardInfoAPICall = (p1CardDeckId: string, p2CardDeckId: string) => {
        axios.get(`${API_BASE_URL}/tcg/card_deck/info?id=${p1CardDeckId}`, {
            headers: {
                'Authorization': getCookie("token")
            }
        }).then(
            (response) => {
                setP1Data(response.data.data);
                setP1CharacterCardNames(response.data.data.characters ? response.data.data.characters : []);
            },
            (error) => {
                console.log("[DEBUG] TODO", error);
            }
        );
        axios.get(`${API_BASE_URL}/tcg/card_deck/info?id=${p2CardDeckId}`, {
            headers: {
                'Authorization': getCookie("token")
            }
        }).then(
            (response) => {
                setP2Data(response.data.data);
                setP2CharacterCardNames(response.data.data.characters ? response.data.data.characters : []);
            },
            (error) => {
                console.log("[DEBUG] TODO", error);
            }
        );
    };


    useEffect(() => {
        getCardsApiCall();
        getGameCardInfoAPICall(gameInfo.player1Deck, gameInfo.player2Deck);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <div>

            <Row type="flex" justify="space-around" align="middle">

                {p1CharacterCardNames &&
                    p1CharacterCardNames.map((characterCardName: any) => {
                        const characterCard = cards.find((card: any) => card.name === characterCardName);
                        return (
                            <Col span={3} style={{ textAlign: "center" }}>
                                <CardDeckCharacterAvatar key={characterCardName} card={characterCard} size={40} />
                            </Col>
                        );
                    })
                }
                {/* Placeholder (角色不足) */}
                {p1CharacterCardNames && 3 - p1CharacterCardNames.length >= 0 && (
                    [...Array(3 - p1CharacterCardNames.length)].map((e) => {
                        return (
                            <Col span={3} style={{ textAlign: "center" }}>
                                <CardDeckCharacterAvatar key={e} card={null} size={40} />
                            </Col>
                        )
                    })
                )}

                {isP1Win ?
                    <Col span={3} style={{ textAlign: "center" }}>
                        {/* <IconCrown style={{ color: '#C58042' }} /> */}
                        <strong style={{ color: '#C58042' }}>胜利</strong>
                    </Col>
                    : <Col span={3} />}
                {gameInfo.winner ? null : <Col span={6} />}
                {isP1Win ? <Col span={3} /> :
                    <Col span={3} style={{ textAlign: "center" }}>
                        {/* <IconCrown style={{ color: '#C58042' }} /> */}
                        <strong style={{ color: '#C58042' }}>胜利</strong>
                    </Col>}

                {/* <Col span={6} /> */}
                {p2CharacterCardNames &&
                    p2CharacterCardNames.map((characterCardName: any) => {
                        const characterCard = cards.find((card: any) => card.name === characterCardName);
                        return (
                            <Col span={3} style={{ textAlign: "center" }}>
                                <CardDeckCharacterAvatar key={characterCardName} card={characterCard} size={40} />
                            </Col>

                        );
                    })
                }
                {/* Placeholder (角色不足) */}
                {p2CharacterCardNames && 3 - p2CharacterCardNames.length >= 0 && (
                    [...Array(3 - p2CharacterCardNames.length)].map((e) => {
                        return (
                            <Col span={3} style={{ textAlign: "center" }}>
                                <CardDeckCharacterAvatar key={e} card={null} size={40} />
                            </Col>
                        )
                    })
                )}

            </Row>


        </div>
    );
};

export default TcgGamePreview;