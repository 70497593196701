import React from "react";
import "./CardPreviewHorizontal.css";
import { TQLImage } from "projectConstants";
import CardCost from "../CardCost/CardCost";

interface Props {
    card: any;
    viewCost: boolean;
    onCardClick: (_card: any) => void
}

const CardPreviewHorizontal: React.FC<Props> = ({ card, viewCost, onCardClick }) => {

    return (
        <div onClick={() => onCardClick(card)}>
            {card && (
                <div style={{ position: "relative", width: "100%" }}>
                    <img src={card.img} style={{ width: "100%" }} alt={"card"}/>

                    <img
                        src={TQLImage.tcg.card_bg}
                        style={{
                            position: "absolute",
                            width: "100%",
                            left: "0",
                            top: "0",
                        }}
                        alt={"card-bg"}
                    />
                    
                    {viewCost &&
                    <span style={{position: 'absolute', left: '-10px', top: '-10px'}}>
                        <CardCost
                        card={card}
                        size_px={30}
                        horizental={false}/>
                    </span>}
                </div>
            )}

        </div>
    );
};

export default CardPreviewHorizontal;
