import React from "react";
import { Helmet } from "react-helmet";

interface Props {
    title: string,
}

const Title: React.FC<Props> = ({title}) => {
    return <>
    {title}
    <Helmet><title>{title} - TQLib</title></Helmet>
    </>;
}

export default Title;
