import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { isSignedIn } from "utils/cookieHelper";
import { relPath } from "utils/navigateHelpers";
import { Button, Form } from '@douyinfe/semi-ui';
import Loading from "components/Loading/Loading";
import auth_api from "api/auth_api";
import Title from "utils/title";


const Step2Page: React.FC<{}> = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const formRef = useRef<Form<any>>(null);

    const gotoPrevStep = () => {
        navigate(relPath('/contest/tcg/register_v2/step1'));
    };

    const gotoNextStep = () => {
        navigate(relPath('/contest/tcg/register_v2/step3'));
    };

    useEffect(() => {
        if (!isSignedIn()) {
            gotoPrevStep();
        }
        setLoading(true);
        auth_api.getProfile((response) => {
            formRef.current?.formApi.setValue("ys_uid", response.ys_uid);
            if (response.related_accounts != null) {
                response.related_accounts.forEach(element => {
                    if (element.platform === "QQ") {
                        try {
                            formRef.current?.formApi.setValue("qq",
                                element.username);
                        } catch (e) {
                            // Ignore validation error
                            console.log("Ignored error", e);
                        }
                    }
                });
            }

            setLoading(false);
        })
    }, [])

    const onFieldsChange = (_values: any) => {
        formRef.current?.formApi.validate().catch(()=>{});
    }

    const validateFields = (values: any) => {
        const errors: any = {};
        if (!values.ys_uid || values.ys_uid < 100000000 || values.ys_uid > 999999999) {
            errors.ys_uid = "请输入正确的原神uid";
        }
        if (!values.qq || values.qq < 10000 || values.qq > 10000000000000) {
            errors.qq = "请输入正确的QQ号";
        }
        return Object.keys(errors).length !== 0 ? errors : null;
    }

    const onSubmit = (values: any) => {
        setSubmitting(false);
        auth_api.setProfile({
            ys_uid: String(values.ys_uid),
            related_accounts: [{
                platform: "QQ",
                username: String(values.qq),
            }],
        },
            () => { // On response
                setSubmitting(false);
                gotoNextStep();
            },
            () => { // On error
                setSubmitting(false);
            });
    }

    return <div className="container pt-5">
        <Form
            labelPosition="left"
            labelWidth={80}
            layout='vertical'
            ref={formRef}
            onValueChange={onFieldsChange}
            validateFields={validateFields}
            onSubmit={onSubmit}
        >
            <h1><Title title="选手信息" /></h1>
            <p>请检查以下报名信息是否正确：</p>

            <Loading loading={loading} prompt="正在加载您过去填写的资料……" />
            <Form.InputNumber field='ys_uid' label='原神 UID'
                style={{ width: 200 }} hideButtons
                disabled={loading} />
            <Form.InputNumber field='qq' label='QQ号'
                style={{ width: 200 }} hideButtons
                disabled={loading} />

            <div style={{ marginTop: "30px", width: "100%" }}>
                <Button
                    htmlType='submit'
                    theme="solid"
                    loading={submitting}
                >
                    保存并继续
                </Button>
            </div>
        </Form>
    </div>
}

export default Step2Page;
