import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Modal,
    Typography,
    Card,
    Space,
    Checkbox,
} from "@douyinfe/semi-ui";
import CardPreviewHorizontal from "../CardPreviewHorizontal/CardPreviewHorizontal";
import CardDeckCharacterAvatar from "../CardDeckCharacterAvatar/CardDeckCharacterAvatar";
import CardDeckInfo from "../CardDeckInfo/CardDeckInfo";
import { CheckboxEvent } from "@douyinfe/semi-ui/lib/es/checkbox";
import { relPath } from "utils/navigateHelpers";

interface Props {
    cardDeck: any; // 当前卡组（卡组schema形式）
    cards: any; // 所有卡牌
    actionCardVisible: boolean;
    createEditMode: boolean; // 编辑&添加 卡组的页面为 true (根据 我的卡组页面状态判断)
    id: undefined|string;

    // If true, the card deck is selectable
    showCheckbox?: boolean;
    // If true, show the card deck as selected
    selected?: boolean;
    // Callback for when the card deck is selected/deselected
    onSelect?: (_: CheckboxEvent) => void,
    // If set, return to this URL after card deck submission
    returnTo?: string;
}

const CardDeckListDetail: React.FC<Props> = ({
    cardDeck,
    cards,
    actionCardVisible,
    createEditMode,
    id,
    showCheckbox = false,
    selected = false,
    onSelect = (_) => {},
    returnTo = undefined,
}) => {
    const { Text, Title } = Typography;

    const [characterCardNames, setCharacterCardNames] = useState<any[]>();
    const [actionCardNamesChunked, setActionCardNamesChunked] = useState<any[]>();


    const [cardDeckDetailModalVisible, setCardDeckDetailModalVisible] = useState(false);
    useEffect(() => {
        setCharacterCardNames(cardDeck.characters ? cardDeck.characters : []);
        setActionCardNamesChunked(cardDeck.actions ? cardDeck.actions : []);
    }, [cardDeck]);

    let title = <></>;
    if (showCheckbox) {
        title = <Checkbox checked={selected} onChange={onSelect}>
            {cardDeck.name}
        </Checkbox>;
    } else {
        title = <Title heading={5} style={{ margin: 8 }}>
            {cardDeck.name}
        </Title>;
    }

    let params = "";
    if (returnTo !== undefined) {
        params = `?return_to=${encodeURIComponent(returnTo)}`;
    }

    return (
        <>
            <Card
                key={cardDeck._id}
                title={title}
                headerLine={true}
                style={{
                    boxShadow: "3px 3px 10px #CCCCCC",
                }}
                className="my-3"
                headerExtraContent={
                    <div
                        style={{ display: "flex", flexDirection: "row" }}
                    >
                        <Space spacing='medium'>
                            {!createEditMode && (
                                <Title heading={6} style={{ margin: 8 }}
                                    link
                                    onClick={() => setCardDeckDetailModalVisible(true)}
                                >
                                    {"卡组详情"}
                                </Title>
                            )}
                            {!createEditMode && (
                                <Title heading={6} style={{ margin: 8 }}
                                    link={{href: relPath(`/tcg-card-decks/${id}/edit${params}`)}}
                                >
                                    {"编辑卡组"}
                                </Title>
                            )}
                        </Space>
                    </div>
                }
            >
                <Row style={{ marginBottom: 20 }}>
                    {characterCardNames &&
                        characterCardNames.map((characterCardName: any) => {
                            const characterCard = cards.find((card: any) => card.name === characterCardName);
                            return (
                                <Col xs={8} sm={6} md={4} lg={2} xl={2}>
                                    <CardDeckCharacterAvatar key={characterCardName} card={characterCard} size={75} />
                                </Col>
                            );
                        })
                    }
                    {/* Placeholder (角色不足) */}
                    {characterCardNames && 3 - characterCardNames.length >= 0 && (
                        [...Array(3 - characterCardNames.length)].map((e) => {
                            return (
                                <Col xs={8} sm={6} md={4} lg={2} xl={2}>
                                    <CardDeckCharacterAvatar key={e} card={null} size={75} />
                                </Col>)
                        })
                    )}
                </Row>
                {actionCardVisible && <Row gutter={{ xs: 6, sm: 8, md: 10, lg: 10 }} type="flex"
                    justify="start">

                    {cards && actionCardNamesChunked &&
                        actionCardNamesChunked.map((name: any) => {
                            const actionCard = cards.find((card: any) => card.name === name);
                            return (
                                <Col xs={4} sm={3} md={2}>
                                    <CardPreviewHorizontal key={name} card={actionCard} onCardClick={() => null} viewCost={false} />
                                </Col>
                            );
                        })
                    }

                </Row>}
                <Row style={{ marginTop: 12 }}>
                    <Text>卡组作者: {cardDeck.username}</Text>
                </Row>
            </Card>

            {/* 卡组详情 Modal */}
            <Modal
                title="卡组数据"
                footer={null}
                visible={cardDeckDetailModalVisible}
                closeOnEsc={true}
                onCancel={() => setCardDeckDetailModalVisible(false)}
                size={"large"}
            >
                <CardDeckInfo
                    cardDeckId={cardDeck._id}
                />
            </Modal>

        </>
    );
};

export default CardDeckListDetail;