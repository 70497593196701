export enum BackendEnvT {
    LOCAL,
    PROD,
    DEV,
};

export const BACKEND_ENV: BackendEnvT = BackendEnvT.DEV;

const apiBaseURL = {
    [BackendEnvT.LOCAL]: "http://localhost:7050/api",
    [BackendEnvT.PROD]: "https://www.tqlib.com/api",
    [BackendEnvT.DEV]: "https://dev.tqlib.com/api",
}
export const API_BASE_URL = apiBaseURL[BACKEND_ENV];

const baseURL = {
    [BackendEnvT.LOCAL]: "https://www.tqlib.com/",
    [BackendEnvT.PROD]: "https://www.tqlib.com/",
    [BackendEnvT.DEV]: "https://www.tqlib.com/",
}
export const BASE_URL = baseURL[BACKEND_ENV];
