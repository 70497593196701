import React from 'react';
import { Nav } from '@douyinfe/semi-ui';
import { IconStar, IconUser, IconUserGroup, IconSetting } from '@douyinfe/semi-icons';
import characterList from './tempjson/characterListShorten.json';


const CharacterNav: React.FC = () => {
    const navBarItems = jsonToElementCharacterList();


    return (
        <div>
            <Nav
                    style={{ position: 'absolute', top: '30px', left: '0px', border: 'none' }}
                    bodyStyle={{ height: "500px" }}
                    items={[
                        { itemKey: 'all', text: 'All', icon: <IconUser /> },
                        {
                            text: 'Pyro',
                            icon: <IconSetting />,
                            itemKey: 'pyro',
                            items: navBarItems.get('Pyro'),
                        },
                        {
                            text: 'Hydro',
                            icon: <IconSetting />,
                            itemKey: 'hydro',
                            items: ['珊瑚宫心海', '芭芭拉', '妮露'],
                        },
                        {
                            itemKey: 'anemo',
                            text: 'Anemo',
                            icon: <IconUser />
                        },
                        {
                            itemKey: 'union-management',
                            text: 'Electro',
                            icon: <IconUserGroup />,
                            items: ['雷电将军', '雷泽', '赛诺']
                        },
                        {
                            text: 'Dendro',
                            icon: <IconSetting />,
                            itemKey: 'dendro',
                            items: ['科莱', '纳西妲', '赛诺'],
                        },
                        {
                            text: 'Geo',
                            icon: <IconSetting />,
                            itemKey: 'geo',
                            items: ['凝光', '钟离'],
                        },
                        {
                            itemKey: 'cryo',
                            text: 'Cryo',
                            icon: <IconStar />,
                        },
                    ]}
                    onSelect={key => console.log(key)}
                    header={{}}
                    footer={{
                        collapseButton: true,
                    }}
                />
        </div>
    );
}

// TODO
function jsonToElementCharacterList() {
    // const elementArray = new Array('Pyro', 'Hydro', 'Anemo', 'Electro', 'Dendro', 'Cryo', 'Geo')

    // const elementArray2 = {
    //     'Pyro': [] as string[], 
    //     'Hydro':[] as string[], 
    //     'Anemo':[] as string[], 
    //     'Electro':[] as string[], 
    //     'Dendro':[] as string[], 
    //     'Cryo':[] as string[], 
    //     'Geo':[] as string[]};
    // const map = new Map(Object.entries(elementArray2));

    type CharacterShort = {
        name: string;
        element: string;
        rarity: number;
        avatar: string;
    };

    const map = new Map();
    map.set('Pyro', [] as string[])
    for (const ch in characterList) {
        let characterObject: CharacterShort = JSON.parse(ch);
        const element = characterObject.element as string;
        const name = characterObject.name as string;
        const curElementCharacters = map.get(element) || [];
        curElementCharacters.push(name);
        map.set(element, curElementCharacters);


        // map.get('Pyro')?.push(typeof characterObject.element)
        // console.log(characterObject.element)
        // if (curElementCharacters != undefined) {
        //     curElementCharacters.push(characterObject.name);
        //     map.set(characterObject.element, curElementCharacters);
        // } else {
        //     map.set(characterObject.element, [characterObject.name]);
        // }


        map.get('Pyro').push(characterObject.name + ch);
    }
    return map;
}



export default CharacterNav;
