import React from "react";

interface Props {
    card: any;
    size?: number;
}

const CardDeckCharacterAvatar: React.FC<Props> = ({ card, size = 75 }) => {
    return (
        card ? (
            <span
                style={{
                    height: size,
                    width: size,
                    borderRadius: "50%",
                    backgroundSize: "120%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center top",
                    backgroundImage: "url(" + card.img + ")",
                    // ...(color ? {} : { filter: 'grayscale(100%)' }),
                    border: "3px solid #bc995c",
                    display: "inline-block",
                }}
            />
        ) : (
            <span
                style={{
                    height: 75,
                    width: 75,
                    borderRadius: "50%",
                    backgroundSize: "120%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center top",
                    backgroundColor: "#CCCCCC",
                    border: "3px solid #bc995c",
                    display: "inline-block",
                }}
            />
        )
    );
};

export default CardDeckCharacterAvatar;
