import React from "react";
import './AbyssCharacter.css'
import { TQLImage } from "projectConstants";
import { Badge } from "@douyinfe/semi-ui";

interface Props {
    character: any,
    onClick?: () => void,
    showBadge?: boolean
}

const AbyssCharacter: React.FC<Props> = ({ character, onClick, showBadge }) => {
    //console.log(build)


    return (
        <div className="character_container"
            onClick={onClick}>
            <Badge count={showBadge ? character?.number : null}>
                <div
                    className="character_bg"
                    style={{ backgroundImage: `url(${character.rarity === 5 ? TQLImage.abyss.rarity_5 : TQLImage.abyss.rarity_4})` }}
                >
                    <div
                        className="avatar"
                        style={{ backgroundImage: `url(${character.avatar})` }}
                    // style={{ backgroundImage: `url(${TQLImage.abyss.traveler_f})` }}
                    ></div>
                </div>
            </Badge>
            <p style={{ color: '#C58042' }}>{character.name}</p>
        </div>
    )
};

export default AbyssCharacter;