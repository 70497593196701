import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { Toast } from '@douyinfe/semi-ui';
import { getErrorMessage } from 'utils/errorMessageHandler';
import { API_BASE_URL } from 'config';
import {getBilibiliCookie, getCookie, setBilibiliCookie} from 'utils/cookieHelper'

// Display error messages for this duration
const ERROR_MESSAGE_DISPLAY_SECOND = 10;

// A function that takes response data to process.
export type OnResponseFn<ResponseT> = (_response: ResponseT) => void;
// A function that takes error message to process.
export type OnErrorFn = (_error: AxiosError) => void;
export type SetLoadingFn = (_newState: boolean) => void;

interface GetRequestOptions<ResponseT, DataT> {
    config?: AxiosRequestConfig<DataT>,
    onResponse?: OnResponseFn<ResponseT>,
    onError?: OnErrorFn,
    setLoading?: SetLoadingFn,
};

// Sends a GET request to API.
export function get<ResponseT, DataT>(
        path: string,
        {config, onResponse, onError, setLoading}: GetRequestOptions<ResponseT, DataT>) {
    if (setLoading) {
        setLoading(true);
    }
    axios.get(getFullURL(path), maybeAttachAuthorization(config))
        .then(response => {
            if (onResponse) {
                onResponse(response.data as ResponseT);
            }
            if (setLoading) {
                setLoading(false)
            }
        })
        .catch(error =>{
            Toast.error({
                content: getErrorMessage(error),
                duration: ERROR_MESSAGE_DISPLAY_SECOND });
            if (onError) {
                onError(error);
            }
            if (setLoading) {
                setLoading(false)
            }
        });
}

interface PostRequestOptions<ResponseT, DataT> {
    data?: DataT,
    config?: AxiosRequestConfig<DataT>,
    onResponse?: OnResponseFn<ResponseT>,
    onError?: OnErrorFn,
    setLoading?: (_newState: boolean) => void,
};

// Sends a POST request to API.
export function post<ResponseT, DataT>(
        path: string,
        {data, config, onResponse, onError, setLoading}: 
            PostRequestOptions<ResponseT, DataT>) {
    if (setLoading) {
        setLoading(true);
    }
    axios.post(getFullURL(path), data, maybeAttachAuthorization(config))
        .then(response => {
            if (onResponse) {
                onResponse(response.data as ResponseT);
            }
            if (setLoading) {
                setLoading(false)
            }
        })
        .catch(error =>{
            Toast.error({
                content: getErrorMessage(error),
                duration: ERROR_MESSAGE_DISPLAY_SECOND });
            if (onError) {
                onError(error);
            }
            if (setLoading) {
                setLoading(false)
            }
        });
}

export interface BilibiliRequest {
    bilibili_cookie?: string,
}

export interface BilibiliResponse {
    bilibili_cookie: string,
}

// Sends a POST request to API with Bilibili credentials.
export function postBilibili<ResponseT extends BilibiliResponse, DataT extends BilibiliRequest>(
    path: string,
    options: 
        PostRequestOptions<ResponseT, DataT>) {
    let originalFn = options.onResponse;
    options.onResponse = (response) => {
        setBilibiliCookie(response.bilibili_cookie);
        if (originalFn)
            originalFn(response);
    }
    if (options.data)
        options.data.bilibili_cookie = getBilibiliCookie();
    post(path, options);
}

// Returns the full API URL for an API path.
function getFullURL(path: string) {
    if (path.startsWith('./')) {
        // Relative path
        return '/' + path.substring('./'.length)
    } else if (path[0] === '/') {
        // Absolute path
        return API_BASE_URL + path;
    } else {
        throw new Error(`API path must begin with "/" or "./".`);
    }
}

// Attach JWT token if the user is logged in.
function maybeAttachAuthorization<DataT>(
        config?: AxiosRequestConfig<DataT>): AxiosRequestConfig<DataT> {
    let headers = {};
    let token = getCookie("token");
    // console.log("token", token);
    if (token !== undefined) {
        headers = {'Authorization': token}
    }
    if (config === undefined) {
        return {headers: headers};
    } else {
        config.headers = {...config.headers, ...headers}
        return config;
    }
}

const api = {
    get, 
    post,
    postBilibili,
};

export default api;
