import api, {BilibiliRequest, BilibiliResponse, OnErrorFn, OnResponseFn} from 'api/api'

export interface ListVideosRequest extends BilibiliRequest {
    page_number: number,
    page_size: number,
    status: VideoStatus,
};

export enum VideoStatus {
    all = "all",
    is_pubing = "is_pubing",
    pubed = "pubed",
    not_pubed = "not_pubed",
};

export interface ListVideosResponse extends BilibiliResponse {
    total_num_videos: number,
    videos: VideoInfo[],
};

export interface VideoInfo {
    resource_id: string,
    title: string,
    cover_url: string,
    duration_seconds: number,
};

function listVideos(
    request: ListVideosRequest,
    onResponse: OnResponseFn<ListVideosResponse>,
    onError: OnErrorFn
) {
    api.postBilibili('/bilibili/list_videos',
        {data: request, onResponse: onResponse, onError: onError})
}

const bilibili_api =  {
    listVideos,
};

export default bilibili_api;
