import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie, isSignedIn } from "utils/cookieHelper";
import { relPath } from "utils/navigateHelpers";
import { ArrayField, Button, Card, Form, Row, TabPane, Tabs, Toast, ToastFactory, Tooltip, Typography } from '@douyinfe/semi-ui';
import { IconMinusCircle, IconPlusCircle } from '@douyinfe/semi-icons';
import { TQLImage } from "projectConstants";
import abyss_api, { AbyssTheme } from "api/contest/abyss_api";


const CreateExternalAbyssContestPage: React.FC<{}> = () => {
    const navigate = useNavigate();
    const { Meta } = Card;
    const { Text, Title } = Typography;
    const [loading, setLoading] = useState(false);
    const formRef = useRef<Form<any>>(null);
    const trackFormRef = useRef<Form<any>>(null);
    const [formValues, setFormValues] = useState<any>({});
    const [tabActiveKey, setTabActiveKey] = useState<string>("1");
    const [bannerImg, setBannerImg] = useState<string>("");

    const token = getCookie("token");
    const username = getCookie("username");

    const gotoNextStep = () => {
        navigate(relPath('/contest/tcg/create/step2'));
    };

    const timezone_diff = new Date(1970, 0, 1).getTime()


    const toStep2 = (values: any) => {
        console.log(values)
        if (validateBasicFormFields(values) === null) {
            setFormValues(values);
            setTabActiveKey("2");
        }
    };

    const onSubmit = (values: any) => {
        // if (validateBasicFormFields(values) == null) {
        //     // setFormValues(values);
        //     console.log(values)
        //     Toast.info({ content: JSON.stringify(values) })

        // } else {
        //     Toast.info({ content: JSON.stringify(values) + "|" + JSON.stringify(formValues) })
        // }
        let theme: AbyssTheme = {
            type: values?.type,
            content: values?.content,
        }
        abyss_api.create_contest({
            name: formValues.contest_name,
            hosts: formValues.hosts,
            version: formValues.version,
            start_time: ((new Date(formValues.date[0])).getTime() - timezone_diff) / 1000,
            end_time: ((new Date(formValues.date[1])).getTime() - timezone_diff) / 1000,
            rule: formValues.description,
            tracks: values?.tracks,
            theme: theme,
        },
            () => {
                // console.log(response);
                Toast.success('赛事信息已成功提交！');
            },
            (error) => {
                Toast.error('请联系管理员！');
            });
    };

    const validateBasicFormFields = (values: any) => {
        const errors: any = {};
        if (!values.contest_name || values.contest_name.length < 2 || values.contest_name.length > 20) {
            errors.contest_name = "赛事名称长度需介于3-20个字符之间";
        }
        if (!values.hosts) {
            errors.hosts = "请输入主办方信息";
        }
        if (!values.description) {
            errors.description = "请输入比赛规则";
        }
        if (!values.date) {
            errors.date = "请输入比赛起止时间";
        }
        // if (avatar_url == "") {
        //     errors.avatar = "请上传头像";
        //     ToastFactory.create({
        //         left: "30%",
        //     }).error('请上传头像');
        // }
        return Object.keys(errors).length !== 0 ? errors : null;
    }

    if (!isSignedIn()) {
        return <div className='container py-3'>
            <p>请先登录，以便创建比赛……</p>
        </div>
    } else {
        return <div
            // className="container py-3"
            style={{
                position: 'relative',
                paddingTop: '30px',
                justifyContent: 'center',
                alignContent: 'center',
                //height: height,
                width: "100%",
                height: '100%',
                display: 'inline-block',
                padding: 20,
                backgroundColor: 'var(--semi-color-fill-0)'
            }}>
            {/* <div className="cover">
            
        </div> */}
            {/* <h1>创建比赛</h1> */}

            {/* <Form
            ref={formRef}
            onSubmit={values => Toast.info({ content: JSON.stringify(values) })}
        >
            {
                ({ formState, values, formApi }) => (
                    <Row style={{ width: 800, margin: 'auto' }}>
                        <Col span={12}>
                            <Form.Input
                                style={{ width: 200 }}
                                field='contest_name'
                                label={{
                                    text: '赛事名',
                                    extra: <Tooltip content='不可重名，请用版本区分'><IconHelpCircle style={{ color: 'var(--semi-color-text-2)' }} /></Tooltip>
                                }}
                                placeholder='给我名字'
                            />
                            <Form.TagInput
                                style={{ width: 200 }}
                                field="hosts"
                                label='主办方'
                                initValue={['小李', '嘉然']}
                                placeholder='请输入主办方'
                            />
                            <Form.Upload
                                field='banner'
                                label='Banner'
                                action='//semi.design/api/upload'
                            >
                                <Button icon={<IconUpload />} theme="light">
                                    点击上传
                                </Button>
                            </Form.Upload>
                        </Col>
                        <Col span={11} offset={1} style={{ marginTop: 12 }}>
                            <Form.Label text='FormState实时映射值：'></Form.Label>
                            <TextArea value={JSON.stringify(formState.values)}></TextArea>

                            <Form.TextArea
                                style={{ height: 120 }}
                                field='description'
                                label='赛事规则'
                                placeholder='请填写赛事规则'
                            />
                        </Col>
                    </Row>
                )
            }
        </Form> */}


            {/* <Form
            ref={formRef}
            labelPosition="left"
            labelAlign="left"
            labelWidth={200}
            onSubmit={values => Toast.info({ content: JSON.stringify(values) })}
        >

            <Form.Input
                style={{ width: 200 }}
                field='contest_name'
                label={{
                    text: '赛事名',
                    extra: <Tooltip content='不可重名，请用版本区分'><IconHelpCircle style={{ color: 'var(--semi-color-text-2)' }} /></Tooltip>
                }}
                placeholder='给我名字'
            />
            <Form.TagInput
                style={{ width: 200 }}
                field="hosts"
                label='主办方'
                initValue={['小李', '嘉然']}
                placeholder='请输入主办方'
            />
            <Form.Upload
                field='bannerImg'
                label='Banner'
                action='//semi.design/api/upload'
            >
                <Button icon={<IconUpload />} theme="light">
                    点击上传
                </Button>
            </Form.Upload>


            <Form.TextArea
                style={{ height: 120, width: 600 }}
                field='description'
                label='赛事规则'
                placeholder='请填写赛事规则'
            />


        </Form>

        <Row>
            <Col span={2}>
                <div style={{ marginTop: "30px", width: "100%" }}>
                    <Button
                        htmlType='submit'
                        theme="solid"
                    // style={{ float: "right" }}
                    >
                        下一步
                    </Button>
                </div>
            </Col>
        </Row> */}
            {/* <div style={{ marginTop: "30px", width: "100%" }}>
            <Button
                htmlType='submit'
                theme="solid"
                style={{ float: "right" }}
            >
                下一步
            </Button>
        </div> */}

            <Card
                style={{ maxWidth: 700, margin: "auto", top: "50%" }}
                bordered={false}
                headerLine={false}
                shadows="always"
                cover={
                    <img style={{ height: "150px", objectFit: "cover", objectPosition: "center center" }} src={TQLImage.abyss.cover} />
                }
                bodyStyle={{ margin: '2px' }}

            >
                <Title heading={2} style={{ color: '#C58042' }}>提交比赛</Title>

                <Tabs
                    type="line"
                    activeKey={tabActiveKey} onTabClick={value => setTabActiveKey(value)}
                >
                    <TabPane tab="基础信息" itemKey="1">
                        <Form
                            ref={formRef}
                            labelPosition="left"
                            labelAlign="left"
                            labelWidth={100}
                            validateFields={validateBasicFormFields}
                            onSubmit={toStep2}
                        // onSubmit={values => Toast.info({ content: JSON.stringify(values) })}
                        >
                            <Form.Input
                                style={{ width: 200 }}
                                field='contest_name'
                                label={{
                                    text: '赛事名',
                                    // extra: <Tooltip content='不可重名，请用版本区分'><IconHelpCircle style={{ color: 'var(--semi-color-text-2)' }} /></Tooltip>
                                }}
                                placeholder='请输入赛事名称'
                            />
                            <Form.TagInput
                                style={{ width: 200 }}
                                field="hosts"
                                label='主办方'
                                initValue={[username]}
                                placeholder='请输入主办方'
                            />
                            <Form.InputNumber
                                field="version"
                                label='版本'
                                precision={1}
                                initValue={3.6}
                                step={0.1} />

                            <Form.DatePicker
                                field="date"
                                label="起止时间"
                                type="dateRange"
                                density="compact"
                                style={{ width: 260 }} />

                            {/* <Form.Upload
                        field='bannerImg'
                        label='Banner'
                        action='//semi.design/api/upload'
                    >
                        <Button icon={<IconUpload />} theme="light">
                            点击上传
                        </Button>
                    </Form.Upload> */}

                            <Form.Input
                                style={{ width: 550 }}
                                field='description'
                                label='赛事官网链接'
                                placeholder='请输入URL'
                            />

                            <div style={{ marginTop: "20px", width: "100%", textAlign: 'right' }}>
                                <Button
                                    htmlType='submit'
                                    theme="solid"
                                // style={{ textAlign: 'center' }}
                                >
                                    提交
                                </Button>
                            </div>
                        </Form>
                    </TabPane>
                    

                </Tabs>
            </Card>
        </div >
    }
}

export default CreateExternalAbyssContestPage;