import Cookies from 'universal-cookie';
import {SignUpResponse} from 'api/auth_api';


const setCookies = (cookiesToSet: any) => {
    const cookies = new Cookies();
    Object.keys(cookiesToSet).forEach(function(key) {
        cookies.set(key, cookiesToSet[key], { path: '/', maxAge: 60 * 60 * 24 * 30 });
    })
}

export const setSignInCookies = (response: SignUpResponse) => {
    let cookies = {
        token: response.token,
        username: response.username,
    }
    setCookies(cookies);
}

export const setBilibiliCookie = (cookie: string) => {
    setCookies({bilibili_state: cookie});
}

export const getBilibiliCookie: ()=>string = () => {
    const cookies = new Cookies();
    const result = cookies.get("bilibili_state");
    if (result === "")
        return;
    return result;
}

export const getCookie = (key: string) => {
    const cookies = new Cookies();
    return cookies.get(key);
}

export function isSignedIn() {
    const cookies = new Cookies();
    return cookies.get("token") !== undefined;
}

export function isBilibiliSignedIn() {
    return getBilibiliCookie() !== undefined;
}

export const removeSignInCookies = () => {
    removeCookie("token");
    removeCookie("username");
    removeCookie("bilibili_state");
}

const removeCookie = (key: string) => {
    const cookies = new Cookies();
    cookies.remove(key, { path: '/' });
}
