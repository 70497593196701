import React, { useState } from "react";

import FilterButton from "components/Widgets/FilterButton/FilterButton";

interface Props {
    cards: any;
    setFilteredCards: any;
}

const CardListFilterArea: React.FC<Props> = ({ cards, setFilteredCards }) => {
    const [cardTypeButtonSelected, setCardTypeButtonSelected] = useState<string>("全部");

    const filterCardType = (
        e:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.MouseEvent<HTMLDivElement, MouseEvent>,
        cardtype: any
    ) => {
        const newCards = cards.filter((card: { card_type: any }) => {
            return card.card_type === cardtype || cardtype === "全部";
        });
        // console.log(cards);
        setFilteredCards(newCards);
        setCardTypeButtonSelected(cardtype);
    };

    const orderedTypeList = ["全部", "角色", "装备", "支援", "事件"];
    var renderedTypeFilter = orderedTypeList.map((type) => (
                <FilterButton
                        key={type}
                        onClick={(e) => filterCardType(e, type)}
                        selected={cardTypeButtonSelected === type}>
                    {type}
                </FilterButton>));

    return (
        <div
            className="mt-3 mb-5">
            <table>
                <tr>
                    <th
                            className=
                                "text-nowrap align-top tql-align-middle-md py-2"
                            style={{
                                paddingRight: '20px',
                                color: 'var(--tql-text-light)'}}>
                        卡牌类型
                    </th>
                    <td>
                        {renderedTypeFilter}
                    </td>
                </tr>
            </table>
        </div>
    );
};

export default CardListFilterArea;
