// Shared current user information across the application

import { createSlice, Dispatch } from '@reduxjs/toolkit'
import auth_api, { UserInfoResponse } from 'api/auth_api';
import {isSignedIn } from 'utils/cookieHelper';

export interface UserInfoState {
  info: UserInfoResponse | null,
  loading: boolean,
}

export const userSlice = createSlice({
  name: 'user_info',
  initialState: {
    info: null,
    loading: false,
  } as UserInfoState,
  reducers: {
    clear: (state) => {
      state.info = null;
    },
    loading: (state, action) => {
      state.loading = action.payload;
    },
    saveResponse: (state, action) => {
      state.info = action.payload;
      state.loading = false;
    },
  },
})

export const actions = userSlice.actions;

export function loadUserInfo(dispatch: Dispatch, state: UserInfoState) {
  if (state.loading || state.info != null || !isSignedIn()) {
    return;
  }

  dispatch(actions.loading(true));
  auth_api.userInfo(
    (response) => {
      dispatch(actions.saveResponse(response));
    },
    (_error) => {
      dispatch(actions.loading(false));
    }
  )
}

export default userSlice.reducer;
