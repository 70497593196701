import React from 'react';
// import { Upload, Modal, message, Typography } from 'antd';
// import ImgCrop from 'antd-img-crop';
// import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
// import { PlusOutlined } from '@ant-design/icons';
// import { API_BASE_URL } from 'config';
// import axios from 'axios';
// import { getCookie } from 'utils/cookieHelper';
// import imageCompression from 'browser-image-compression';

// const { Text } = Typography;


interface Props {
    visible: boolean;
    onUploadAvatarSuccess: (AvatarUrl: string) => void;
    onCancel: () => void;
}

const UploadAvatarDialog: React.FC<Props> = ({ /* visible, onUploadAvatarSuccess, onCancel */}) => {
    // const [fileList, setFileList] = useState<UploadFile[]>([]);
    // const [previewVisible, setPreviewVisible] = useState(false);
    // const [previewImage, setPreviewImage] = useState('');
    // const [previewTitle, setPreviewTitle] = useState('');
    // const UserId = getCookie("UserId")
    // const Password = getCookie("Password");
    // const GoogleId = getCookie("GoogleId");

    // const handlePreviewCancel = () => setPreviewVisible(false);

    // const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    //     setFileList(newFileList);
    // };

    // const getBase64 = (file: RcFile): Promise<string> =>
    //     new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onload = () => resolve(reader.result as string);
    //         reader.onerror = error => reject(error);
    //     });
    // const onPreview = async (file: UploadFile) => {
    //     if (!file.url && !file.preview) {
    //         file.preview = await getBase64(file.originFileObj as RcFile);
    //     }

    //     setPreviewImage(file.url || (file.preview as string));
    //     setPreviewVisible(true);
    //     setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    // };

    // const beforeUpload = async (file: any) => {
    //     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    //     if (!isJpgOrPng) {
    //         message.error('You can only upload JPG/PNG file!');
    //     }
    //     const compressOptions = {
    //         maxSizeMB: 1,
    //         maxWidthOrHeight: 640,
    //         useWebWorker: true
    //     }

    //     try {
    //         const compressedFile = await imageCompression(file, compressOptions);
    //         // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
    //         // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    //         return compressedFile;
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    // const uploadAvatar = (options: any) => {
    //     const requestData = new FormData()
    //     requestData.append('avatarImage', options.file)
    //     requestData.append('UserId', UserId)
    //     requestData.append('Password', Password)
    //     requestData.append('GoogleId', GoogleId)
    //     axios.post(`${API_BASE_URL}/users/upload-avartar`, requestData).then(
    //         response => {
    //             options.onSuccess(response.data, options.file)
    //             if (response.data.status === 'SUCCESS') {
    //                 onUploadAvatarSuccess(response.data.result.AvatarUrl);
    //             } else {
    //                 message.error(response.data.message);
    //             }
    //         },
    //         error => {
    //             console.log("[DEBUG] uploadAvatar Error", error);
    //         }
    //     )
    // }

    // const uploadButton = (
    //     <div>
    //         {/* <PlusOutlined /> */}
    //         <div style={{ marginTop: 8 }}>Upload</div>
    //     </div>
    // );

    return (
        <div></div>
        // <Modal
        //     visible={visible}
        //     title={"Upload Profile Image"}
        //     footer={null}
        //     onCancel={onCancel}
        //     width={350}
        // >
        //     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
        //         <Text >{"Click or drag & drop an image to upload"}</Text>
        //         <Text type="secondary">{"Only JPG & PNG files are accepted"}</Text>
        //         <div style={{ width: '100px', marginTop: '30px' }}>
        //             {/* <ImgCrop rotate>
        //                 <Upload
        //                     // action={`${API_BASE_URL}/users/upload-avartar`}
        //                     listType="picture-card"
        //                     fileList={fileList}
        //                     customRequest={uploadAvatar}
        //                     onChange={onChange}
        //                     onPreview={onPreview}
        //                     beforeUpload={beforeUpload}
        //                 >
        //                     {fileList.length < 1 && uploadButton}
        //                 </Upload>
        //             </ImgCrop> */}
        //             <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handlePreviewCancel}>
        //                 <img alt="example" style={{ width: '100%' }} src={previewImage} />
        //             </Modal>
        //         </div>
        //     </div>
        // </Modal>
    );
};

export default UploadAvatarDialog;
