import React from 'react';
import './NavBar.css';
import { useTranslation } from "react-i18next";
import UserProfileAvatar from './UserProfileAvatar/UserProfileAvatar';
import { logo } from 'logo';
import VersionBanner  from './VersionBanner';
import LanguageSelector from './LanguageSelector';

const NavBar: React.FC = () => {
    const { t, i18n } = useTranslation();
    const language = i18n.language;

    const navBarFooter = () => {
        return (
            <span style={{ display: 'flex', alignItems: 'center'}}>
                <LanguageSelector />
                <UserProfileAvatar />
            </span >
        )
    }

    return (
        <nav className="navbar sticky-top navbar-expand-lg px-0 flex-wrap">
            <VersionBanner />
            <div className="container-fluid flex-wrap py-1 py-lg-0">
                {/* Mobile menu button */}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <a className="navbar-brand mx-auto p-2" href="https://tqlib.com/">
                    {logo(0.25)}
                    <b className='semi-navigation-header-text d-none d-lg-inline'>
                        TQlib
                    </b>
                </a>

                {/* Other buttons (such as the user's avatar) */}
                <span className='order-lg-1'>
                    {navBarFooter()}
                </span>

                {/* Menu */}
                <div className="collapse navbar-collapse order-lg-0" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 semi-navigation-item">
                        <li className="dropdown">
                            <a className="nav-link" href={`/${language}/contest/abyss`}>{t("navbar.contest")}</a>
                            <ul className="dropdown-content">
                                <li><a className="nav-link" href={`/${language}/contest/abyss`}>{ t("navbar.contestAbyss") }</a></li>
                                <li><a className="nav-link" href={`/${language}/contest/tcg`}>{t("navbar.contestTcg")}</a></li>
                            </ul>
                        </li>
                        
                        <li className="dropdown">
                            <a className="nav-link" href={`/${language}/tcg`}>{t("navbar.tcg")}</a>
                            <ul className="dropdown-content">
                                <li><a className="nav-link" href={`/${language}/tcg-cards`}>{t("navbar.tcgCards")}</a></li>
                                <li><a className="nav-link" href={`/${language}/tcg-card-decks`}>{t("navbar.tcgCardDecks")}</a></li>
                                <li><a className="nav-link" href={`/${language}/contest/tcg`}>{t("navbar.tcgContest")}</a></li>
                                <li><a className="nav-link" href={`/${language}/tcg-my-card-decks`}>{t("navbar.tcgMyCardDecks")}</a></li>
                                <li><a className="nav-link" href={`/${language}/contest/tcg/register_v2/step1`}>{t("navbar.tcgRegister")}</a></li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" href={`/api/page/view/页面/`}>
                                Wiki
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;