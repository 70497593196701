import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import Loading from 'components/Loading/Loading';
import { Toast } from '@douyinfe/semi-ui';
import Title from 'utils/title';
import bilibili_api from 'api/auth/bilibili_api';
import { useDispatch } from 'react-redux';
import { actions } from 'states/user_info';


export default function LinkAccountPage() {
    const [loading, setLoading] = useState<boolean>(true);
    const [result, setResult] = useState<string>("派蒙正在检查b站的令牌……");
    const [searchParams, _setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispach = useDispatch();

    useEffect(() => {
        bilibili_api.decodeCallbackState(
            {callback_state: searchParams.get("state") || ""},
            (response)=> {
                generateCookie(response.return_url);
            },
            (_error) => {
                setResult("验证失败。Bilibili 提供的令牌无效，请重新登录。");
                setLoading(false);
            },
        );
    }, [searchParams])

    const generateCookie = (return_url: string) => {
        bilibili_api.generateCookieState(
            {bilibili_code: searchParams.get("code") || ""},
            (_response) => {
                linkAccount(return_url);
            },
            (_error) => {
                setResult("Bilibili验证失败，请重试。");
                setLoading(false);
            }
        )
    }

    const linkAccount = (return_url: string) => {
        bilibili_api.LinkAccount(
            (_response) => {
                Toast.success("绑定成功！3秒后将自动跳转到登录前的页面。");
                setResult("绑定成功！3秒后将自动跳转到登录前的页面。");
                setLoading(false);
                dispach(actions.clear());  // Reload user information
                setTimeout(()=>{navigate(return_url);}, 3000);
            },
            (_error) => {
                setResult("Bilibili验证失败，请重试。");
                setLoading(false);
            }
        );
    };

    return <div className="container">
        <h1><Title title="绑定Bilibili账号" /></h1>
        <p>
            {
                loading?
                <Loading prompt="派蒙正在检查 Bilibili 的令牌……" />
                : result
            }
        </p>
    </div>;
}
