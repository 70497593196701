import { useEffect, useState } from 'react';

import { Row, Col } from '@douyinfe/semi-ui';
import { Pagination } from '@douyinfe/semi-ui';
import { Typography } from '@douyinfe/semi-ui';
import axios from 'axios';
import { API_BASE_URL } from 'config';
import { TCG_CARD_DECK_PAGE_SIZE } from 'projectConstants';
import CardDeckListVersion from 'components/Tcg/CardDeck/CardDeckListVersion';
import Loading from 'components/Loading/Loading';
import Title from 'utils/title';


export default function TcgCardDecksPage() {
    const [cards, setCards] = useState<any>(null);
    const [cardDecks, setCardDecks] = useState<any>(null);
    const [currentPageNum, setCurrentPageNum] = useState<number>(1);
    const [totalItemCount, setTotalItemCount] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(true);

    // TODO
    const language = "zh-CN";

    const getCardsApiCall = () => {
        axios.get(`${API_BASE_URL}/tcg/card_list?lang=${language}`, {

        }).then(
            response => {
                setCards(response.data.data);
            },
            error => {
                console.log("[DEBUG] TODO", error);
            }
        )
    }

    const getCardDecksApiCall = (pageSize: number, pageNum: number) => {
        setLoading(true);
        axios.get(`${API_BASE_URL}/tcg/card_deck/approved?pg_size=${pageSize}&pg_num=${pageNum}`, {

        }).then(
            response => {
                setCardDecks(response.data.data);
                setTotalItemCount(response.data.pagination.total_record);
                setLoading(false);
            },
            error => {
                console.log("[DEBUG] TODO", error);
                setLoading(false);
            }
        )
    }

    useEffect(() => {
        getCardsApiCall();
        getCardDecksApiCall(TCG_CARD_DECK_PAGE_SIZE, 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const onPageChange = (currentPage: number) => {
        setCurrentPageNum(currentPage);
        getCardDecksApiCall(TCG_CARD_DECK_PAGE_SIZE, currentPage);
    }

    return (
        <div>
            <div style={{ marginTop: '40px' }} />
            <h1><Title title="卡组一览" /></h1>

            {loading && 
                <Loading prompt={"加载中。。。"} loading={loading}/>
            }
            {loading || 
                <Row
                    gutter={{ xs: 10, sm: 16, md: 16, lg: 18, xl: 24, xxl: 24 }}
                    type="flex"
                    justify="start"
                    align="top"
                >
                    {
                        cards && cardDecks && cardDecks.map((cardDeck: any) => {
                            return (
                                <Col key={cardDeck._id} xs={24} sm={12} md={12} lg={8} xl={8} style={{ marginTop: '20px' }}>
                                    <CardDeckListVersion
                                        cardDeck={cardDeck}
                                        cards={cards}
                                        createEditMode={false}
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>
            }
            
            <div style={{ marginTop: '40px' }} />

            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Pagination 
                    total={totalItemCount} 
                    showTotal 
                    hideOnSinglePage
                    currentPage={currentPageNum}
                    onPageChange={onPageChange}
                    pageSize={TCG_CARD_DECK_PAGE_SIZE}
                    style={{ marginBottom: 12 }}></Pagination>
            </div>

            <div style={{ marginTop: '40px' }} />

        </div>
    );
}
