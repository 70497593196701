import { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isSignedIn } from "utils/cookieHelper";
import { relPath } from "utils/navigateHelpers";
import { IconUpload } from '@douyinfe/semi-icons';
import { Button, Card, Divider, Form, Space, TabPane, Tabs, Toast, ToastFactory, Upload } from '@douyinfe/semi-ui';
import tcg_api from "api/contest/tcg_api";
import { customRequestArgs } from "@douyinfe/semi-ui/lib/es/upload";
import tcg_avatar_api from "api/contest/tcg_avatar_api";
import contest_api from "api/contest_api";
import auth_api from "api/auth_api";
import Title from "utils/title";

interface ContestInfo {
    name: string
    numCardDecksPerUser: number
}

const Step3Page: React.FC<{}> = () => {
    const navigate = useNavigate();
    const [loadingContest, setLoadingContest] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [contestInfo, setContestInfo] = useState<ContestInfo>({
        name: '',
        numCardDecksPerUser: 0
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const contestFormRef = useRef<Form<any>>(null);
    const formRef = useRef<Form<any>>(null);
    const [tabActiveKey, setTabActiveKey] = useState<string>("1");
    const [avatar_url, setAvatarUrl] = useState<string>("");

    const onFieldsChange = (values: any) => {
        formRef.current?.formApi.validate().catch(() => {});
    }

    const validateFields = (values: any) => {
        const errors: any = {};
        if (avatar_url === "") {
            errors.avatar = "请上传头像";
            // ToastFactory.create({
            //     left: "30%",
            // }).error('请上传头像');
        }
        return Object.keys(errors).length !== 0 ? errors : null;
    }

    const onAvatarUpload = (args: customRequestArgs) => {
        var data = new FormData();
        data.append("file", args.fileInstance);
        // 虚假进度条
        let count = 0;
        let interval = setInterval(() => {
            if (count === 100) {
                clearInterval(interval);
                return;
            }
            args.onProgress({ total: 100, loaded: count });
            count += 10;
        }, 50);
        tcg_avatar_api.upload_contest_avatar(data,
            response => {
                console.log(response)
                setAvatarUrl(response.filename);
                count = 100;
                args.onProgress({ total: 100, loaded: 100 });
                args.onSuccess(response, new Event('complete'));
            },
            _error => {
                args.onProgress({ total: 100, loaded: 100 });
            });
    };

    const gotoStep1 = () => {
        navigate(relPath('/contest/tcg/register_v2/step1'));
    };
    let imageOnly = 'image/*';

    useEffect(() => {
        if (!isSignedIn()) {
            gotoStep1();
        }

        // Try to get contest ID from URL
        auth_api.getProfile((response) => {
            formRef.current?.formApi.setValue("ys_uid", response.ys_uid);
            if (response.related_accounts != null) {
                response.related_accounts.forEach(element => {
                    if (element.platform === "QQ") {
                        // It is okey if we have multiple QQ fields. Just use 
                        // the last one.
                        formRef.current?.formApi.setValue("qq",
                            element.username);
                    }
                });
            }
        })

        let contest_id = searchParams.get('contest_id');
        if (contest_id != undefined) {
            onSubmitContestID({ contest_id: contest_id });
            formRef.current?.formApi.setValue('contest_id', contest_id);
        }
        formRef.current?.formApi.setValue('contest_id', contest_id);
    }, [])

    const onSubmitContestID = function (values: any) {
        setLoadingContest(true);

        setSearchParams({ contest_id: values.contest_id });
        tcg_api.registration_info({
            contest_id: values.contest_id,
        },
            (response) => {
                setContestInfo({
                    name: response.name,
                    numCardDecksPerUser: response.num_card_decks_per_user,
                });
                setLoadingContest(false);
                contestFormRef.current?.formApi.setValue('contest_id', values.contest_id);
                formRef.current?.formApi.setValue('contest_id', values.contest_id);
                console.log(contestInfo.numCardDecksPerUser)
                setTabActiveKey("2");
            },
            (_error) => {
                setLoadingContest(false);
            });


    }

    const gotoNextStep = () => {
        navigate(relPath('/contest/tcg/register_v2/step4?contest_id=' + searchParams.get('contest_id')));
    };

    const onSubmit = (values: any) => {
        console.log(values)

        if (avatar_url === "") {
            ToastFactory.create({
                left: "30%",
            }).error('请上传头像');
        } else {
            contest_api.loginRegister({
                username: values.username,
                email: values.email,
                ys_uid: values.ys_uid + "",
                qq: values.qq + "",
                contest_code: values.contest_id,
                custom_questions: [],
                characters: [],
                actions: [],
                avatar: avatar_url
            },
                () => {
                    console.log(contestInfo)
                    console.log(contestInfo?.numCardDecksPerUser)
                    setSubmitting(false);
                    if (contestInfo.numCardDecksPerUser === 0) {
                        Toast.success("感谢报名～")
                    } else {
                        gotoNextStep();
                    }
                },
                () => {
                    setSubmitting(false);
                    //gotoStep1();
                });
        }


    }

    return <div style={{
        position: 'relative',
        marginTop: '50px',
        justifyContent: 'center',
        alignContent: 'center',
        width: "100%",
        height: '100%',
        display: 'inline-block',
        padding: 20,
    }}>


        <Card
            style={{ maxWidth: 360, margin: "auto", top: "60%" }}
            bordered={false}
            headerLine={false}
            shadows="always"

        >
            <h1><Title title="比赛报名" /></h1>
            <Tabs type="line" activeKey={tabActiveKey} onTabClick={value => setTabActiveKey(value)}>
                <TabPane tab="比赛信息" itemKey="1">
                    <Form
                        labelPosition="left"
                        labelWidth={100}
                        layout='vertical'
                        ref={contestFormRef}
                        onSubmit={onSubmitContestID} >
                        <Form.Input field='contest_id' label='比赛邀请码'
                            style={{ width: 200 }}
                            disabled={loadingContest} />

                        <div style={{ marginTop: "30px", width: "100%" }}>
                            <Button
                                block
                                htmlType='submit'
                                theme="solid"
                                loading={loadingContest}>
                                确认
                            </Button>
                        </div>
                    </Form>
                </TabPane>
                <TabPane tab="选手信息" itemKey="2" disabled>
                    <Form
                        labelPosition="left"
                        labelWidth={80}
                        layout='vertical'
                        ref={formRef}
                        onValueChange={onFieldsChange}
                        validateFields={validateFields}
                        onSubmit={onSubmit}
                    >
                        <Space />
                        <Upload action={""} accept={imageOnly} style={{ marginTop: 12 }} customRequest={onAvatarUpload} maxSize={1024} limit={1}>
                            <Button icon={<IconUpload />} theme="light">
                                上传选手头像
                            </Button>
                        </Upload>

                        <Divider margin='20px' />


                        <div style={{ marginTop: "30px", width: "100%" }}>
                            <Button
                                block
                                htmlType='submit'
                                theme="solid"
                                loading={submitting}>
                                {contestInfo?.numCardDecksPerUser === 0 ? '提交' : '下一步'}
                            </Button>
                        </div>

                    </Form>
                </TabPane>
            </Tabs>
        </Card>
    </div >
}

export default Step3Page;
