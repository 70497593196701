import './App.css';
// import HomePage from 'pages/HomePage/HomePage';
import NavBar from 'components/NavBar/NavBar';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import WikiPage from 'pages/WikiPage/WikiPage';
import DefaultPage from 'pages/DefaultPage/DefaultPage';
import TcgCardsListPage from 'pages/Tcg/TcgCardsListPage/TcgCardsListPage';
import TcgCardDecksPage from 'pages/Tcg/TcgCardDecksPage/TcgCardDecksPage';
import AdminPage from 'pages/AdminPage/AdminPage';
import UserProfilePage from 'pages/UserProfilePage/UserProfilePage';
import TcgCardDeckDetailPage from 'pages/Tcg/TcgCardDeckDetailPage/TcgCardDeckDetailPage';
import ContestsTcgRegisterPage from 'pages/Contests/Tcg/ContestsTcgRegisterPage/ContestsTcgRegisterPage';
import TcgMyCardDecksPageLi from 'pages/Tcg/TcgMyCardDecksPage_Li/TcgMyCardDecksPageLi';
import TcgContestDetailPage from 'pages/Contests/Tcg/TcgContestDetailPage/TcgContestDetailPage';
import TcgMyCardDeckEditPage from 'pages/Tcg/TcgMyCardDeckEditPage/TcgMyCardDeckEditPage';
import React from 'react';
import AllTcgContestsPage from 'pages/Contests/Tcg/AllContestPage/AllTcgContestsPage';
import Step1Page from 'pages/Contests/Tcg/RegisterV2/Step1Page';
import Step2Page from 'pages/Contests/Tcg/RegisterV2/Step2Page';
import Step3Page from 'pages/Contests/Tcg/RegisterV2/Step3Page';
import Step4Page from 'pages/Contests/Tcg/RegisterV2/Step4Page';
import TcgContestAdminPage from 'pages/Contests/Tcg/TcgContestAdminPage/TcgContestAdminPage';
import VerifyEmailPage from 'pages/auth/VerifyEmailPage/VerifyEmailPage';
import CreateTcgContestStep1Page from 'pages/Contests/Tcg/CreateTcgContestPage/CreateTcgContestStep1Page';
import CreateAbyssContestStep1Page from 'pages/Contests/Abyss/CreateAbyssContestPage/CreateAbyssContestStep1Page';
import CreateExternalAbyssContestPage from 'pages/Contests/Abyss/CreateAbyssContestPage/CreateExternalAbyssContestPage';
import AbyssContestDetailPage from 'pages/Contests/Abyss/AbyssContestDetailPage/AbyssContestDetailPage';
import AllAbyssContestsPage from 'pages/Contests/Abyss/AllAbyssContestsPage/AllAbyssContestsPage';
import AbyssRecordSubmitPage from 'pages/Contests/Abyss/AbyssRecordSubmitPage/AbyssRecordSubmitPage';
import BilibiliLoginPage from 'pages/auth/bilibili_callback/login/LoginPage';
import BilibiliLinkAccountPage from 'pages/auth/bilibili_callback/link_account/LinkAccountPage';
import store from 'states/store'
import { Provider } from 'react-redux'
import ResetPasswordPage from 'pages/auth/ResetPasswordPage/ResetPasswordPage';


// Fixed content area width
function fixedWidthPage(page: React.ReactNode) {
    return <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '1200px' }}>
            {page}
        </div>
    </div>
}

function App() {

    const { innerWidth: windowWidth } = window;

    return (
        <Provider store={store}>

            <BrowserRouter>
                <NavBar />
                <Routes>
                    {/* <Route path=":language/home" element={<HomePage />} />*/}
                    <Route path=":language/auth/verify_email" element={<VerifyEmailPage />} />
                    <Route path=":language/auth/reset_password" element={<ResetPasswordPage />} />
                    <Route path=":language/auth/bilibili_callback/login" element={<BilibiliLoginPage />} />
                    <Route path=":language/auth/bilibili_callback/link_account" element={<BilibiliLinkAccountPage />} />
                    <Route path=":language/tcg" element={fixedWidthPage(<TcgCardsListPage />)} />
                    <Route path=":language/tcg-cards" element={<TcgCardsListPage />} />
                    <Route path=":language/tcg-card-decks" element={fixedWidthPage(<TcgCardDecksPage />)} />
                    <Route path=":language/tcg-card-decks/:id/edit" element={<TcgMyCardDeckEditPage />} />
                    <Route path=":language/tcg-card-decks/*" element={fixedWidthPage(<TcgCardDeckDetailPage />)} />
                    <Route path=":language/tcg-my-card-decks" element={<div className='container'><TcgMyCardDecksPageLi /></div>} />

                    <Route path=":language/contest/abyss" element={<AllAbyssContestsPage isMobile={false} />} />
                    <Route path=":language/contest/abyss/:contest_id" element={<AbyssContestDetailPage />} />
                    <Route path=":language/contest/abyss/:contest_id/submit" element={<AbyssRecordSubmitPage isMobile={false} />} />
                    <Route path=":language/contest/abyss/create" element={<CreateAbyssContestStep1Page />} />
                    <Route path=":language/contest/abyss/createExternal" element={<CreateExternalAbyssContestPage />} />

                    <Route path=":language/contest/tcg/create" element={<CreateTcgContestStep1Page />} />
                    <Route path=":language/contest/tcg/register_v2/step1" element={<Step1Page />} />
                    <Route path=":language/contest/tcg/register_v2/step2" element={<Step2Page />} />
                    <Route path=":language/contest/tcg/register_v2/step3" element={<Step3Page />} />
                    <Route path=":language/contest/tcg/register_v2/step4" element={<Step4Page />} />
                    <Route path=":language/contest/tcg/register" element={windowWidth >= 1200 ? fixedWidthPage(<ContestsTcgRegisterPage isMobile={false} />) : <ContestsTcgRegisterPage isMobile={true} />} />
                    <Route path=":language/contest/tcg/*" element={<TcgContestDetailPage />} />
                    <Route path=":language/contest/tcg/:contest_id/admin" element={fixedWidthPage(<TcgContestAdminPage />)} />
                    <Route path=":language/contest/tcg" element={fixedWidthPage(<AllTcgContestsPage />)} />

                    <Route path=":language/wiki/*" element={fixedWidthPage(<WikiPage />)} />

                    <Route path=":language/profile" element={<UserProfilePage />} />

                    {/* <Route path="/" element={<HomePage />} /> */}
                    <Route path="/:language/admin" element={fixedWidthPage(<AdminPage />)} />
                    <Route path="/*" element={<DefaultPage />} />
                </Routes>
                {/* <PageFooter /> */}
            </BrowserRouter>
        </Provider>
    );
}

export default App;
