import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Modal,
    Typography,
    Card,
} from "@douyinfe/semi-ui";
import CardDeckCharacterAvatar from "../CardDeckCharacterAvatar/CardDeckCharacterAvatar";
import CardDeckInfo from "../CardDeckInfo/CardDeckInfo";

interface Props {
    cardDeck: any; // 当前卡组（卡组schema形式）
    cards: any; // 所有卡牌
    createEditMode: boolean; // 编辑&添加 卡组的页面为 true (根据 我的卡组页面状态判断)
}

const CardDeckListVersion: React.FC<Props> = ({
    cardDeck,
    cards,
    createEditMode,
}) => {
    const { Text } = Typography;

    const [characterCardNames, setCharacterCardNames] = useState<any[]>();

    const [cardDeckDetailModalVisible, setCardDeckDetailModalVisible] = useState(false);
    useEffect(() => {
        setCharacterCardNames(cardDeck.characters ? cardDeck.characters : []);
    }, [cardDeck]); // 检测 cardDeck 因为在卡组创建页面添加卡牌时，需要rerender this CardDeck

    return (
        <>
            <Card
                key={cardDeck._id}
                title={cardDeck.name}
                headerLine={true}
                style={{
                    boxShadow: "3px 3px 10px #CCCCCC",
                }}
                headerExtraContent={
                    <div
                        style={{ display: "flex", flexDirection: "row" }}
                    >
                        {!createEditMode && (
                            <Text
                                link
                                onClick={() => setCardDeckDetailModalVisible(true)}
                            >
                                {"卡组详情"}
                            </Text>
                        )}
                    </div>
                }
            >
                <Row style={{ marginBottom: 20 }}>
                    {characterCardNames &&
                        characterCardNames.map((characterCardName: any) => {
                            const characterCard = cards.find((card: any) => card.name === characterCardName);
                            return (
                                <Col span={6}>
                                    <CardDeckCharacterAvatar card={characterCard} size={75} />
                                </Col>
                            );
                        })
                    }
                    {/* Placeholder (角色不足) */}
                    {characterCardNames && 3 - characterCardNames.length >= 0 && (
                        [...Array(3 - characterCardNames.length)].map(() => {
                            return (
                                <Col span={6}>
                                    <CardDeckCharacterAvatar card={null} size={75} />
                                </Col>)
                        })
                    )}
                </Row>
                <Row style={{ marginTop: 12 }}>
                    <Text>卡组作者: {cardDeck.username}</Text>
                </Row>
            </Card>

            {/* 卡组详情 Modal */}
            <Modal
                title="卡组数据"
                footer={null}
                visible={cardDeckDetailModalVisible}
                closeOnEsc={true}
                onCancel={() => setCardDeckDetailModalVisible(false)}
                size={"large"}
            >
                <CardDeckInfo
                    cardDeckId={cardDeck._id}
                />
            </Modal>
        </>
    );
};

export default CardDeckListVersion;
