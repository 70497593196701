import { useState } from 'react';
import { UserInfoState, actions } from 'states/user_info';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Toast } from '@douyinfe/semi-ui';
import { useLocation } from 'react-router-dom';
import bilibili_api from 'api/auth/bilibili_api';
import bilibiliUtils from 'utils/bilibiliUtils';
import { BASE_URL } from 'config';

export default function BilibiliAccountInfo() {
    const userInfo: UserInfoState = useSelector(
        (state: any) => state.user_info);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const location = useLocation();

    const onUnlinkAccount = () => {
        setLoading(true);
        bilibili_api.UnlinkAccount(
            (_response) => {
                Toast.success("成功解除绑定！3秒后将自动刷新页面。");
                dispatch(actions.clear);
                setLoading(false);
                // Refresh after 3 seconds.
                setTimeout(()=>{window.location.replace(window.location.href);}, 3000)
            },
            (_error) => {
                Toast.error("未能解除绑定，请重试。");
                setLoading(false);
            },
        );
    }

    const onLinkAccount = () => {
        setLoading(true);
        bilibiliUtils.login(
            BASE_URL + "auth/bilibili_callback/link_account",
            location.pathname + location.search,
            (url) => {window.location.replace(url);},
        )
    }
    if (userInfo.info?.bilibili_open_id) {
        return <>
            <p>
                用户名：{userInfo.info?.bilibili_name} 
                <br />
                ID：{userInfo.info?.bilibili_open_id}
            </p>
            <Button theme='solid' type='primary' loading={loading}
                    onClick={onUnlinkAccount}>
                解除绑定
            </Button>
        </>;
    } else {
        return <>
            <p>未绑定。</p>
            <Button theme='solid' type='primary' loading={loading}
                    onClick={onLinkAccount}>
                立即绑定
            </Button>
        </>
    }
}
