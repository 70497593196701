
import React from 'react';

import { Tooltip } from '@douyinfe/semi-ui';
import { TQLImage } from 'projectConstants';


interface Props {
    card: any;
    size_px?: number;
    horizental?: boolean;
}

interface CostDetail {
    bgURL: string;
    cost: number;
    fgColor: string;
    description: string;
}

const CardCost: React.FC<Props> = ({
    card,
    size_px = 50,
    horizental = true,
}) => {

    const ele_hex_map = new Map([
        ["岩", TQLImage.tcg.rock_hex],
        ["水", TQLImage.tcg.water_hex],
        ["雷", TQLImage.tcg.thunder_hex],
        ["风", TQLImage.tcg.wind_hex],
        ["火", TQLImage.tcg.fire_hex],
        ["草", TQLImage.tcg.grass_hex],
        ["冰", TQLImage.tcg.ice_hex],
    ]);

    var defaultBgURL = TQLImage.tcg.black_hex;
    

    const costBadge = (
        info: CostDetail, index: number, size_px: number, horizental: boolean) => (
        <>
            <img
                src={info.bgURL}
                style={{
                    position: 'absolute',
                    top: horizental? 0 : `${index * size_px}px`,
                    left: horizental? `-${index * size_px}px` : 0,
                    display:'inline'
                }}
                width={size_px} />
            <Tooltip content={info.description}>
                <span
                    className='text-center'
                    style={{
                        position: 'absolute',
                        top: horizental? 0 : `${index * size_px}px`,
                        left: horizental? `-${index * size_px}px` : 0,
                        width: size_px + 'px',
                        height: size_px + 'px',
                        fontSize: `calc(${size_px}px / 2)`,
                        lineHeight: size_px + 'px',
                        color: info.fgColor,
                        // Personal text shadow
                        textShadow: "1px 1px 2px black",
                    }}>
                    {info.cost}
                </span>
            </Tooltip>
        </>);

    let badgeInfos: CostDetail[] = [];
    if (card.cost) {
        if (card.cost.specify >= 0) {
            badgeInfos.push({
                bgURL: ele_hex_map.get(card.element) ?? defaultBgURL,
                cost: card.cost.specify,
                fgColor: 'white',
                description: `${card.element}元素骰子`,
            })
        }
        if (card.cost.universal >= 0) {
            badgeInfos.push({
                bgURL: TQLImage.tcg.black_hex,
                cost: card.cost.universal,
                fgColor: 'white',
                description: '任意元素骰子',
            })
        }
        if (card.cost.same >= 0) {
            badgeInfos.push({
                bgURL: TQLImage.tcg.white_hex,
                cost: card.cost.same,
                fgColor: 'white',
                description: '同种元素骰子',
            })
        }
        if (card.cost.energy >= 0) {
            badgeInfos.push({
                bgURL: TQLImage.tcg.energy_bg,
                cost: card.cost.energy,
                fgColor: 'white',
                description: '能量',
            })
        }
    }
    let costBadgeGroup: React.ReactNode[] = [];
    badgeInfos.forEach((info, index) => {
        costBadgeGroup.push(costBadge(info, index, size_px, horizental));
    })

    return (
        <span
            style={{
                position: 'relative',
                width: size_px + 'px',
                height: size_px + 'px',
                display: 'flex',
                alignItems:"center",
                float:'right'
            }}>
            {costBadgeGroup}
        </span>);
};

export default CardCost;
