import SignInSignUpDialog from "components/SignIn/SignInSignUpDialog/SignInSignUpDialog";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isSignedIn } from "utils/cookieHelper";
import { relPath } from "utils/navigateHelpers";
import { Divider } from '@douyinfe/semi-ui';
import Title from "utils/title";

const Step1Page: React.FC<{}> = () => {
    const navigate = useNavigate();
    const [dialogVisiable, setDialogVisable] = useState<boolean>(false);

    const gotoNextStep = () => {
        navigate(relPath('/contest/tcg/register_v2/step2'));
    };

    useEffect(() => {
        if (isSignedIn()) {
            gotoNextStep();
        }
    }, [])

    if (isSignedIn()) {
        return <p>您已登录，跳转中……</p>
    } else {

    }
    return <div className="container">
        <h1><Title title="赛事报名" /></h1>
        <p>您尚未登录，请选择一种注册方式。</p>
        <Divider margin='12px' />
        <h2>登录或注册账号</h2>
        <p>您可以将您的所有比赛信息保存在一个账号中。</p>
        {/* <a href="https://www.tqlib.com/contest/tcg/register_v2"> */}
        {/* <a href={navigateHelpers.relPath('/contest/tcg/register_v2')}> */}
        <button className="btn btn-primary"
            onClick={() => setDialogVisable(true)}>
            登录 / 注册
        </button>
        {/* </a> */}
        <Divider margin='12px' />
        <h2>访客模式</h2>
        <p>您可以使用一次性临时账号来报名比赛。这样将不能汇总比赛信息。</p>
        <a href={relPath('/contest/tcg/register')}>
            <button className="btn btn-primary">
                访客报名
            </button>
        </a>
        <SignInSignUpDialog
            visible={dialogVisiable}
            onSignInSuccess={gotoNextStep}
            onSignUpSuccess={gotoNextStep}
            onCancel={() => setDialogVisable(false)} />
    </div>
}

export default Step1Page;
