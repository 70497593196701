import React, { useEffect, useState } from "react";
import {
    Avatar,
    Badge,
    Space,
    Spin,
} from "@douyinfe/semi-ui";
import { TeamBuild } from "api/contest/abyss_api";
import axios from "axios";

interface Props {
    build: TeamBuild,
    right_margin: number,
}

const AbyssTeamBuild: React.FC<Props> = ({ build, right_margin }) => {
    //console.log(build)

    const API_URL = "https://wiki.biligame.com/ys/api.php?action=query&titles="
    const QUERY = "&prop=imageinfo&iiprop=url&format=json"


    return (
        <div style={{ marginRight: `${right_margin}px` }}>
            <Space spacing={right_margin > 10 ? 'tight' : right_margin}>
                <Badge count={build.character_build.number} position='rightTop'>
                    <Avatar
                        size="medium"
                        src={build.character_build.url}
                        style={{ backgroundColor: "darkgoldenrod" }}
                        shape='square'
                    />
                </Badge>
                <Badge count={build.weapon_build.number} position='rightTop' theme='inverted'>
                    <Avatar
                        size="small"
                        src={build.weapon_build.url}
                        style={{ backgroundColor: "peru" }}
                        shape='square'
                    />
                </Badge>
            </Space>
        </div>
    )
};

export default AbyssTeamBuild;