import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import auth_api from 'api/auth_api';
import { getErrorMessage } from 'utils/errorMessageHandler';
import Loading from 'components/Loading/Loading';
import { useSelector } from 'react-redux';
import { UserInfoState } from 'states/user_info';
import { Toast } from '@douyinfe/semi-ui';
import Title from 'utils/title';


export default function VerifyEmailPage() {
    const userInfo: UserInfoState = useSelector(
        (state: any) => state.user_info);
    const [loading, setLoading] = useState<boolean>(true);
    const [result, setResult] = useState<string>("加载中");
    const [allowResendEmail, setallowResendEmail] = useState<boolean>(true);
    const [searchParams, _searchParams] = useSearchParams();

    useEffect(() => {
        auth_api.verifyEmail(
            {verification_code: searchParams.get("code") || ""},
            () => {
                setLoading(false);
                setResult("邮箱验证成功！您可以继续浏览其他页面。");
            },
            (error) => {
                setLoading(false);
                setResult(getErrorMessage(error))
            });
    }, [searchParams])

    return <div className="container">
        <h1><Title title="邮箱验证" /></h1>
        <p>
            用户名：
            <input type='text' disabled={true} className='form-control'
                value={userInfo.info?.username}/>
        </p>
        <p>
            验证码：
            <input type='text' disabled={true} className='form-control'
                value={searchParams.get("code") || ""} />
        </p>
        {loading?
            <Loading prompt="正在验证邮箱" /> :
            <p>{result}</p>}
        {
            !loading && !userInfo.info?.email_verified?
            <button className='btn btn-primary'
                disabled={!allowResendEmail}
                onClick={() => {
                    setallowResendEmail(false);
                    auth_api.sendVerificationEmail(
                        (_response) => {
                            Toast.success("已发送验证邮件");
                        });
                }}>
                重发验证邮件
            </button>
            :
            <></>
        }
    </div>;
}
