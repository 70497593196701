import React, { ReactElement, useEffect, useState } from 'react';
import bilibili_api, { ListVideosResponse, VideoInfo, VideoStatus } from 'api/bilibili_api';
import { Button, Checkbox, InputNumber, Modal } from '@douyinfe/semi-ui';
import { getBilibiliCookie } from 'utils/cookieHelper';
import bilibiliUtils from 'utils/bilibiliUtils';
import { BASE_URL } from 'config';

interface Props {
    selectedVideo?: VideoInfo;
    onChangeSelection: (video?: VideoInfo) => void;
}

const NUM_VIDEOS_PER_PAGE = 12;

const SelectBilibiliVideo: React.FC<Props> = (props) => {
    if (getBilibiliCookie() === undefined) {
        return <LoginButton />;
    } else {
        return SelectBilibiliVideoWithLogin(props);
    }
}

const SelectBilibiliVideoWithLogin: React.FC<Props> = ({selectedVideo, onChangeSelection}) => {
    const [dialogVisable, setDialogVisable] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [videoInfo, setVideoInfo] = useState<ListVideosResponse>();
    useEffect(
        () => {
            bilibili_api.listVideos(
                {
                    page_number: pageNumber,
                    page_size: NUM_VIDEOS_PER_PAGE,
                    status: VideoStatus.all,
                },
                (response) => {
                    setVideoInfo(response);
                },
                (_error) => {

                }
            )
        },
        [pageNumber],
    )
    let videoContent: ReactElement[] = [];
    videoInfo?.videos.forEach(
        (video) => {
            videoContent.push(
                <div className='col'
                    style={{cursor: 'pointer'}}
                    onClick={
                        () => {
                            if (selectedVideo?.resource_id != video.resource_id) {
                                onChangeSelection(video);
                            } else {
                                onChangeSelection(undefined);
                            }
                        }
                }>
                    <img
                        width={'100%'}
                        src={video.cover_url} />
                    <div>
                        <input className='me-1' type='checkbox'
                            checked={selectedVideo?.resource_id == video.resource_id}/>
                        <span className='text-secondary float-end'>
                            {Math.floor(video.duration_seconds / 60)}
                            :
                            {String(video.duration_seconds % 60).padStart(2, '0')}
                        </span>
                    </div>
                    {video.title}
                </div>
            )
        }
    )
    return <>
        <p>{selectedVideo? selectedVideo.title : "未选择视频"}</p>
        <Button theme='solid' type='primary'
                onClick={() => setDialogVisable(true)}>
            选择视频
        </Button>
        <Modal
                visible={dialogVisable}
                onOk={() => setDialogVisable(false)}
                onCancel={() => setDialogVisable(false)}
                hasCancel={false}
                style={{width: 'calc(100vw - 60px)', maxWidth: '960px'}}
                size='large'>
            {pageSelector(pageNumber, setPageNumber, videoInfo)}
            <div className='container'>
                <div className='row row-cols-1 row-cols-sm-2 row-cols-md-4'>
                    {videoContent}
                </div>
                {((videoInfo?.total_num_videos ?? 0) == 0)?
                    <p>
                        无视频。若您未授权 TQLib 查看您的视频，请退出登录然后重新授权。
                    </p>
                    :""
                }
            </div>
            {pageSelector(pageNumber, setPageNumber, videoInfo)}
        </Modal>
    </>;
}

function pageSelector(
        pageNumber: number, setPageNumber: (p: number) => void,
        videoInfo: ListVideosResponse | undefined)
{
    let totalPages = videoInfo?
        Math.ceil(videoInfo.total_num_videos / NUM_VIDEOS_PER_PAGE):
        0;
    return <div className='my-3 text-center'>
        <Button theme='solid' type='primary'
                onClick={() => setPageNumber(pageNumber - 1)}
                disabled={pageNumber <= 1}>
            上一页
        </Button>
        <span className='mx-3'>
            第
            <InputNumber hideButtons={true} style={{width: '3em'}}
                value={pageNumber}
                min={1}
                max={totalPages}
                onNumberChange={(value, e) => setPageNumber(value)}
            />
            页，共 {totalPages} 页
        </span>
        <Button theme='solid' type='primary'
                onClick={() => setPageNumber(pageNumber - 1)}
                disabled={pageNumber >= totalPages}>
            下一页
        </Button>
    </div>
}

const LoginButton: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const onClick = () => {
        setLoading(true);
        bilibiliUtils.login(
            BASE_URL + 'auth/bilibili_callback/login?guest=true',
            window.location.href,
            (url: string)=>{window.location.replace(url);});
    }
    return <>
        <p>请登录B站账号并授权访问您的视频信息</p>
        <Button theme='solid' type='primary' onClick={onClick} loading={loading}>
            登录
        </Button>
    </>;
}

export default SelectBilibiliVideo;
