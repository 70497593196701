import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { USERNAME_LENGTH_MIN, USERNAME_LENGTH_MAX, PASSWORD_LENGTH_MIN, PASSWORD_LENGTH_MAX } from 'projectConstants';


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "zh-CN",
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            "en-US": {
                translation: {
                    "actions": {
                        "create": "Create",
                        "cancel": "Cancel",
                        "submit": "Submit",
                        "close": "Close",
                        
                    },
                    "signin": {
                        "signin": "Sign in",
                        "signup": "Sign up",
                        "signout": "Sign out",
                        "username": "Username",
                        "username_or_email": "Username/Email",
                        "password": "Password",
                        "email": "Email",
                        "confirmPassword": "Confirm password",
                        "alreadyHaveAnAccount": "Already have an account?",
                        "createNewAccount": "Create a new account",
                        "validations": {
                            "validationUsernameLength": `Username length must be between ${USERNAME_LENGTH_MIN} and ${USERNAME_LENGTH_MAX} characters.`,
                            "validationPasswordLength": `Username length must be between ${PASSWORD_LENGTH_MIN} and ${PASSWORD_LENGTH_MAX} characters.`,
                            "validationPasswordNotSame": "The passwords entered are not the same, please check."
                        }
                    },
                    "errors": {
                        "ERR_AUTH_USER_ALREADY_EXISTS": "User with this user name alerady exists, please try another one",
                        "ERR_AUTH_USER_NOT_EXIST": "User doesn't exist, pleases verify username and password.",
                        "ERR_AUTH_PASSWORD_INCORRECT": "Password incorrect, please verify.",
                        "ERR_AUTH_EMAIL_MALFORMAT": "Email address has wrong format, please double check.",
                    },
                    "navbar": {
                        "contest": "Contest",
                        "tcg": "TCG",
                        "contestAbyss": "Championship",
                        "contestTcg": "TCG",
                        "tcgContest": "Contests",
                        "tcgCards": "All Cards",
                        "tcgCardDecks": "Player Decks",
                        "tcgMyCardDecks": "My Decks",
                        "tcgRegister": "Contest Signup",
                        "Wiki": "Wiki"
                    }
                },
            },
            "zh-CN": {
                translation: {
                    "actions": {
                        "create": "新建",
                        "cancel": "取消",
                        "submit": "提交",
                        "close": "关闭",
                        
                    },
                    "signin": {
                        "signin": "登录",
                        "signup": "注册",
                        "signout": "登出",
                        "username": "用户名",
                        "username_or_email": "用户名/邮箱",
                        "email": "邮箱",
                        "password": "密码",
                        "confirmPassword": "确认密码",
                        "alreadyHaveAnAccount": "已有账户?",
                        "createNewAccount": "新建账户",
                        "validations": {
                            "validationUsernameLength": `用户名长度必须为 ${USERNAME_LENGTH_MIN} 至 ${USERNAME_LENGTH_MAX} 字符.`,
                            "validationPasswordLength": `密码长度必须为 ${PASSWORD_LENGTH_MIN} 至 ${PASSWORD_LENGTH_MAX} 字符.`,
                            "validationPasswordNotSame": "两次输入的密码不相符，请检查."
                        },
                    },
                    "errors": {
                        "ERR_AUTH_USER_ALREADY_EXISTS": "该用户名已被注册，请尝试其他用户名",
                        "ERR_AUTH_USER_NOT_EXIST": "用户不存在，请检查登录信息",
                        "ERR_AUTH_PASSWORD_INCORRECT": "密码不正确，请检查登录信息",
                        "ERR_AUTH_EMAIL_MALFORMAT": "邮箱地址格式不正确，请重新填写",
                        "ERR_AUTH_EMAIL_DOMAIN_NOT_ALLOWED": "邮箱域名不是常用域名，请使用常用的邮箱运营商",
                        "ERR_AUTH_OUT_OF_EMAIL_QUOTA": "发送邮件频率过高",
                        "ERR_AUTH_EMAIL_ALREADY_VERIFIED": "已验证过邮箱，无需重复验证",
                        "ERR_AUTH_EMAIL_VERIFICATION_FAILED": "邮箱验证失败，可能因为验证码无效或过期，请重试",
                        "ERR_CONTEST_NOT_FOUND": "比赛邀请码不正确",
                        "ERR_AUTH_MISSING_JWT": "该页面需要登录后才能访问",
                        "ERR_CONTEST_PLAYER_ALREADY_EXISTS": "选手名已存在，请尝试其他用户名"
                    },
                    "navbar": {
                        "contest": "原神赛事",
                        "contestAbyss": "杯赛",
                        "contestTcg": "七圣",
                        "tcg": "七圣召唤",
                        "tcgContest": "七圣赛事",
                        "tcgCards": "卡牌图鉴",
                        "tcgCardDecks": "卡组一览",
                        "tcgMyCardDecks": "我的卡组",
                        "tcgRegister": "赛事报名",
                        "Wiki": "Wiki"
                    }
                },
            },
        }
    });
export default i18n;
