import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import auth_api from 'api/auth_api';
import { useSelector } from 'react-redux';
import { UserInfoState } from 'states/user_info';
import { Toast } from '@douyinfe/semi-ui';
import { checkPasswordStrenth } from 'utils/checkPasswordStrenth';
import Title from 'utils/title';


export default function ResetPasswordPage() {
    const userInfo: UserInfoState = useSelector(
        (state: any) => state.user_info);
    const [verifying, setVerifying] = useState<boolean>(false);
    const [allowResendEmail, setallowResendEmail] = useState<boolean>(true);
    const [searchParams, _] = useSearchParams();
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [validationError, setValidationError] = useState<string>("");
    const [verificationCode, setVerificationCode] = useState<string>("");

    useEffect(()=>{
        setUsername(userInfo.info?.username || "");
        const newUsername = searchParams.get("username");
        if (newUsername !== null && newUsername !== "") {
            setUsername(newUsername!);
        }
        setVerificationCode(searchParams.get("code") || "");
    }, [userInfo, searchParams])

    const sendEmail = () => {
        setallowResendEmail(false);
        auth_api.sendResetPasswordEmail(username,
            (_response) => {
                Toast.success("已发送验证邮件，请根据邮件提示重置密码。");
            });
    }

    const resetPassword = () => {
        setVerifying(true);
        auth_api.resetPassword({
            username: username,
            verification_code: verificationCode,
            new_password: password,
        },
        (_response) => {
            Toast.success("密码重置成功！");
            setVerifying(false);
        },
        (_error) => {setVerifying(false);})
    };

    let resetActions = null;
    if (verificationCode !== "") {
        resetActions = (<>
            <p>
                验证码：
                <input className='form-control'
                    type='text'
                    value={verificationCode}
                    disabled={true}
                />
            </p>
            <p>
                新密码：
                <input className='form-control'
                    type='password'
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                        setValidationError(checkPasswordStrenth(e.target.value));
                    }}
                />
                {validationError !== "" &&
                    <div className='alert alert-warning'>
                        {validationError}
                    </div>
                }
            </p>
            <button className='btn btn-primary'
                disabled={validationError !== "" || verifying}
                onClick={resetPassword}
            >
                重置密码
            </button>
        </>);
    }

    return <div className="container">
        <h1><Title title="重置密码" /></h1>
        <p>
            用户名或邮箱：
            <input className='form-control'
                type='text'
                value={username}
                onChange={(event)=>{
                    setVerificationCode("");
                    setUsername(event.target.value);
                }}
            />
        </p>
        <button className="btn btn-primary"
            onClick={sendEmail}
            disabled={username === "" || !allowResendEmail}
            >
            发送/重发验证邮件
        </button>
        <hr />
        {verificationCode && resetActions}
    </div>;
}
