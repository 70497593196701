import {useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Toast, Button, Upload, Tabs, TabPane } from '@douyinfe/semi-ui';
import { IconUpload } from '@douyinfe/semi-icons';
import { customRequestArgs } from '@douyinfe/semi-ui/lib/es/upload';
import axios from 'axios';
import { API_BASE_URL } from 'config';
import { getCookie } from 'utils/cookieHelper';
import CardDeckCharacterAvatar from 'components/Tcg/CardDeckCharacterAvatar/CardDeckCharacterAvatar';
import CardList from 'components/Tcg/CardList/CardList';
import Loading from 'components/Loading/Loading';
import CardListCardPreviewVertical from 'components/Tcg/CardListCardPreviewVertical/CardListCardPreviewVertical';
import card_deck_api from 'api/tcg/card_deck_api';
import Title from 'utils/title';

const MAX_ACTION_CARDS = 30;
const MAX_CHARACTOR_CARDS = 3;

export default function TcgMyCardDeckEditPage() {

    const navigate = useNavigate();

    // === Data ===
    const [cardDeckID, setCardDeckID] = useState<string>("");
    const [cardDeckName, setCardDeckName] = useState<string>("");

    // Map from card name to card data
    const [cards, setCards] = useState<Map<string, any>>(new Map());
    const [characters, setCharacters] = useState<string[]>([]);
    const [actions, setActions] = useState<string[]>([]);

    // === Status ===
    const [cardDeckLoaded, setCardDeckLoaded] = useState<boolean>(false);
    const [cardsLoaded, setCardsLoaded] = useState<boolean>(false);
    // true iff we are waiting for the server to respond to submmitted card deck
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string|undefined>(undefined);
    
    const language = useParams()["language"];
    const initialID = useParams()["id"];
    const [searchParams, _setSearchParams] = useSearchParams()

    useEffect(()=>{
        switch (initialID) {
            case undefined:
            case "new":
                return;
            default:
                setCardDeckID(initialID);
        }}, []);
    
    // Load card information
    const getCards = () => {
        setCardsLoaded(false);
        axios.get(`${API_BASE_URL}/tcg/card_list?lang=${language}`, {}).then(
            response => {
                let data = response.data.data;
                let map = new Map<string, any>();
                data.forEach((card: any) => {
                    map.set(card.name, card);
                });
                setCards(map);
                setCardsLoaded(true);
            },
            error => {
                Toast.error(`加载卡牌信息失败:${error.response.data.error}`);
                setCardsLoaded(true);
            }
        )
    }

    useEffect(() => {
        getCards();
    }, []);

    // Load the card deck
    const getCardDeck= () => {
        setCardDeckLoaded(false)
        if (cardDeckID === "") {
            setCardDeckLoaded(true);
            return;
        }
        axios.get(`${API_BASE_URL}/tcg/card_deck/info?id=${cardDeckID}`, {
            headers: {
                Authorization: getCookie("token"),
            }
        }).then(
            response => {
                let data = response.data.data;
                setActions(data.actions.slice(0, MAX_ACTION_CARDS));
                setCharacters(data.characters.slice(0, MAX_CHARACTOR_CARDS));
                setCardDeckName(data.name);
                setCardDeckLoaded(true);
            },
            error => {
                let error_response = error.response.data;
                setErrorMsg(`卡组加载失败：${error_response.error}(${error_response.context})`)
                setCardDeckLoaded(true);
            }
        )
    }

    useEffect(()=>{
        getCardDeck();
    }, [cardDeckID]);

    // Show error message
    useEffect(()=>{
        if (errorMsg !== undefined) {
            Toast.error(errorMsg);
        }
    }, [errorMsg]);

    // Add a card to the card deck
    const addCard = (card: any) => {
        let name = card.name;
        if (card.card_type === "角色") {
            if (characters.length >= MAX_CHARACTOR_CARDS) {
                Toast.error(`已经有${MAX_CHARACTOR_CARDS}张角色卡了哦`);
                return
            }
            if (characters.indexOf(name) !== -1) {
                Toast.error(`${name}已经在卡组里了哦`);
                return
            }

            // Add a character card
            let newCharacters = characters.slice();
            newCharacters.push(name);
            setCharacters(newCharacters);
        } else {
            if (actions.length >= MAX_ACTION_CARDS) {
                Toast.error(`已经有${MAX_ACTION_CARDS}张行动卡了哦`);
                return;
            }
            if (actions.filter(x=>(x === name)).length >= 2) {
                Toast.error(`已经有两张"${name}"了哦`);
                return;
            }

            // Add an action card
            let newActions = actions.slice();
            let index = actions.indexOf(name);
            if (index === -1) {
                newActions.push(name);
            } else {
                // Make sure that duplicated cards stay together
                newActions.splice(index, 0, name);
            }
            setActions(newActions)
        }
    }

    // Submit card deck
    function onSubmit() {
        setSubmitting(true);
        axios.post(
            `${API_BASE_URL}/tcg/card_deck/update_one`,
            {
                id: cardDeckID,
                lang: language,
                name: cardDeckName,
                characters: characters,
                actions: actions
            },
            {headers: {Authorization: getCookie("token")}})
            .then((response)=>{
                setSubmitting(false);
                Toast.success("卡组提交成功！")

                // Navigate to return_to URL when return_to is set
                let return_to = searchParams.get("return_to")
                if (return_to !== null && return_to[0] === '/') {
                    navigate(return_to);
                    return;
                }

                // Navigate to the new card deck's URL
                let data=response.data;
                if (data.created_new) {
                    navigate(`/tcg-card-decks/${data.id}/edit`);
                }
            },
            (error)=>{
                setSubmitting(false);
                let error_response = error.response.data;
                Toast.error(`提交卡组失败:${error_response.error}(${error_response.context})`);
            });
    }

    let editRegion = (<div>
        <div className="input-group was-validated">
            <span className="input-group-text">卡组名称</span>
            <input
                type="text" aria-label="卡组名称"
                className='form-control'
                value={cardDeckName}
                required={true}
                onChange={(event)=>setCardDeckName(event.target.value)}/>
        </div>
        {
            currentCards(
                cards,
                characters, setCharacters,
                actions, setActions)
        }
        <div className="d-grid">
            <button className="btn btn-primary" type="button"
                    onClick={onSubmit}
                    title={cardDeckName === ""? "卡组名称不能为空":undefined}
                    disabled={
                        characters.length !== MAX_CHARACTOR_CARDS
                        || actions.length !== MAX_ACTION_CARDS
                        || cardDeckName === ""}>
                {
                    submitting?
                    <>
                        <span className="spinner-border spinner-border-sm mx-3"
                            role="status" aria-hidden="true" />
                        提交中……
                    </>:
                    "提交"
                }
            </button>
        </div>
    </div>)
    if (errorMsg !== undefined) {
        editRegion = <p>{errorMsg}</p>;
    } else if (!cardDeckLoaded || !cardsLoaded) {
        editRegion = <Loading prompt="加载卡组中……" />
    }
    return (
        <div className="container py-3">
            <h1><Title title="卡组编辑" /></h1>
            {editRegion}
            <Tabs>
                <TabPane tab="识别分享图片" itemKey='1'>
                    {CardDeckRecongnition(setCharacters, setActions)}
                </TabPane>
                <TabPane tab="手动选择卡牌" itemKey='2'>
                    <CardList
                        onCardClickCallBack={addCard}
                        onCardClickPreviewMode={false}/>
                </TabPane>
            </Tabs>
        </div>
    );
}

// Display the current cards
function currentCards(
    cards: Map<string, any>,
    characters: string[],
    setCharacters: (_: string[])=>void,
    actions: string[],
    setActions: (_: string[])=>void
) {
    const deleteCharacter = (index: number) => {
        characters = characters.slice();
        characters.splice(index, 1);
        setCharacters(characters);
    }
    const deleteAction = (index: number) => {
        actions = actions.slice();
        actions.splice(index, 1);
        setActions(actions);
    }
    return (
    <div className='card my-3'>
        <div className='card-header text-center'>
            {
                characters.map((name, index)=>
                    <a
                        key={index}
                        onClick={() => deleteCharacter(index)}
                        style={{padding: 5}}>
                            <CardDeckCharacterAvatar card={cards.get(name)}/>
                    </a>
                )
            }
        </div>
        <div className="card-body px-md-5">
            <div className="row row-cols-3 row-cols-md-5 tql-row-cols-xl-10">
                {
                    actions.map((card: any, i: number) => {
                        return (
                            <div
                                    key={card._id}
                                    className='col'>
                                <CardListCardPreviewVertical
                                    card={cards.get(card)}
                                    onCardClick={()=>deleteAction(i)}
                                    showDetails={false}
                                />
                            </div>);
                    })
                }
            </div>
        </div>
        <div className="card-footer">
            {`已选角色 ${characters.length}/${MAX_CHARACTOR_CARDS}、
            卡牌 ${actions.length}/${MAX_ACTION_CARDS}`}
        </div>
    </div>
    )
}

function CardDeckRecongnition(
    setCharacters: (_: string[])=>void,
    setActions: (_: string[])=>void
) {
    const [loading, setLoading] = useState<boolean>();

    const onImageUpload = (args: customRequestArgs) => {
        var data = new FormData();
        data.append("file", args.fileInstance);
        setLoading(true);
        card_deck_api.detect_by_image(data,
            response => {
                setLoading(false);
                setActions(response.actions);
                setCharacters(response.characters);
                args.onProgress({ total: 100, loaded: 100 });
            },
            _error => {
                setLoading(false);
            });
    };

    return (<>
        <p>只支持从原神游戏中直接导出的卡组分享图片，不支持其他形式的排版。为保证准确率，请尽量使用原图画质，并且不要剪裁或添加滤镜。</p>
        <Upload
            action={""}
            accept={".jpg,.png,.jpeg"}
            maxSize={10 * 1024}
            customRequest={onImageUpload}
            disabled={loading}
        >
            <Button icon={<IconUpload />} theme="light" loading={loading} >
                上传文件
            </Button>
        </Upload>
    </>);
}
