import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DEFAULT_LANDING_PAGE_RROUTE } from "projectConstants";
import NotFoundPage from "pages/NotFoundPage/NotFoundPage";


export default function TcgPage() {
    let navigate = useNavigate();
    const location = useLocation();
    const [show404, set404] = useState<boolean>(false);

    const { i18n } = useTranslation();

    console.log("Catched by default page", location.pathname);

    // TODO: 现在暂时只用中文，remove later
    useEffect(() => {
        let current_path = location.pathname + location.search;
        let current_lang = current_path.split("/")[1];
        if (i18n.resolvedLanguage === current_lang) {
            // The path already contains language tag and doesn't match any
            // previous router, so the path is invalid
            set404(true);
        } else {
            // Maybe the language tag is missing, so add the language tag
            i18n.changeLanguage("zh-CN");
            let target = i18n.resolvedLanguage + (current_path !== "/" ? current_path : DEFAULT_LANDING_PAGE_RROUTE);
            navigate(target);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return show404? <NotFoundPage /> : <></>;
}
