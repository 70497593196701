

export const chunkArray = (arr: any[], n: number) => {
    if (!arr) {
        return [];
    }
    var chunks = [];
    if (arr.length < n) {
        chunks.push(arr);
    } else {
        for (let i = 0; i < arr.length; i += n) chunks.push(arr.slice(i, i + n));
    }
    return chunks;
}