import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import auth_api from 'api/auth_api';
import Loading from 'components/Loading/Loading';
import { Toast } from '@douyinfe/semi-ui';
import Title from 'utils/title';
import { setSignInCookies } from 'utils/cookieHelper';
import bilibili_api from 'api/auth/bilibili_api';


export default function VerifyEmailPage() {
    const [loading, setLoading] = useState<boolean>(true);
    const [result, setResult] = useState<string>("派蒙正在检查b站的令牌……");
    const [searchParams, _setSearchParams] = useSearchParams();

    useEffect(() => {
        bilibili_api.decodeCallbackState(
            {callback_state: searchParams.get("state") || ""},
            (response)=> {
                generateCookie(response.return_url);
            },
            (_error) => {
                setResult("验证失败。Bilibili 提供的令牌无效，请重新登录。");
                setLoading(false);
            },
        );
    }, [searchParams])

    const generateCookie = (return_url: string) => {
        bilibili_api.generateCookieState(
            {bilibili_code: searchParams.get("code") || ""},
            (_response) => {
                if (searchParams.get("guest") === "true")
                    onSuccess(return_url);
                else
                    signIn(return_url);
            },
            (_error) => {
                setResult("Bilibili验证失败，请重试。");
                setLoading(false);
            }
        )
    }

    const signIn = (return_url: string) => {
        auth_api.signIn(
            {},
            (response) => {
                setSignInCookies(response);
                onSuccess(return_url);
            },
            (_error) => {
                setResult("登录失败，请重试。常见问题：（1）B站账号未绑定到 TQLib 账号（2）登录时间超时");
                setLoading(false);
            }
        );
    };

    const onSuccess = (return_url: string) => {
        Toast.success("登录成功！3秒后将自动跳转到登录前的页面。");
        setResult("登录成功！3秒后将自动跳转到登录前的页面。");
        setLoading(false);
        setTimeout(()=>{window.location.replace(return_url);}, 3000);
    }

    return <div className="container">
        <h1><Title title="Bilibili登录" /></h1>
        <p>
            {
                loading?
                <Loading prompt="派蒙正在检查 Bilibili 的令牌……" />
                : result
            }
        </p>
    </div>;
}
