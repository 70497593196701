import React, { useRef, useState } from 'react';

import { IconChevronLeft, IconUpload } from '@douyinfe/semi-icons';
import { Button, TabPane, Tabs, Typography, Upload, Form, Divider, Spin, Card, Descriptions, Toast, ToastFactory, Banner, Modal } from '@douyinfe/semi-ui';
import { customRequestArgs } from '@douyinfe/semi-ui/lib/es/upload';
import TcgCardDeckDetectionConfirmModal from 'components/Contest/TcgCardDeckDetectionConfirmModal';
import { getCookie } from 'utils/cookieHelper';
import contest_api from 'api/contest_api';
import card_deck_api from 'api/tcg/card_deck_api';
import tcg_avatar_api from 'api/contest/tcg_avatar_api';
import Title from 'utils/title';


interface Props {
    isMobile: boolean;
}

const ContestsTcgRegisterPage: React.FC<Props> = ({ isMobile }) => {
    const { Text } = Typography;

    const playerFormRef = useRef<Form<any>>(null);
    const formRef = useRef<Form<any>>(null);;

    const [characterCardNames, setCharacterCardNames] = useState<any[]>([]);
    const [actionCardNames, setActionCardNames] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [tcgCardDeckDetectionConfirmModalModalVisible, setTcgCardDeckDetectionConfirmModalModalVisible] = useState<boolean>(false);
    const [tcgCardDeckDetectionConfirmModalModalLoading, setTcgCardDeckDetectionConfirmModalModalLoading] = useState<boolean>(false);
    const [tabActiveKey, setTabActiveKey] = useState<string>("1");
    const [playerFormValues, setPlayerFormValues] = useState<any>({});
    const [avatar_url, setAvatarUrl] = useState<string>("");


    const token = getCookie("token");
    const username = getCookie("username");

    const toStep2 = (values: any) => {
        console.log(values)
        if (validateFields(values) === null) {
            setPlayerFormValues(values);
            setTabActiveKey("2");
            console.log("to step 2")
            console.log(playerFormValues)
        }
    };

    const onFieldsChange = (_values: any) => {
        formRef.current?.formApi.validate().catch(() => {});
    }

    const validateEmail = (email: string) => {
        if (typeof email === "undefined") {
            return false;
        }
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const validateFields = (values: any) => {
        const errors: any = {};
        if (!values.username || values.username.length < 1 || values.username.length > 20) {
            errors.username = "昵称长度需介于2-20个字符之间";
        }
        if (!values.ys_uid || values.ys_uid < 100000000 || values.ys_uid > 999999999) {
            errors.ys_uid = "请输入正确的原神uid";
        }
        if (!values.qq || values.qq < 10000 || values.qq > 10000000000000) {
            errors.qq = "请输入正确的QQ号";
        }
        if (!validateEmail(values.email)) {
            errors.email = "请输入正确的邮箱";
        }
        if (!values.contest_code || (values.contest_code !== "64c206259d98c3761a09d1cc" && values.contest_code !== "63c494a442106fc40849166d" && values.contest_code !== "64c20e7d1aa6a1484f83fa9a")) {
            errors.contest_code = "请输入正确的赛事码";
        }
        if (avatar_url == "") {
            errors.avatar = "请上传头像";
            Toast.error('请上传头像');
        }
        return Object.keys(errors).length !== 0 ? errors : null;
    }

    const onImageUpload = (args: customRequestArgs) => {
        var data = new FormData();
        data.append("file", args.fileInstance);
        setLoading(true);
        card_deck_api.detect_by_image(data,
            response => {
                setLoading(false);
                console.log(response)
                setActionCardNames(response.actions);
                setCharacterCardNames(response.characters);
                setTcgCardDeckDetectionConfirmModalModalVisible(true);
                args.onProgress({ total: 100, loaded: 100 });
            },
            () => {
                setLoading(false);
                args.onProgress({ total: 100, loaded: 100 });
            });
    };

    const onAvatarUpload = (args: customRequestArgs) => {
        var data = new FormData();
        data.append("file", args.fileInstance);
        setLoading(true);
        // 虚假进度条
        let count = 0;
        let interval = setInterval(() => {
            if (count === 100) {
                clearInterval(interval);
                return;
            }
            args.onProgress({ total: 100, loaded: count });
            count += 10;
        }, 50);
        tcg_avatar_api.upload_contest_avatar(data,
            response => {
                setLoading(false);
                console.log(response)
                count = 100
                args.onProgress({ total: 100, loaded: 100 });
                args.onSuccess(response, new Event('complete'));
                setAvatarUrl(response.filename);
            },
            () => {
                setLoading(false);
                args.onProgress({ total: 100, loaded: 100 });
            });
    };

    const onConfirmCardDeck = () => {
        setLoading(true);
        setTcgCardDeckDetectionConfirmModalModalLoading(true);
        contest_api.register({
            username: playerFormValues.username,
            email: playerFormValues.email,
            ys_uid: playerFormValues.ys_uid + "",
            qq: playerFormValues.qq + "",
            contest_code: playerFormValues.contest_code,
            custom_questions: [
                // { question: "报名时间", answer: formValues.报名时间 },
            ],
            characters: characterCardNames,
            actions: actionCardNames,
            avatar: avatar_url
        },
            response => {
                setLoading(false);
                setTcgCardDeckDetectionConfirmModalModalLoading(false);
                console.log(response);
                ToastFactory.create({
                    left: "30%",
                }).success('卡组已成功提交！');
                setTcgCardDeckDetectionConfirmModalModalVisible(false);
            },
            () => {
                setLoading(false);
                setTcgCardDeckDetectionConfirmModalModalLoading(false);
            });
    }

    const { Checkbox } = Form;
    let imageOnly = 'image/*';

    const [visible, setVisible] = useState(false);
    const showDialog = () => {
        setVisible(true);
    };
    const handleOk = () => {
        setVisible(false);
        console.log('Ok button clicked');
    };
    const handleCancel = () => {
        setVisible(false);
        console.log('Cancel button clicked');
    };
    const handleAfterClose = () => {
        console.log('After Close callback executed');
    };



    return (
        <div style={{
            position: 'relative',
            marginTop: '50px',
            justifyContent: 'center',
            alignContent: 'center',
            //height: height,
            width: "100%",
            height: '100%',
            display: 'inline-block',
            padding: 20,
            // backgroundColor: 'var(--semi-color-fill-0)'
        }}>

            <Card
                style={{ maxWidth: 360, margin: "auto", top: "60%" }}
                bordered={false}
                headerLine={false}
                shadows="always"

            >
                <h1><Title title="访客报名" /></h1>
                <Tabs type="line" activeKey={tabActiveKey} onTabClick={value => setTabActiveKey(value)}>
                    <TabPane tab="选手信息" itemKey="1">
                        <Form
                            labelPosition="left"
                            labelWidth={80}
                            layout='vertical'
                            ref={playerFormRef}
                            onValueChange={onFieldsChange}
                            validateFields={validateFields}
                            onSubmit={toStep2}
                        >
                            <Upload action={""} accept={imageOnly} style={{ marginTop: 12 }} customRequest={onAvatarUpload} maxSize={1024} limit={1}>
                                <Button icon={<IconUpload />} theme="light">
                                    上传选手头像
                                </Button>
                            </Upload>
                            <Form.Input field='contest_code' label='赛事码' style={{ width: 200 }} />
                            <Form.Input field='username' label='参赛昵称' style={{ width: 200 }} disabled={!!token} initValue={username} />
                            <Form.InputNumber field='ys_uid' label='原神 UID' style={{ width: 200 }} hideButtons formatter={value => `${value}`.replace(/\D/g, '')} />
                            <Form.InputNumber field='qq' label='QQ号' style={{ width: 200 }} hideButtons formatter={value => `${value}`.replace(/\D/g, '')} />
                            <Form.Input field='email' label='邮箱' style={{ width: 200 }} disabled={!!token} />
                            <div style={{ marginTop: "30px", width: "100%" }}>
                                <Button
                                    htmlType='submit'
                                    theme="solid"
                                style={{ position: "relative", bottom: "0px", right: "-230px" }}
                                >
                                    下一步
                                </Button>
                            </div>
                        </Form>
                    </TabPane>
                    {/* <TabPane tab="报名时间" itemKey="2" disabled>
                        <Form
                                // labelPosition="left"
                                // labelWidth={80}
                                layout='vertical'
                                ref={formRef}
                                onSubmit={toStep3}
                            >
                            <Form.CheckboxGroup field='报名时间' label="报名时间（将从所选场次中分配一场）" direction='vertical'>
                                <Form.Checkbox value="4月20日19:00-22：30">4月20日 19:00-22：30</Form.Checkbox>
                                <Form.Checkbox value="4月21日19:00-22：30">4月21日 19:00-22：30</Form.Checkbox>
                                <Form.Checkbox value="4月22日19:00-22：30">4月22日 19:00-22：30</Form.Checkbox>
                                <Form.Checkbox value="4月23日14:00-17：30">4月23日 14:00-17：30</Form.Checkbox>
                                <Form.Checkbox value="4月23日19:00-22：30">4月23日 19:00-22：30</Form.Checkbox>
                                <Form.Checkbox value="4月24日19:00-22：30">4月24日 19:00-22：30</Form.Checkbox>
                                <Form.Checkbox value="4月25日19:00-22：30">4月25日 19:00-22：30</Form.Checkbox>
                                <Form.Checkbox value="4月26日19:00-22：30">4月26日 19:00-22：30</Form.Checkbox>
                                <Form.Checkbox value="4月27日19:00-22：30">4月27日 19:00-22：30</Form.Checkbox>
                            </Form.CheckboxGroup>
                            <div style={{ marginTop: "30px", width: "100%" }}>
                                <Button
                                    theme="solid"
                                    onClick={toStep1}
                                // style={{ position: "relative", bottom: "0px", right: "-210px" }}
                                >
                                    上一步
                                </Button>
                                <Button
                                    htmlType='submit'
                                    theme="solid"
                                    // onClick={toStep3}
                                style={{ position: "relative", bottom: "0px", right: "-170px" }}
                                >
                                    下一步
                                </Button>
                            </div>
                        </Form>
                    </TabPane> */}
                        {/* <Divider margin='20px' />  */}
                    <TabPane tab="核对信息" itemKey="2" disabled>
                        <Banner style={{ marginTop: "10px", marginBottom: "10px" }}
                            description="请核对个人信息，如正确即可提交"
                        />
                        <Descriptions style={{ marginTop: "10px", marginBottom: "10px" }} align="left">
                            <Descriptions.Item itemKey="参赛昵称">{playerFormValues.username}</Descriptions.Item>
                            <Descriptions.Item itemKey="原神 UID">{playerFormValues.ys_uid}</Descriptions.Item>
                            <Descriptions.Item itemKey="QQ号">{playerFormValues.qq}</Descriptions.Item>
                            <Descriptions.Item itemKey="邮箱">{playerFormValues.email}</Descriptions.Item>
                            <Descriptions.Item itemKey="赛事码">{playerFormValues.contest_code}</Descriptions.Item>
                            {/* <Descriptions.Item itemKey="报名时间">
                                { renderArrayToDescriptionItem(formValues.报名时间) }
                            </Descriptions.Item> */}
                        </Descriptions>
                        <Text icon={<IconChevronLeft />}
                            link
                            onClick={() => setTabActiveKey("1")}
                        >
                            {"信息有误? 返回修改"}
                        </Text>
                        <br />
                        <Text>上传游戏内导出的卡组图片，每次一张。</Text>
                        <Text>请提交四套不完全重复的阵容，5套阵容至少包含11张不同角色牌。</Text>
                        <br />
                        <Upload
                            action={""}
                            accept={".jpg,.png,.jpeg"}
                            maxSize={10 * 1024}
                            customRequest={onImageUpload}
                            disabled={loading}
                        >
                            <Button icon={<IconUpload />} theme="light" loading={loading} >
                                上传游戏导出的卡组图片
                            </Button>
                        </Upload>
                        {loading && <Spin
                            tip={"图像识别中... 此过程可能需要10-20秒, 请耐心等待～"}
                            size="large"
                            spinning={loading}
                            style={{ width: "100%", height: "100px" }}
                        />}
                        <Divider margin='10px' />
                        <Checkbox onChange={checked => console.log(checked)} aria-label="确认提交">确认卡组符合要求，且个人信息填写正确。</Checkbox>
                        <br />
                        <Button onClick={showDialog} theme="solid">提交报名</Button>
                        <Modal
                            title="提交成功"
                            visible={visible}
                            onOk={handleOk}
                            afterClose={handleAfterClose} //>=1.16.0
                            onCancel={handleCancel}
                            closeOnEsc={true}
                        >
                            提交成功，请耐心等待赛事方联系。
                            <br />
                        </Modal>
                    </TabPane>
                </Tabs>
            </Card>
            {/* </Modal> */}

            <TcgCardDeckDetectionConfirmModal
                visible={tcgCardDeckDetectionConfirmModalModalVisible}
                loading={tcgCardDeckDetectionConfirmModalModalLoading}
                characterCardNames={characterCardNames}
                actionCardNames={actionCardNames}
                isFullScreen={isMobile}
                onOK={onConfirmCardDeck}
                onCancel={() => setTcgCardDeckDetectionConfirmModalModalVisible(false)}
            />
            <div style={{
                marginTop: '50px'
            }}></div>

        </div >
    );
}

export default ContestsTcgRegisterPage;