import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { isSignedIn } from "utils/cookieHelper";
import { relPath } from "utils/navigateHelpers";
import { Toast } from '@douyinfe/semi-ui';
import tcg_api from "api/contest/tcg_api";
import TcgMyCardDecksPageLi from "pages/Tcg/TcgMyCardDecksPage_Li/TcgMyCardDecksPageLi";
import React, { useEffect, useRef, useState } from 'react';

import { Button, Form } from '@douyinfe/semi-ui';
import Title from "utils/title";


interface ContestInfo {
    name: string
    numCardDecksPerUser: number
}

const Step4Page: React.FC<{}> = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [loadingContest, setLoadingContest] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [contestInfo, setContestInfo] = useState<ContestInfo | undefined>(undefined);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedCardDecks, setSelectedCardDecks] = useState<Set<string>>(new Set<string>());
    const formRef = useRef<Form<any>>(null);
    const [_loading, setLoading] = useState(false);
    const [formValues, _setFormValues] = useState<any>({});

    useEffect(() => {
        if (!isSignedIn()) {
            gotoStep1();
        }

        // Try to get contest ID from URL
        const contest_id = searchParams.get('contest_id');
        if (contest_id === null) {
            gotoStep1();
        } else {
            tcg_api.registration_info({
                contest_id: contest_id,
            },
                (response) => {
                    console.log(response);
                    setContestInfo({
                        name: response.name,
                        numCardDecksPerUser: response.num_card_decks_per_user,
                    });
                    setSelectedCardDecks(new Set<string>(response.card_decks));
                    setLoadingContest(false);
                },
                (_error) => {
                    setLoadingContest(false);
                });
        }
        formRef.current?.formApi.setValue('contest_id', contest_id);
    }, [])



    const gotoStep1 = () => {
        navigate(relPath('/contest/tcg/register_v2/step1'));
    };

    const onSubmitContestID = function (values: any) {
        setLoadingContest(true);
        setSearchParams({ contest_id: values.contest_id });
        tcg_api.registration_info({
            contest_id: values.contest_id,
        },
            (response) => {
                setContestInfo({
                    name: response.name,
                    numCardDecksPerUser: response.num_card_decks_per_user,
                });
                setSelectedCardDecks(new Set<string>(response.card_decks));
                setLoadingContest(false);
            },
            (_error) => {
                setLoadingContest(false);
            });
    }

    const onSubmit = function () {
        console.log(formValues);
        setLoading(true);
        tcg_api.register_v2({
            contest_id: searchParams.get('contest_id') || "",
            card_deck_ids: Array.from(selectedCardDecks),
        },
            () => {
                setSubmitting(false);
                Toast.success("感谢报名～")
            },
            (_error) => {
                setSubmitting(false);
            });
    }

    if (contestInfo === undefined) {
        return <div className="container pt-5">
            <Form
                labelPosition="left"
                labelWidth={100}
                layout='vertical'
                ref={formRef}
                onSubmit={onSubmitContestID} >
                <Form.Input field='contest_id' label='比赛邀请码'
                    style={{ width: 200 }}
                    disabled={loadingContest} />

                <div style={{ marginTop: "30px", width: "100%" }}>
                    <Button
                        htmlType='submit'
                        theme="solid"
                        loading={loadingContest}>
                        确认
                    </Button>
                </div>
            </Form>
        </div>;
    } else {
        return (


            <div className="container pt-5">
                <h1><Title title={contestInfo.name} /></h1>
                <p>请选择您要提交的卡组：</p>

                <TcgMyCardDecksPageLi
                    defaultActionCardsVisible={false}
                    selectedCardDecks={selectedCardDecks}
                    setSelectedCardDecks={setSelectedCardDecks}
                    returnTo={location.pathname + location.search} />

                <p className="my-3">
                    已选择 {selectedCardDecks.size}/{contestInfo.numCardDecksPerUser} 个卡组
                </p>

                <button className="btn w-100 btn-primary my-3" type="button"
                    onClick={onSubmit}
                    disabled={
                        selectedCardDecks.size !== contestInfo.numCardDecksPerUser}>
                    {
                        submitting ?
                            <>
                                <span className="spinner-border spinner-border-sm mx-3"
                                    role="status" aria-hidden="true" />
                                提交中……
                            </> :
                            "提交"
                    }
                </button>
            </div>)
    };
}

export default Step4Page;
