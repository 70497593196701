import { Button, Card, Col, Descriptions, Row, Space } from '@douyinfe/semi-ui';
import axios from "axios";
import { API_BASE_URL } from "config";
import { useEffect, useState } from "react";
import Title from 'utils/title';

const AllTcgContestsPage: React.FC = () => {
    const language = "zh-CN";

    const [allContests, setAllContests] = useState<any>(null);
    const { Meta } = Card;

    // Handle API Calls

    const getAllContestsApiCall = () => {
        axios.get(`${API_BASE_URL}/contest/tcg/all`, {}).then(
            (response) => {
                setAllContests(response.data.data);
            },
            (error) => {
                console.log("[DEBUG] TODO", error);
            }
        );
    };

    useEffect(() => {
        getAllContestsApiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <div className='container py-3'>
            <h1><Title title="七圣召唤比赛一览" /></h1>




            <Space />

            {/* Carousel: provide info for major contests */}
            {/* <Row>
                <Carousel style={style} theme='dark'>
                    {
                        imgList.map((src, index) => {
                            return (
                                <div key={index} style={{ backgroundSize: 'cover', backgroundImage: `url(${src})` }}>
                                    <Space vertical align='start' spacing='medium'>
                                        <Title heading={2} style={colorStyle}>{textList[index][0]}</Title>
                                        <Space vertical align='start'>
                                            <Paragraph style={colorStyle}>{textList[index][1]}</Paragraph>
                                            <Paragraph style={colorStyle}>{textList[index][2]}</Paragraph>
                                        </Space>
                                    </Space>
                                </div>
                            );
                        })
                    }
                </Carousel>
            </Row> */}

            <Space />

            {/* Filter Component: search bar, filter dropdown Not Implemented in v1*/}
            {allContests && (
                <Row gutter={[16, 16]}>

                    {
                        allContests.map((contest: any, i: number) => {
                            return (
                                <Col xs={24} sm={12} md={8} lg={6} xl={6} key={i}>
                                    <Card
                                        cover={
                                            <img
                                                height="100px"
                                                width="100%"
                                                style={{ objectFit: "cover" }}
                                                alt="tcg"
                                                src={contest.bannerImg ? contest.bannerImg : "https://i.ytimg.com/vi/HIOExazTZNI/maxresdefault.jpg"}
                                            />
                                        }
                                        footer={
                                            <Button block style={{ backgroundColor: "#D57804" }} theme='solid' type='primary' onClick={() => window.open(`/${language}/contest/tcg/${contest._id}`, '_blank')}>查看详情</Button>

                                        }
                                    >
                                        <Meta title={contest.name}
                                        // avatar={
                                        //     <Avatar
                                        //         alt='tcg game default'
                                        //         size="default"
                                        //         src='https://i01piccdn.sogoucdn.com/dcc8453185dcb299'
                                        //     />
                                        // }
                                        />
                                        <Space />
                                        <Descriptions align="left"> 
                                            <Descriptions.Item itemKey="开始时间">{contest.start_time ? new Date(contest.start_time * 1000).toLocaleDateString() : '暂无'}</Descriptions.Item>
                                            <Descriptions.Item itemKey="结束时间">{contest.end_time ? new Date(contest.end_time * 1000).toLocaleDateString() : '暂无'}</Descriptions.Item>
                                        </Descriptions>
                                    </Card>
                                </Col>);
                        })
                    }


                </Row>
            )}


        </div >
    );
}

export default AllTcgContestsPage;
