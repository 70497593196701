
import React from 'react';
import { Tag, Modal, Typography, Image, Divider } from '@douyinfe/semi-ui';
import { TCG_CARD_PREVIEW_VERTICAL_WIDTH_PX, TCG_CARD_PREVIEW_VERTICAL_HEIGHT_PX } from 'projectConstants';
import CardCost from 'components/Tcg/CardCost/CardCost';


interface Props {
    card: any;
    visible: boolean;
    onCancel: () => void;
}

const ActionCardModal: React.FC<Props> = ({ card, visible, onCancel }) => {
    const { Title } = Typography;

    return (
        <Modal
            title={null}
            footer={null}
            visible={visible}
            afterClose={onCancel}
            onCancel={onCancel}
            closeOnEsc={true}
            style={{width: 'calc(100vw - 60px)', maxWidth: '960px'}}
            size='large'
        >

            {card && <div style={{ padding: "30px", display: "flex", flexDirection: "row" }}>
                {/* 左侧 */}
                <div>
                    <Image
                        width={TCG_CARD_PREVIEW_VERTICAL_WIDTH_PX}
                        height={TCG_CARD_PREVIEW_VERTICAL_HEIGHT_PX}
                        src={card.img}
                        preview={false}
                        style={{ borderRadius: "5px" }}
                    />
                    {/* <div style={{ paddingLeft: "10px", paddingTop: "20px" }}>
                        <Title heading={6} type="secondary" >价格: {card.price}</Title>
                    </div> */}

                </div>
                {/* 右侧 */}
                <div style={{ paddingLeft: "50px", width: "100%", display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Title >{card.name}</Title>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }} >
                        <Tag size="large" color="violet" >#{card.card_type}</Tag>
                        <CardCost
                            card={card}
                            size_px={30}
                        />
                    </div>

                    <Divider margin='10px' />

                    <div style={{ paddingLeft: "15px", paddingTop: "10px" }}>
                        <Title heading={6} type="secondary" >{card.effect}</Title>
                    </div>


                </div>
            </div>}
        </Modal>
    );
};


export default ActionCardModal;
