import React, { Component, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Nav, Dropdown, Popover } from '@douyinfe/semi-ui';
import { IconSearchStroked, IconGlobeStroke, IconPlus } from '@douyinfe/semi-icons';
import { Button, SplitButtonGroup } from '@douyinfe/semi-ui';
import { Typography } from '@douyinfe/semi-ui';
import './NavBar.css';
import SignInSignUpDialog from 'components/SignIn/SignInSignUpDialog/SignInSignUpDialog';
import { useTranslation } from "react-i18next";



const LanguageSelector: React.FC = () => {
    const { t, i18n } = useTranslation();

    const { Text } = Typography;
    let navigate = useNavigate();
    const location = useLocation();

    const [signInSignUpDialogVisible, setSignInSignUpDialogVisible] = useState(false);

    const languages = [
        {
            language: "简体中文",
            key: "zh-CN",
        },
        {
            language: "English",
            key: "en-US"
        }
    ]

    

    const onLanguageChange = (languageKey: string) => {
        i18n.changeLanguage(languageKey);
        navigate(i18n.resolvedLanguage + location.pathname.substring(6));
    }


    return (
        <Dropdown
            render={
                <Dropdown.Menu>
                    {
                        languages.map(item => {
                            return <Dropdown.Item key={item.key} onClick={() => onLanguageChange(item.key)}>{item.language}</Dropdown.Item>
                        })
                    }
                </Dropdown.Menu>
            }
        >
            <IconGlobeStroke size={"large"} style={{ paddingRight: "10px" }} />
        </Dropdown>
    );
}

export default LanguageSelector;
