import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux'


import { Avatar, Typography, Popover, Divider } from '@douyinfe/semi-ui';

import SignInSignUpDialog from 'components/SignIn/SignInSignUpDialog/SignInSignUpDialog';
import { getBilibiliCookie, isSignedIn, removeSignInCookies } from 'utils/cookieHelper';
import { UserInfoState, loadUserInfo, actions } from 'states/user_info';
import { relPath } from 'utils/navigateHelpers';
import { UserInfoResponse } from 'api/auth_api';
import Loading from 'components/Loading/Loading';
import { useNavigate } from 'react-router-dom';

export default function UserProfileAvatar() {
    if (isSignedIn() || getBilibiliCookie() !== undefined) {
        return <ProfileView />;
    }
    return <SignInButton />;
}

function ProfileView() {
    const userInfo: UserInfoState = useSelector(
        (state: any) => state.user_info);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { Text } = Typography;

    useEffect(()=> {
        loadUserInfo(dispatch, userInfo);
    }, []);

    const onSignOut = () => {
        removeSignInCookies();
        dispatch(actions.clear());
        window.location.reload();
    }

    let adminControls = null;
    if (userInfo.info?.superuser) {
        adminControls = <>
            <a href={relPath('/admin')} className='btn btn-primary m-1'>管理面板</a>
        </>;
    }

    let popoverContent = <></>;
    if (userInfo.info !== null){
        let info: UserInfoResponse = userInfo.info!;
        popoverContent = (
            <div style={{ display: 'flex', flexDirection: "column"}}>
                <Text>{info.username}</Text>
                <Text><b>{info.email_verified? "已验证邮箱": "未验证邮箱"}</b></Text>
                <Divider margin='10px' />
                {adminControls}
                <button
                    className='btn btn-primary m-1'
                    onClick={() => navigate(relPath('/profile'))}
                >
                    账号信息
                </button>
                <button
                    className='btn btn-primary m-1'
                    onClick={onSignOut}
                >
                    {t("signin.signout")}
                </button>
            </div>
        );
    } else {
        popoverContent = <>
            {getBilibiliCookie() == undefined?
                <Loading prompt="正在加载用户信息..."/>:
                <p>Bilibili游客模式</p>}
            <button
                className='btn btn-primary m-1'
                onClick={onSignOut}
            >
                {t("signin.signout")}
            </button>
        </>;
    }

    return (<Popover
            showArrow
            content={popoverContent}
            position="bottomRight"
    >
        <Avatar
            size="small"
            alt='User'
            style={{ margin: 4, marginRight: "15px" }}
            onClick={() => navigate(relPath('/profile'))}
        >
            U
        </Avatar>
    </Popover>);
}

function SignInButton() {
    const [signInSignUpDialogVisible, setSignInSignUpDialogVisible] = useState(false);
    const userInfo: UserInfoState = useSelector(
        (state: any) => state.user_info);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onSigninSuccess = () => {
        setSignInSignUpDialogVisible(false);
        loadUserInfo(dispatch, userInfo);
        window.location.reload();
    }

    return <>
        <div>
            <button
                className='btn btn-primary'
                onClick={() => setSignInSignUpDialogVisible(true)}
            >
                {t("signin.signin")}
            </button>
        </div>
        <SignInSignUpDialog
            visible={signInSignUpDialogVisible}
            onSignInSuccess={onSigninSuccess}
            onSignUpSuccess={onSigninSuccess}
            onCancel={() => setSignInSignUpDialogVisible(false)}
        />
    </>;
}
