import api, { OnErrorFn, OnResponseFn } from 'api/api';

export interface AbyssContestRequest {
    id: string
};


export interface AbyssContestInfoResponse {
    records: AbyssContestInfo,
};

export interface AbyssContestInfo {
    _id: string,
    name: string,
    rule: string,
    banner_img?: string,
    tracks: Track[],
    all_records: AbyssRecords[],
    hosts: string[],
}

export interface Track {
    description: string,
    name: string,
}

export interface AbyssRecords {
    _id: string,
    approved: boolean,
    build: TeamBuild[],
    bvid: string,
    contest_id: string,
    cost: number,
    player_name: string,
    plaer_note: string,
    review_timestamp: number,
    reviewer: string,
    reviewer_note: string,
    room_info: RoomInfo[],
    submit_timestamp: number,
    total_time: number,
    track: string,
}

export interface RoomInfo {
    room: string,
    time_second: number,
    buff: Buff[],
}

export interface Buff {
    effect: string,
    in_floor: boolean,
}

export interface TeamBuild {
    character_build: Build,
    weapon_build: Build,
}

export interface Build {
    name: string,
    number: number,
    url: string,
}


function abyss_info(
    id: string,
    onResponse?: OnResponseFn<AbyssContestInfoResponse>,
    onError?: OnErrorFn) {
    api.get(`/contest/abyss/info?id=${id}`, {
        onResponse: onResponse,
        onError: onError,
    });
}

export interface CreateAbyssContestRequest {
    name: string,
    hosts: string[],
    version?: number,
    start_time: number,
    end_time: number,
    rule: string,
    tracks?: Track[],
    theme?: AbyssTheme,
};

export interface AbyssTheme {
    type: string,
    content?: string[],
}


function create_contest(
    request: CreateAbyssContestRequest,
    onResponse?: () => void,
    onError?: OnErrorFn) {
    api.post('/contest/abyss/create', {
        data: request,
        onResponse: onResponse,
        onError: onError,
    });
}


const abyss_api =  {
    abyss_info, create_contest
};
export default abyss_api;
