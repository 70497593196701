// A banner that shows version information.

import React, { useEffect, useState } from 'react';
import './NavBar.css';
import { BACKEND_ENV, BackendEnvT } from 'config';
import { VersionInfo } from 'api/system_api';
import system_api from 'api/system_api';

const VersionBanner: React.FC = () => {
    var backendName = 'Unknown';
    var backgroundColor = '#FFF';
    const [hide, setHide] = useState<boolean>(false);
    const [frontendVersion, setFrontendVersion] = useState<VersionInfo>();
    const [backendVersion, setBackendVersion] = useState<VersionInfo>();

    useEffect(() => {
        system_api.frontendVersion((version) => {setFrontendVersion(version)});
        system_api.backendVersion((version) => {setBackendVersion(version)});
    }, [])
    
    if (hide) {
        return <></>;
    }

    switch (BACKEND_ENV) {
        case BackendEnvT.PROD:
            return <></>;
        case BackendEnvT.LOCAL:
            backendName = 'Local';
            backgroundColor = '#FCA510';
            break;
        case BackendEnvT.DEV:
            backendName = 'Dev';
            backgroundColor = '#1AA7EC';
            break;
    }

    return <div className='row w-100 flex-shrink-0 px-3 mx-0 text-light'
            style={{
                backgroundColor: backgroundColor,
            }}>
        <p className='m-1'>
            <span className='mx-3'>
                Backend: {backendName} {showVersion(backendVersion)}
            </span>
            <span className='mx-3'>
                Frontend {showVersion(frontendVersion)}
            </span>
            <a className='float-end text-light' onClick={()=>{setHide(true)}}>
                Hide
            </a>
        </p>
    </div>;
}

const showVersion = (version: VersionInfo | undefined) => {
    const format_sha = (sha: string | undefined) => {
        if (sha === undefined || sha === '') {
            return 'Unknown';
        } else {
            return sha.substring(0, 7);
        }
    };
    const format_time = (timestamp: number | undefined) => {
        if (timestamp === undefined || timestamp <= 0) {
            return 'Unknown';
        } else {
            return new Date(timestamp * 1000)
                .toLocaleString(
                    ['en-us'],
                    {year: 'numeric', month: 'short', day: 'numeric',
                    hour12: false,
                    hour: 'numeric', minute: 'numeric', second: 'numeric'});
        }
    }
    return <>(
        {format_sha(version?.git_sha)}, {format_time(version?.build_timestamp)}
    )</>;
}

export default VersionBanner;
