import api, {BilibiliRequest, BilibiliResponse, OnErrorFn, OnResponseFn, SetLoadingFn} from 'api/api'

export interface UserInfoResponse {
    username: string,
    email: string,
    email_verified: boolean,
    superuser: boolean,
    approved: boolean,

    last_login_timestamp: number,
    sign_up_timestamp: number,

    bilibili_open_id: string,
    bilibili_name: string,
}

function userInfo(
        onResponse: OnResponseFn<UserInfoResponse>,
        onError: OnErrorFn) {
    api.get('/auth/user_info',
        {onResponse: onResponse, onError: onError})
}

export interface SignInRequest extends BilibiliRequest {
    username?: string,
    bilibili_code?: string,
    password?: string,
}

export interface SignInResponse extends BilibiliResponse {
    token: string,
    username: string,
}

function signIn(
        request: SignInRequest,
        onResponse: OnResponseFn<SignInResponse>,
        onError?: OnErrorFn) {
    api.postBilibili('/auth/signin', {
        data: request, onResponse: onResponse, onError: onError})
}

export interface SignUpRequest {
    username: string,
    password: string,
    email: string,
}

export interface SignUpResponse {
    token: string,
    username: string,
}

function signUp(
        request: SignUpRequest,
        onResponse: OnResponseFn<SignUpResponse>,
        setLoading: SetLoadingFn) {
    api.post('/auth/signup', {
        data: request, onResponse: onResponse, setLoading: setLoading})
}

export interface ProfileRequestResponse {
    ys_uid: string,
    related_accounts: Account[],
}

interface Account {
    platform: string,
    username: string,
}

function getProfile(
        onResponse: OnResponseFn<ProfileRequestResponse>) {
    api.get('/auth/profile', {onResponse: onResponse});
}

function setProfile(
        request: ProfileRequestResponse,
        onResponse: () => void,
        onError: () => void) {
    api.post('/auth/profile',
        {data: request, onResponse: onResponse, onError: onError});
}

function sendVerificationEmail(
    onResponse: OnResponseFn<void>,
) {
    api.post('/auth/send_verification_email', {
        onResponse: onResponse,
    });
}

export interface VerifyEmailRequest {
    verification_code: string,
}

function verifyEmail(
        request: VerifyEmailRequest,
        onResponse: OnResponseFn<void>,
        onError: OnErrorFn) {
    api.post('/auth/verify_email',
        {data: request, onResponse: onResponse, onError: onError})
}

function sendResetPasswordEmail(
    username: string,
    onResponse: OnResponseFn<void>,
) {
    api.post('/auth/send_reset_password_email',
        {
            data: {username: username},
            onResponse: onResponse,
        }
    )
}

export interface ResetPasswordRequest {
    username: string,
    verification_code: string,
    new_password: string,
}

function resetPassword(
        request: ResetPasswordRequest,
        onResponse: OnResponseFn<void>,
        onError: OnErrorFn) {
    api.post('/auth/reset_password',
        {data: request, onResponse: onResponse, onError: onError})
}

const auth_api =  {
    signIn, signUp,
    userInfo,
    getProfile, setProfile,
    sendVerificationEmail,
    verifyEmail,
    sendResetPasswordEmail,
    resetPassword,
};

export default auth_api;
